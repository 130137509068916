const axios = require("axios");
var qs = require("qs");

const headers = {
  pubid: localStorage.getItem("pubid"),
  channelid: localStorage.getItem("channelid"),
  country_code: localStorage.getItem("C_Code"),
  dev_id: localStorage.getItem("deviceId"),
  ip: localStorage.getItem("IP"),
  device_type: "web",
  uid: localStorage.getItem("userId"),

};
async function facebookLogin(
  facebook_id,
  facebook_email,
  first_name,
  last_name,
  login_type
) {
  var token = localStorage.getItem("access-token");
  let ipaddress = getCookie("ipaddress");
  let deviceId = localStorage.getItem("deviceId");
  let uId = getCookie("guestUserId");
  let user_id = getCookie("userId");
  let countryCode = getCookie("country_code");
  if (user_id) {
    uId = user_id;
  }
  const data = {
    device_id: deviceId,
    facebook_id: login_type == 'facebook' ? facebook_id : null,
    google_id: login_type == 'google' ? facebook_id : null,
    ipaddress: ipaddress,
    pubid: localStorage.getItem("pubid"),
    login_type: login_type,
    email: facebook_email,
    country_code: countryCode,
    first_name: first_name,
    last_name: last_name,
  };
  if (localStorage.getItem("guest_user_id")) {
    data.guest_user_id = localStorage.getItem("guest_user_id")
  }
  const customConfig = {
    method: "POST",

    body: qs.stringify(data),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: localStorage.getItem("pubid"),
      country_code: countryCode,
      channelid: localStorage.getItem("channelid"),
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
      platform: 'ecommerce'

    },
  };

  var url = new URL(
    process.env.REACT_APP_API_URL + "/account/social/loginWithoutCode"
  );

  let res = {};
  let response = await fetch(url, customConfig);
  try {
    res = await response.json();
  } catch (err) {
    res = {};

  }

  res.status = response.status;

  return res;
}

async function facebookLink(
  facebook_id,
  facebook_email,
  first_name,
  last_name,
  login_type
) {
  var token = localStorage.getItem("access-token");
  let ipaddress = getCookie("ipaddress");
  let deviceId = localStorage.getItem("deviceId");
  let uId = getCookie("guestUserId");
  let user_id = getCookie("userId");
  let countryCode = getCookie("country_code");
  if (user_id) {
    uId = user_id;
  }
  const data = {
    device_id: deviceId,
    facebook_id: facebook_id,
    google_id: login_type == 'google' ? facebook_id : null,
    ipaddress: ipaddress,
    pubid: localStorage.getItem("pubid"),
    login_type: login_type,
    email: facebook_email,
    country_code: countryCode,
    first_name: first_name,
    last_name: last_name,
    platform: 'ecommerce'

  };
  const customConfig = {
    method: "POST",

    body: qs.stringify(data),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: localStorage.getItem("pubid"),
      country_code: countryCode,
      channelid: localStorage.getItem("channelid"),
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
  };

  var url = new URL(process.env.REACT_APP_API_URL + "/account/social/link");
  let res = {};
  let response = await fetch(url, customConfig);
  try {
    res = await response.json();
  } catch (err) {
    res = {};
  }
  res.status = response.status;

  return res;
}
function register(values, firebase_user_id) {
  headers.tid = localStorage.getItem("trans_id")
  headers.pubid = localStorage.getItem("pubid");
  headers.channelid = localStorage.getItem("channelid");
  headers.country_code = localStorage.getItem("C_Code");
  headers.dev_id = localStorage.getItem("deviceId");
  headers.ip = localStorage.getItem("IP");
  const data = {
    first_name: values.f_name,
    last_name: values.l_name,
    phone: values.mobile,
    user_email: values.email,
    password: values.password,
    type: "phone",
    ua: navigator.userAgent,
    platform: 'ecommerce',
    firebase_user_id:firebase_user_id
  };
  if (localStorage.getItem("guest_user_id")) {
    data.guest_user_id = parseInt(localStorage.getItem("guest_user_id"))
  }
  return axios
    .post(process.env.REACT_APP_API_URL + "/account/register", data, {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function loginToDevice(login_to_device) {
  headers.tid = localStorage.getItem("trans_id")
  headers.pubid = localStorage.getItem("pubid");
  headers.uid = localStorage.getItem("userId");
  headers.channelid = localStorage.getItem("channelid");

  const data = {
    checked : login_to_device
  };
  
  return axios
    .post(process.env.REACT_APP_API_URL + "/ecommerce/tv/login/update", data, {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}
function userExists(user_email,phone) {
  headers.pubid = localStorage.getItem("pubid");
  headers.channelid = localStorage.getItem("channelid");

  
  return axios
    .get(`https://staging.poppo.tv/jishna/api/account/user/exists?user_email=${user_email}&phone=${phone}`, {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
function verifyEmail(OTP) {
  headers.uid = localStorage.getItem("userId");
  headers.tid = localStorage.getItem("trans_id")
  headers.pubid = localStorage.getItem("pubid");

  let params = {
    otp: OTP,
  };

  return axios
    .get(process.env.REACT_APP_API_URL + "/account/otp/verify", {
      headers,
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function checkoutCheck() {
  // headers.uid = localStorage.getItem("cart_user_id")
  headers.tid = localStorage.getItem("trans_id")
  headers.uid = localStorage.getItem("userId")
  headers.pubid = localStorage.getItem("pubid");
  headers.channelid = localStorage.getItem("channelid");

  var data = {
    cart_id: localStorage.getItem("CID"),
    cart_user_id:localStorage.getItem("cart_user_id")

  }
  return axios
    .post(`${process.env.REACT_APP_API_URL}/ecommerce/checkout`, data, { headers: headers })
    .then((response) => {
     // console.log("response", response)
      return response;
    })
    .catch((error) => {
    });
};
async function login(values, type) {
  let ipaddress = localStorage.getItem("IP");
  let deviceId = localStorage.getItem("deviceId");
  let countryCode = localStorage.getItem("C_Code");
  headers.tid = localStorage.getItem("trans_id")
  headers.pubid = localStorage.getItem("pubid");
  headers.channelid = localStorage.getItem("channelid");

  let customConfig;
  var params = {
    user_email: values.email,
    password: values.password,
    platform: 'ecommerce'
  };
  if (type === "phone") {
    params = {
      login_type: "phone",
      firebase_id: values?.uid,
      phone: values.phoneNumber?values.phoneNumber.replace("+", ""):null,
      platform: 'ecommerce'
    };
  }
  console.log("params",params)
  if (localStorage.getItem("guest_user_id")) {
    params.guest_user_id = localStorage.getItem("guest_user_id")
  }
  var url = process.env.REACT_APP_API_URL + "/account/login";

  return axios
    .get(url, {
      headers,
      params,
    })
    .then((res) => {
      return { ...res.data, status: res.status };
    });
}

const getCountryList = async () => {
  let url = process.env.REACT_APP_API_URL + "/ecommerce/countries";
  return axios
    .get(url, {
      headers,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return [];
    });
};

const getStateList = async (country_code) => {
  let url = process.env.REACT_APP_API_URL + "/ecommerce/states/" + country_code;
  return axios
    .get(url, {
      headers,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return [];
    });
};

function shippindAddressAdd(values, isbilling) {
  headers.tid = localStorage.getItem("trans_id")
  headers.pubid = localStorage.getItem("pubid");

  const data = {
    first_name: values.firstname,
    last_name: values.lastname,
    phone: values.phone,
    user_email: values.user_email,
    ua: navigator.userAgent,
    is_billing_address: values.isBilling,
    city: values.city,
    country: values.country.label,
    zip: values.zip,
    address1: values.address1,
    address2: values.address2,
    province: values.state.label,
    is_billing_address: isbilling,
    cart_id: localStorage.getItem("CID"),
    state_code:values.state.shortCode
  };
 // console.log("data01", data);
  // console.log("data01 country",values.country);


  return axios
    .post(`${process.env.REACT_APP_API_URL}/ecommerce/user/address/add`, data, {
      headers,
    })
    .then((response) => {
     // console.log("response")

      return response;
    })
    .catch((error) => {
    //  console.log("error")
      return error.response;
    });
}

const getCardList = (pub_id) => {
  headers.tid = localStorage.getItem("trans_id")

  let url = process.env.REACT_APP_API_URL + "/ecommerce/spreedly/cards";
  return axios
    .get(url, {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return {
        data: false,
      };
    });
};

function payment(data) {
  headers.tid = localStorage.getItem("trans_id")

  var requestBody = {
    token: data.token,
    amount: data.amount,
    currencyCode: data.currencyCode,
  };
  return axios
    .post(
      process.env.REACT_APP_API_URL + "/ecommerce/spreedly/payment",
      requestBody,
      {
        headers,
      }
    )
    .then((response) => {
    //  console.log("success", response);
      return response;
    })
    .catch((error, response) => {
      // console.log("error",error.response,"response",response)
      return error.response;
    });
}

function cardDetailsSave(data) {
 // console.log(" data data", data);
  return axios
    .post(process.env.REACT_APP_API_URL + "/ecommerce/spreedly/card", data, {
      headers,
    })
    .then((response) => {
    //  console.log("success", response);
      return response;
    })
    .catch((error) => {
      return [];
    });
}

const getAddress = () => {
  headers.tid = localStorage.getItem("trans_id")

  return axios
    .get(process.env.REACT_APP_API_URL + "/ecommerce/user/address", {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
};
function getUserAddress() {
  headers.uid = localStorage.getItem("userId");
  headers.tid = localStorage.getItem("trans_id")

  var cid = localStorage.getItem("CID");

  return axios
    .get(process.env.REACT_APP_API_URL + "/ecommerce/user/address?cart_id=" + cid, {
      headers: headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => { });
}

const getAddressCompleted = (partialAddress) => {
  let url = `https://api.postgrid.com/v1/intl_addver/completions`;
  headers.tid = localStorage.getItem("trans_id")

  var data = qs.stringify({
    'partialStreet': partialAddress,
  });

  var config = {
    method: 'post',
    url: 'https://api.postgrid.com/v1/addver/completions',
    headers: {
      'x-api-key': 'live_pk_fpEJWEW2zoGPXBAezLE5Dz'
    },
    data: data
  };
  return axios(config)
    .then((res) => {
      return res.data
    }).catch(e => [])
};
function fetchCart() {
  headers.uid = localStorage.getItem("userId")
  headers.tid = localStorage.getItem("trans_id")

  var cart_id = localStorage.getItem("CID")
 // console.log("")
  if (cart_id) {

    return axios
      .get(`${process.env.REACT_APP_API_URL}/ecommerce/cart/${cart_id}`, { headers: headers })
      .then((response) => {
        if (response?.data?.data) {
          // console.log("cart_user_id",response.data.data.user_id)

          localStorage.setItem("cart_user_id", response.data.data.user_id);
          localStorage.setItem("pubid", response.data.data.pubid)
          localStorage.setItem("channelid", response.data.data.channelid)
        }
      //  console.log("response", response)
        return response.data;
      })
      .catch((error) => {
      });
  }
};
export const services = {
  register,
  setCookie,
  getCookie,
  verifyEmail,
  login,
  getCountryList,
  getStateList,
  shippindAddressAdd,
  getCardList,
  payment,
  cardDetailsSave,
  getAddress,
  getUserAddress,
  getAddressCompleted,
  checkoutCheck,
  fetchCart,
  facebookLogin,
  facebookLink,
  loginToDevice,
  userExists
};
