import React, {
    useEffect
} from "react"
import LineItem from "./LineItem"
import {
    useShopify
} from "../hooks"
import {
    MdShoppingCart,
    MdRemoveShoppingCart
} from "react-icons/md"
import {
    useHistory
} from "react-router-dom";
import {
    services
} from '../Service/service'


export default (props) => {
    const {
        cartStatus,
        closeCart,
        openCart,
        checkoutState,
        setCount,
    } = useShopify()

    function handleOpen(e) {
        e.preventDefault()
        openCart()
    }

    function handleClose(e) {
        e.preventDefault()
        closeCart()
    }
    let history = useHistory();

    function openCheckout(e) {
        e.preventDefault()
        console.log("checkoutState",checkoutState)
        var checkoutDetails = {};
        if(checkoutState.lineItems.length !==0){
          checkoutDetails = {
        	currencyCode:checkoutState.currencyCode,
        	id:checkoutState.lineItems[0].id,
        	quantity:checkoutState.lineItems[0].quantity,
        	title:checkoutState.lineItems[0].title,
        	price:checkoutState.lineItems[0].variant.price,
        	subtotalPrice:checkoutState.subtotalPrice,
        	totalTax:checkoutState.totalTax,
        	totalPrice:checkoutState.totalPrice
          }
        services.setCookie("checkoutDetails", JSON.stringify(checkoutDetails));
        console.log("checkOutDetails",localStorage.getItem('isLoggedIn'))
        if (localStorage.getItem('userId') && localStorage.getItem('userId') !== undefined && localStorage.getItem('isLoggedIn')) {
                      console.log("checkOutDetails address")

              services.getUserAddress()
                .then((response) => {
                    console.log("response",response)
                    if (response.data && response.data.shipping_address) {
                        history.push({
                            pathname: "/payment",
                            
                        });

                    } else {
                         history.push('/shippinginfo')

                    }
                    console.log("response", response)
                })
                .catch((error) => {
                    console.log("response", error)

                })
                      console.log("checkOutDetails hiii")
        } else {
            history.push({pathname:'/login',
                
        })
        }
       }
       //http://localhost:3000/
        // window.open(checkoutState.webUrl) // opens checkout in a new window
        //window.location.replace(checkoutState.webUrl) // opens checkout in same window
    }

    useEffect(() => {
        const button = document.querySelector("button.App__view-cart")
        if (cartStatus === true) {
            button.classList.add("hide")
        } else {
            button.classList.remove("hide")
        }

        function getCount() {
            let lineItems =
                checkoutState.lineItems && checkoutState.lineItems.length > 0 ?
                checkoutState.lineItems :
                []
            let count = 0
            lineItems.forEach((item) => {
                count += item.quantity
                return count
            })

            setCount(count)
        }

        getCount()
    }, [cartStatus, checkoutState])

    return ( <
        div id = "cart" >
        <
        div className = {
            `Cart ${cartStatus ? "Cart--open" : ""}`
        } >
        <
        div className = "App__view-cart-wrapper2" >
        <
        button className = "App__view-cart"
        onClick = {
            (e) => handleOpen(e)
        } >
        <
        MdShoppingCart / >
        <
        /button> <
        /div> <
        header className = "Cart__header" >
        <
        h2 > Your cart < /h2> <
        button className = "Cart__close"
        onClick = {
            (e) => handleClose(e)
        } >
        <
        MdRemoveShoppingCart / >
        <
        /button> <
        /header> <
        ul className = "Cart__line-items" >
        <
        LineItem / >
        <
        /ul> <
        footer className = "Cart__footer" >
        <
        div className = "Cart-info clearfix" >
        <
        div className = "Cart-info__total Cart-info__small" > Subtotal < /div> <
        div className = "Cart-info__pricing" >
        <
        span className = "pricing" > $ {
            checkoutState.subtotalPrice
        } < /span> <
        /div> <
        /div> <
        div className = "Cart-info clearfix" >
        <
        div className = "Cart-info__total Cart-info__small" > Taxes < /div> <
        div className = "Cart-info__pricing" >
        <
        span className = "pricing" > $ {
            checkoutState.totalTax
        } < /span> <
        /div> <
        /div> <
        div className = "Cart-info clearfix" >
        <
        div className = "Cart-info__total Cart-info__small" > Total < /div> <
        div className = "Cart-info__pricing" >
        <
        span className = "pricing" > $ {
            checkoutState.totalPrice
        } < /span> <
        /div> <
        /div> <
        button className = "Cart__checkout button"
        onClick = {
            (e) => openCheckout(e)
        } >
        Checkout <
        /button> <
        /footer> <
        /div> <
        /div>
    )
}