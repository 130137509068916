import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
const fpPromise = FingerprintJS.load();
var headers = {
  ua: navigator.userAgent,
  dev_id: localStorage.getItem("deviceId"),
  uid: localStorage.getItem("userId"),
  country_code: localStorage.getItem("C_Code"),
  pubid: localStorage.getItem("pubid"),
  // pubid:50029,
  device_type: "web",
  channelid: process.env.REACT_APP_CHANNELID,
  platform: "ecommerce",
};

function addProduct(
  transaction_id,
  pid,
  quantity,
  variantId,
  cart_id_include_flag
) {
  headers.uid = localStorage.getItem("cart_user_id");
  headers.tid = localStorage.getItem("trans_id");
  if(!transaction_id){
     delete headers["tid"]; 
  }
  headers.pubid = localStorage.getItem("pubid");
  headers.channelid = localStorage.getItem("channelid");
  let url = process.env.REACT_APP_API_URL + "/ecommerce/cart/add";
  var body = {
    // transaction_id: transaction_id,
    product_id: pid,
    product_count: quantity,
    variant_id: variantId,
  };
  if(transaction_id){
    body.transaction_id = transaction_id ; 
  }
  // if(localStorage.getItem('CID')){
  //   body.cart_id = localStorage.getItem('CID')
  // }
  if (cart_id_include_flag && localStorage.getItem("CID")) {
    body.cart_id = localStorage.getItem("CID");
  }
 // console.log("SSSS");
  return axios
    .post(url, body, { headers })
    .then((res) => {
     // if (localStorage.getItem("trans_id")) {
   // localStorage.removeItem("trans_id");

        //      localStorage.setItem("trans_id",res?.data?.transaction_id)

 // }
      return res;
    })
    .catch((e) => false);
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function loginToDevice(login_to_device) {
  headers.tid = localStorage.getItem("trans_id")
  headers.pubid = localStorage.getItem("pubid");
  headers.uid = localStorage.getItem("userId");
  headers.channelid = localStorage.getItem("channelid");

  const data = {
    checked : login_to_device
  };
  
  return axios
    .post(process.env.REACT_APP_API_URL + "/ecommerce/tv/login/update", data, {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}
function removeProduct(pid, variantId) {
  headers.uid = localStorage.getItem("cart_user_id");
  headers.tid = localStorage.getItem("trans_id");
  headers.pubid = localStorage.getItem("pubid");
  headers.channelid = localStorage.getItem("channelid");

  let url = process.env.REACT_APP_API_URL + "/ecommerce/cart/remove";
  var body = {
    cart_id: localStorage.getItem("CID"),
    product_id: pid,
    variant_id: variantId,
  };
  return axios
    .post(url, body, { headers })
    .then((res) => {
      return res;
    })
    .catch((e) => false);
}

function updateProductCount(pid, variantId, product_count) {
  headers.uid = localStorage.getItem("cart_user_id");
  headers.tid = localStorage.getItem("trans_id");
  headers.pubid = localStorage.getItem("pubid");
  headers.channelid = localStorage.getItem("channelid");

  let url = process.env.REACT_APP_API_URL + "/ecommerce/cart/update";
  var body = {
    cart_id: localStorage.getItem("CID"),
    product_id: pid,
    product_count: product_count,
    variant_id: variantId,
  };

  return axios
    .post(url, body, { headers })
    .then((res) => {
      return res;
    })
    .catch((e) => false);
}

const getGeoInfo = async () => {
  return axios
    .get("https://giz.poppo.tv/service/ipinfo")
    .then((response) => {
      localStorage.setItem("C_Code", response.data.countryCode);
      localStorage.setItem("IP", response.data.query);
      return response.data;
    })
    .catch((error) => { });
};
const genarateDevideID = async () => {
  const fp = await fpPromise;
  const result = await fp.get();
  const visitorId = result.visitorId;
  localStorage.setItem("deviceId", visitorId);
  //console.log("visitorId", visitorId);
};
genarateDevideID();
getGeoInfo();
const updateProductView = (transaction_id, pid) => {
  headers.uid = localStorage.getItem("userId");
  headers.tid = localStorage.getItem("trans_id");
  headers.pubid = localStorage.getItem("pubid");
  headers.channelid = localStorage.getItem("channelid");

  let url = process.env.REACT_APP_API_URL + "/ecommerce/session/event";
  var body = {
    transaction_id,
    pid,
    e: "pv",
  };

  axios
    .post(url, body, { headers: headers })
    .then((res) => {
      return false;
    })
    .catch((e) => false);
};
const redirectCall = (productId, eventType) => {
  let url = `${process.env.REACT_APP_API_URL}/ecommerce/session/initiate?pid=${productId}&e=${eventType}`;

  axios
    .get(url, { headers: headers })
    .then((res) => {
      var url_string = res.data.url;
      var url = new URL(url_string);
      if (!localStorage.getItem("userId")) {
        localStorage.setItem("userId", url.searchParams.get("uid"));
      }
      localStorage.setItem("trans_id", url.searchParams.get("tid"));

      setTimeout(() => {
        window.location.href = res.data.url;
      }, 500);
    })
    .catch((e) => {
      return;
    });
};
function getProducts(id, event) {
  // headers.uid = localStorage.getItem("userId")
  headers.tid = localStorage.getItem("trans_id");

  delete headers["uid"];
  delete headers["pubid"];
  delete headers["channelid"];

  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/ecommerce/product/${id}?e=${event}`,
      { headers: headers }
    )
    .then((response) => {
   //   console.log("response------------", response.data.data);
      if (response?.data?.data?.user_id) {
        localStorage.setItem("cart_user_id", response.data.data.user_id);
        localStorage.setItem("pubid", response.data.data.pubid);
        localStorage.setItem("channelid", response.data.data.channelid);

        //set pubid channelid to localstorage

        // console.log("localStorage.getItemuserId",localStorage.getItem("userId") );
        // console.log("response.data.data.user_id",response.data.data.user_id);

        // if (localStorage.getItem("userId") != response.data.data.user_id) {
        // localStorage.setItem("previous_user_id",localStorage.getItem("userId"));
        // localStorage.setItem("userId",response.data.data.user_id)
      }
      //else{
      //   localStorage.setItem("userId",response.data.data.user_id)

      // }

      //        }
      return response.data;
    })
    .catch((error) => { });
}
function checkoutCheck() {
  headers.uid = localStorage.getItem("userId");
  headers.tid = localStorage.getItem("trans_id");
  headers.pubid = localStorage.getItem("pubid");
  headers.channelid = localStorage.getItem("channelid");
  var data = {
    cart_id: localStorage.getItem("CID"),
    cart_user_id:localStorage.getItem("cart_user_id")
  };
  // console.log("localStorage.getItemuserId", localStorage.getItem("userId"));
  // console.log(
  //   "localStorage.getItemprevious_user_id",
  //   localStorage.getItem("previous_user_id")
  // );

  //if((localStorage.getItem("userId") == localStorage.getItem("previous_user_id")) || !localStorage.getItem("previous_user_id") ){
  return axios
    .post(`${process.env.REACT_APP_API_URL}/ecommerce/checkout`, data, {
      headers: headers,
    })
    .then((response) => {
    //  console.log("response 02", response);
      return response;
    })
    .catch((error) => { });
  //}else{
  // window.location.href ='/login'
  // console.log("response 01")

  //}
}
function fetchCart(cart_id,tid) {
  localStorage.setItem("trans_id",tid)
  headers.tid = tid;
  headers.pubid = localStorage.getItem("pubid");
  headers.channelid = localStorage.getItem("channelid");
  delete headers["uid"];
  var cart_id = cart_id;
 // console.log("");

  if (cart_id) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/ecommerce/cart/${cart_id}`, {
        headers: headers,
      })
      .then((response) => {
        if (response?.data?.data) {
          // console.log("cart_user_id",response.data.data.user_id)
          if (response.data?.data?.user_id) {
            services.setCookie("user_email", response?.data?.data?.user_email);
            localStorage.setItem("pubid", response.data.data.pubid)
            localStorage.setItem("channelid", response.data.data.channelid)
            localStorage.setItem("cart_user_id", response.data.data.user_id);
          }
          // if (localStorage.getItem("userId") != response.data.data.user_id) {
          if (response.data?.data?.guest_user_id) {
            localStorage.setItem(
              "guest_user_id",
              response.data.data.guest_user_id
            );
            //     localStorage.setItem("userId", response.data.data.user_id)
          } else {
            localStorage.removeItem("guest_user_id");
          }
          // } else {
          //     localStorage.setItem("userId", response.data.data.user_id)

          // }
        }
     //   console.log("response", response);
        return response.data;
      })
      .catch((error, response) => {
            return error.response.data;
        });
  }
}
function getUserAddress() {
  headers.uid = localStorage.getItem("userId");
  headers.tid = localStorage.getItem("trans_id");
  headers.pubid = localStorage.getItem("pubid");
  headers.channelid = localStorage.getItem("channelid");

  var cid = localStorage.getItem("CID");

  return axios
    .get(
      process.env.REACT_APP_API_URL + "/ecommerce/user/address?cart_id=" + cid,
      { headers: headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => { });
}
export const services = {
  removeProduct,
  addProduct,
  updateProductCount,
  getGeoInfo,
  redirectCall,
  updateProductView,
  getUserAddress,
  setCookie,
  getCookie,
  getProducts,
  fetchCart,
  checkoutCheck,
  loginToDevice
};
