import axios from "axios";
import React, { useEffect, useState } from "react";

import "./payment.css";

function Payment() {
  const [appleButon, setAppleButon] = useState("");
  const [disable, setDisable] = useState(true);
  const [count, setcount] = useState(0);

  var applePaySession;
  var MERCHANT_ID = "merchant.gizmeon.inc";
  // var BACKEND_URL_VALIDATE_SESSION = window.location.href + "validateSession";
  var BACKEND_URL_VALIDATE_SESSION =
    "https://4276-43-229-88-131.ngrok.io/" + "validateSession";
  var BACKEND_URL_PAY = window.location.href + "pay";

  var appleButton;
  useEffect(() => {
    
    if (count === 0) {
      //   check apple pay is available and user has a active card
      // only then displaying the apple pay button
      if (window.ApplePaySession) {
        console.log("apple-pay-session-inner-if");
        console.log("apple-pay-session", window.ApplePaySession);
        var merchantIdentifier = "merchant.gizmeon.inc";
        var promise =
          window.ApplePaySession.canMakePaymentsWithActiveCard(
            merchantIdentifier
          );
        promise.then(function (canMakePayments) {
          console.log("one");
          console.log(canMakePayments);
          if (window.ApplePaySession.canMakePayments) {
            console.log("two");
            setDisable(false);

            // appleButon.style.display = "block";
          } else {
            console.log("no-active card");
          }
        });
      }
    }
    setcount(count + 1);
  }, []);

  useEffect(() => {
    if (!disable && count <= 1) {
      appleButton = document.getElementById("apple-pay-button");
      console.log("inside-useEffect", appleButton);
      setAppleButon(appleButton);
    }
  }, [disable]);

 

  //click event handler
  appleButon &&
    appleButon.addEventListener("click", function () {
      console.log("clicked");

      var request = {
        countryCode: "US",
        currencyCode: "USD",
        supportedNetworks: ["visa", "masterCard", "amex", "discover"],
        merchantCapabilities: ["supports3DS"],
        total: { label: "Your Merchant Name", amount: "0.01" },
      };
      var applePaySessions=new window.ApplePaySession(3, request);
      console.log("session-check",applePaySessions);
      window.ApplePaySession = new window.ApplePaySession(3, request);
      console.log("real-session",window.ApplePaySession);
      window.ApplePaySession.begin();
    
    
    
    // });

  

  //first event triggered
  //apple pay session from back end
  console.log(window.ApplePaySession);
  window.ApplePaySession.onvalidatemerchant = function (event) {
    console.log("first-trigger", event);
    var theValidationURL = event.ValidationURL;
    console.log("validation UrL", theValidationURL);
    validateTheSession(theValidationURL, function (merchantSession) {
      console.log("api-success");
      applePaySession.completeMerchantValidation(merchantSession);
    });
  };

  //triggers after the user confirmed the transaction with touch ID or face ID
  //contain payment token
  // applePaySession.onpaymentauthorized = function (event) {
  //   var applePaymentToken = event.payment.token;

  //   pay(applePaymentToken, function (outcome) {
  //     if (outcome) {
  //       window.ApplePaySession.completePayment(
  //         window.ApplePaySession.STATUS_SUCCESS
  //       );
  //     } else {
  //       window.ApplePaySession.completePayment(
  //         window.ApplePaySession.STATUS_FAILURE
  //       );
  //     }
  //   });
  // };
});


  var validateTheSession = function (theValidationURL, callback) {
    //sending validation url  to backend
    console.log("api-request");
    axios
      .post(
        "https://staging.poppo.tv/test/api/ecommerce/applepay/validateSession"
      )
      .then(function (response) {
        callback(response.data);
      });
  };

  var pay = function (applePaymentToken, callback) {
    axios
      .post(
        BACKEND_URL_PAY,
        {
          token: applePaymentToken,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .the(function (response) {
        callback(response.data);
      });
  };

  // });
 

  return (
   

    !disable && <div id="apple-pay-button" className="apple-pay-button"></div>
    
  );
}

export default Payment;
