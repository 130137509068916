import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import React, { useState } from 'react'
function Stripe() {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setSubmitted(true);
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        let paymentData = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url:'https://buy.flixbuy.tv/payment'
            },
        });
        if (paymentData.error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(paymentData.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement/>
            <div className='d-flex justify-content-center mt-3'>
            <button style={{ background: "blue", borderStyle: "none", color: "white",width:'200px' }} disabled={!stripe || submitted}>{submitted ? "Processing" : "PAY"}</button>
            </div>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    )

}

export default Stripe

