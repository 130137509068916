import React, { Fragment, useEffect,useState } from "react"
import { useShopify } from "../hooks"
import {services} from '../Service/service' 

export default (props) => {
	const { updateQuantity, removeLineItem } = useShopify()
const [id,setId] = useState([])
const [checkoutState,setCheckoutState] =useState(props.lineItems)
 useEffect(() => {
 	if(props.lineItems?.data){
 		 	setCheckoutState(props.lineItems.data)

 	}else{
 		 		 	setCheckoutState(props.lineItems)

 	}

}, [props.lineItems])
	function decrementQuantity(index,lineItemId, lineItemQuantity,product_id,variant_id, e) {


		// checkoutState.lineItems[index].quantity = lineItemQuantity -1;
		// setCheckoutState({...checkoutState});
console.log("res",checkoutState)

		services.updateProductCount(product_id,variant_id,lineItemQuantity -1).then((res)=>{
					setCheckoutState(res.data.data);

console.log("res",res)
		},
		(error)=>{
console.log("error",error)

		})

		// e.preventDefault()
		// const checkoutId = checkoutState.id
		// const updatedQuantity = lineItemQuantity - 1
		// updateQuantity(lineItemId, updatedQuantity, checkoutId,'DEC',product_id,variant_id)
	}

	function incrementQuantity(index,lineItemId, lineItemQuantity,product_id,variant_id, e) {
		// e.preventDefault()
		// const checkoutId = checkoutState.id
		// const updatedQuantity = lineItemQuantity + 1
		// updateQuantity(lineItemId, updatedQuantity, checkoutId,'INC',product_id,variant_id)
		// checkoutState.lineItems[index].quantity = lineItemQuantity +1;
		// setCheckoutState({...checkoutState});
		services.updateProductCount(product_id,variant_id,lineItemQuantity +1).then((res)=>{
					setCheckoutState(res.data.data);

console.log("res",res)
		},
		(error)=>{
console.log("error",error)

		})

	}

	function deleteLineItem(index,lineItemId, product_id,variant_id,e) {
		// e.preventDefault()
		// console.log(product_id);
		// const checkoutId = checkoutState.id
		 services.removeProduct(product_id,variant_id).then((res)=>{
					console.log("res",res)

					setCheckoutState(res.data.data);

		},
		(error)=>{
console.log("error",error)

		})

		 //checkoutState.lineItems.splice(index, 1)
				// setCheckoutState({...checkoutState});
				// 	 services.removeProduct(product_id,variant_id)

	}
 // useEffect(() => {
 //    services.fetchCart().then((response) =>{
 //    console.log("response",response)
 //    setCheckoutState(response.data)
 //    } ,
 //      (error) => {
 //        return false;
 //      }
 //      )
    
 //  }, [id]);
	return (
		<li className="Line-item" style={{    minHeight: '50vh',overflowY: 'scroll'}}>
			{checkoutState.lineItems &&
				checkoutState.lineItems.map((lineItem, i) => {
					return (
						<div key={`${lineItem.title}` + i} className="lineItemDiv">

							<div className="Line-item__img">
								{lineItem.image ? (
									<img
										src={lineItem.image}
										alt={`${lineItem.title} product shot`}
										width='200px'
										height='400px'
									/>
								) : null}
							</div>
							<div className="Line-item__content">
								<div className="Line-item__content-row">
									<div className="Line-item__variant-title col">
										{lineItem.variant_title}
									</div>
									<span className="Line-item__title">{lineItem.title}</span>
								</div>
								{/* {lineItem.available ?(  */}
								<div className="Line-item__content-row">
								{lineItem.available ?(
									<Fragment>
									<div className="Line-item__quantity-container">
									
										
										<button
											className="Line-item__quantity-update"
											onClick={(e) =>
												props.decrementQuantity(i,lineItem.lineitem_id, lineItem.quantity,lineItem.product_id,lineItem.variant_id, e)
											}
										>
											-
										</button>
										<span className="Line-item__quantity">
											{lineItem.quantity}
										</span>
										<button
											className="Line-item__quantity-update"
											onClick={(e) => {
												props.incrementQuantity(i,lineItem.lineitem_id, lineItem.quantity,lineItem.product_id,lineItem.variant_id, e,lineItem.quantityAvailable)
											}}
										>
											+
										</button>
										
									</div>
									</Fragment>):(<p style={{color:"red",fontSize:"1.8rem",marginTop:"1rem"}}>OUT OF STOCK </p>)}
									<span className="Line-item__price">
										{lineItem.price}
									</span>
									<button
										className="Line-item__remove"
										style={{top:"-10px"}}
										onClick={(e) => props.deleteLineItem(i,lineItem.lineitem_id,lineItem.product_id,lineItem.variant_id, e)}
									>
										×
									</button>
								</div>
								{/* ): */}

								{/* (
								<Fragment>
									<button
									className="Line-item__remove"
									onClick={(e) => props.deleteLineItem(i,lineItem.lineitem_id,lineItem.product_id,lineItem.variant_id, e)}
								>
									×
								</button> <p style={{color:"red",fontSize:"1.8rem",marginTop:"1rem"}}>OUT OF STOCK </p></Fragment>)
								}
								 */}
							</div>
						</div>
					)
				})}
		</li>
	)
}
