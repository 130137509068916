import React, { Fragment, useState, useEffect } from "react";
import image from "../../assets/tick.jpg";
import { services } from "./service";
import { toast } from "react-toastify";
import "./payment.css";

function App({ history }) {
  var checkout_id = localStorage.getItem("CID");
  var billing_address = services.getCookie("billing_address");
  const [orderId, setOrderId] = useState({});
  const MINUTE_MS = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      //if(orderId){
      //console.log("orderId",orderId) ;    

      services.getOrderStatus().then((response) => {
        if (response.status == 200 && response?.data?.order_id) {
         // console.log("response orderCreate 200", response)
          localStorage.removeItem("CID");
          history.push({
            pathname: "/order/details",
            state: { id: response.data.order_id }
          });
        }
      })
        .catch((error) => {

        })
      //}
     // console.log('Logs every minute');
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [])
  // if(checkout_id && billing_address){
  //     billing_address = JSON.parse(billing_address);
  //     billing_address.province=billing_address.state ;
  //     billing_address.firstName=billing_address.firstname;
  //     billing_address.lastName=billing_address.lastname;
  //     billing_address.phone="+"+billing_address.phone;

  //     delete billing_address['firstname'];
  //      delete billing_address['lastname'];
  //      delete billing_address['state'];
  //      delete billing_address['user_email'];

  //      services.orderCreate(checkout_id,billing_address).then(
  //         (response) => {

  //        if(response ?.data ?.order){
  //       // setOrderId(response.data.order);

  //          // history.push({
  //          //   pathname: "/order/details",
  //          //   state:{id:response.data.order}
  //          // });
  //        }else{
  //          if(response ?.data ?.error ?.errors ?.order ?.[0]){

  //         toast.error(response ?.data ?.error ?.errors ?.order ?.[0] , {
  //         position: toast.POSITION.BOTTOM_LEFT,
  //           });
  //         }
  //        }
  //      },
  //      (error) => {
  //         return false;
  //      }
  //     );
  //   }
  return (
    <div className="App">
      <img src={image} alt="Girl in a jacket" className="success_style" />
      <h1 style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: '5%',
        marginTop: '3%'
      }}>Payment Successful !</h1>
      <p style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: '5%',
        marginTop: '7%',
        margin: '0 0 15px',
        fontSize: 'small',
      }}>Please wait until the current process to be completed...</p>
    </div>
  );
}

export default App;
