import React, { Fragment, useState, useEffect } from "react";
import "../Login/style.css";
import Image from "../../assets/delivery.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import edit from "../../assets/edit.svg";
//import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Menudetails from "../menu.js";

import {
  Col,
  Row,
  Button,
  CustomInput,
  FormGroup,
  Input,
  Card,
  ListGroup, ListGroupItem,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";

import "./order.css";

import { services } from "./service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

var CryptoJS = require("crypto-js");

const Login = ({ history }) => {

  var id = history.location.state.id
  //console.log("id", id)
  const [values, setValues] = useState({
    id: id
  });
  const [isLoading, setIsLoading] = useState(true);



  useEffect(() => {

    if (values.id) {
      services.getOrderDetails(values.id).then(
        (response) => {
          if (response) {
            //console.log("response", response)
            var data = response.data;
            var contact = data.phone
            var deliver_address = data.shipping_address ? data.shipping_address.address1 +
              ", " +
              data.shipping_address.address2 +
              ", " +
              data.shipping_address.city +
              ", " +
              data.shipping_address.province +
              ", " +
              data.shipping_address.zip +
              ", " +
              data.shipping_address.country
              : null;
            var created_at = data.created_at;
            var line_items = data.line_items;
            var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
              "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            var selectedMonthName = months[parseInt(created_at.split("T")[0].split("-")[1]) - 1];
            var selectedDate = created_at.split("T")[0].split("-")[2]
            var selectedYear = created_at.split("T")[0].split("-")[0]
           // console.log(selectedMonthName, selectedDate, selectedYear)
            var status = "Ordered  on " + selectedMonthName + " " + selectedDate + "," + selectedYear;
            var total_price = data.total_price;
            var name = data.name;
            var first_name = data.shipping_address.first_name + " " + data.shipping_address.last_name;
            setValues({
              ...values,
              contact: contact,
              deliver_address: deliver_address,
              line_items: line_items,
              status: status,
              total_price: total_price,
              name: name,
              delivery_message: data.delivery_message,
              order_message: data.order_message,
              first_name: first_name,
              data: data,
              subtotal_price: data.subtotal_price,
              shipping_price: data.total_shipping_price,
              total_tax: data.total_tax,
              total_discounts: data.total_discounts,
              total_price: data.total_price,
              dataupdated: true
            });
            setIsLoading(false)
            //console.log("contact", contact, "deliver_address", deliver_address, "line_items", line_items)
          }
          //console.log("data ", response)
        },
        (error) => {
         // console.log("data error", error)

        }
      );
    }
  }, [values.id]);
  const hangleChange = (value, name) => {
   // console.log("value", value, "name", name)
    setValues({
      ...values,
      [name]: value,
    });
  };

  const sample = (id) => {
   // console.log("id", id)
    window.location.href = "/Product?pid=" + id;
    // history.push({
    //            pathname: "/Product?pid="+id,
    //          });
  };
  const Orders = () => {


    window.location.href = "/order/list";

  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };




  return (
    <form className="order_list" >
      <div className="order_details" >

        <Menudetails
          handleClick={handleClick}
          anchorEl={anchorEl}
          handleClose={handleClose}
          Orders={Orders}
          color={'#0d6efd'}
        />
        <h2 style={{ marginLeft: '6%', marginTop: '5%', fontSize: '2.5rem', marginBottom: '10px' }} className="order_hr order_mobile "><b>Order Details</b></h2>

      </div>
      {values.line_items && <div className="payment_heading" style={{ textAlign: "left" }}>

        <div className="addressCard" style={{ backgroundColor: 'rgb(243, 241, 241)' }}>
          <div className="address_section details_section" >
            <div className="payment_heading"> Shipping Details </div>{" "}
            <div className="row">
              <div className="ship_bill">
                {" "}
                <b> Contact </b>{" "}
                {/*  <Button className="checkoutButton" style={{border: 'none',marginTop:'-5px',fontSize: 'small',marginLeft: '55%',backgroundColor: '#979da5'}}>
                                           <FontAwesomeIcon icon="edit" />
                                  </Button> */}{" "}

              </div>

              <div className="col col-sm-12 feilds">
                <label
                  style={{
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  {values.contact}{" "}
                </label>
              </div>
            </div>{" "}
            <hr />
            <div className="row">
              <div className="ship_bill">
                {" "}
                <b> Shipping Address </b>{" "}

              </div>
              <div className="col col-sm-12 feilds">
                <label
                  style={{
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  {values.deliver_address}{" "}
                </label>
              </div>{" "}
              <div className="payment_heading" style={{ marginBottom: 'auto' }}> Product Details </div>{" "}
              {values.line_items && values.line_items.map((element, index) => {
                return (
                  <>  <div className="row" onClick={() => { sample(element.product_id) }}>
                    <img src={element.image} alt="Girl in a jacket" style={{ maxWidth: '100px', maxHeight: '100px', marginLeft: 'auto', marginRight: 'auto' }} />
                  </div>

                    <div className="row breadcrumb">

                      <Card style={{ maxWidth: '90%', marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'rgb(243, 241, 241)' }}>
                        <CardActionArea>

                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h2"><b>
                              {element.title}</b>
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              <ul style={{ listStyleType: 'none', marginLeft: '-10px' }}>
                                {element.variant_title ? <li style={{ fontSize: 'medium' }}>Variant : {element.variant_title}</li> : null}
                                {element.vendor ? <li style={{ fontSize: 'medium' }}>Vendor : {element.vendor}</li> : null}
                                {element.quantity ? <li style={{ fontSize: 'medium' }}>Quantity  : {element.quantity}</li> : null}
                                {element.price ? <li style={{ fontSize: 'medium' }}>Price : {element.price}</li> : null}

                              </ul>
                            </Typography>
                          </CardContent>
                        </CardActionArea>

                      </Card>




                    </div> </>
                );
              })}




            </div>
            <div className="pricingShipMobile">
              <div className="PricingItems">
                <hr />
                <div className="PricingItem">
                  <div className="PricingItemHead"> {values.name + "," + values.status} </div>{" "}

                </div>{" "}

                <hr />
                <div className="PricingItem" style={{ marginBottom: '20px' }}>
                  <div className="PricingItemHeadTotal">Total Price </div>{" "}
                  <div
                    style={{
                      fontWeight: "900",
                      fontSize: "1.5rem",
                    }}
                  >
                    {values.total_price}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          </div>{" "}
          <div className="details_section">
            <div className="pricingShip">
              <div className="pricingHead">
                <div style={{
                  fontWeight: "900",
                  fontSize: "1.5rem",
                }}> {values.name + "," + values.status} </div>{" "}
              </div>{" "}
              <div className="PricingItems">


                <div className="PricingItem" style={{ marginLeft: '15%' }}>
                  <div className="PricingItemHead">Total Price </div>{" "}
                  <div style={{
                    fontWeight: "900",
                    fontSize: "1.5rem",
                  }}>  {values.total_price} </div>{" "}

                </div>{" "}
              </div>{" "}
            </div>
            <div className="shopingIMG">
              <img src={Image} />{" "}
            </div>{" "}
          </div>{" "}
        </div>
      </div>}
      <div >
        {values.dataupdated ? <div className="order_mobile" style={{ marginTop: '-30%' }} >




          <Card className="card_style" style={{ border: '0px solid rgba(0,0,0,.125)' }}>
            <CardBody>
              <hr />
              <Row >
                <Col xs={4}>
                  <label style={{ fontWeight: 'bold', fontSize: 'medium' }}>Order ID :</label>

                </Col>
                <Col xs={8}>
                  <label style={{ fontWeight: 'bold', fontSize: 'medium' }}>{values.name}</label>
                </Col>
              </Row>

              <hr />
              {values.line_items && values.line_items.map((element, index) => {
                return (
                  <>

                    <Row >
                      <Col xs={3}>
                        <img src={element.image} alt="Girl in a jacket" style={{ maxWidth: '80px', maxHeight: '80px' }} />

                      </Col>
                      <Col xs={9}>
                        <label style={{ fontSize: 'small', marginLeft: '18px', fontWeight: 'bold' }}>
                          {element.title}</label>

                        <ul style={{ listStyleType: 'none' }}>
                          {/* <li>Variant  : {element.variant_title}</li>
      <li>Seller : {element.vendor}</li>
      <li>Quantity  : {element.quantity}</li> 
      <li style={{marginTop: '10px'}} > {element.title} </li>

    */}
                          {element.variant_title ? <li style={{ fontSize: 'medium' }}><span >Variant</span><span style={{ marginLeft: '1.1rem' }}>:</span>{element.variant_title}</li> : null}
                          {element.vendor ? <li style={{ fontSize: 'medium' }}><span >Vendor</span><span style={{ marginLeft: '1.1rem' }}>:</span>{element.vendor}</li> : null}
                          {element.quantity ? <li style={{ fontSize: 'medium' }}><span >Quantity</span><span style={{ marginLeft: '.4rem' }}>:</span> {element.quantity}</li> : null}
                          {element.price ? <li style={{ fontSize: 'medium' }}><span >Price </span><span style={{ marginLeft: '2.3rem' }}>:</span>{element.price}</li> : null}

                        </ul>
                      </Col>

                    </Row>
                  </>
                );
              })}
              <hr />
              <Row >
                <Col xs={3}>
                  <label style={{ fontWeight: 'bold', fontSize: 'medium' }}>Ordered</label>

                </Col>
                <Col xs={9}>
                  <label style={{ fontSize: 'medium' }}>{values.order_message}</label>
                </Col>
              </Row>
              <Row >
                <Col xs={3}>
                  <label style={{ fontWeight: 'bold', fontSize: 'medium' }}>Delivered</label>

                </Col>
                <Col xs={9}>
                  <label style={{ fontSize: 'medium' }}>{values.delivery_message}</label>
                </Col>
              </Row>
              <hr />
              <Row >
                <label style={{ fontWeight: 'bold', fontSize: 'medium' }}>Shipping Details</label>
              </Row>
              <hr />
              <Row >
                <label style={{ fontWeight: 'bold', fontSize: 'medium' }}>{values.first_name}</label>
                <label style={{ fontSize: 'medium' }}>{values.deliver_address}</label>
              </Row>
              <hr />
              <Row >
                <label style={{ fontWeight: 'bold', fontSize: 'medium' }}>Price Details</label>
              </Row>
              <hr />
              <Row >
                <Col xs={6}>
                  <label style={{ fontSize: 'medium' }}>Subtotal price </label>

                </Col>
                <Col xs={6}>
                  <label style={{ fontSize: 'medium', float: 'right' }}>{values.subtotal_price}</label>
                </Col>
              </Row>
              <Row >
                <Col xs={6}>
                  <label style={{ fontSize: 'medium' }}>Shipping price </label>

                </Col>
                <Col xs={6}>
                  <label style={{ fontSize: 'medium', float: 'right' }}>{values.shipping_price}</label>
                </Col>
              </Row>
              <Row >
                <Col xs={6}>
                  <label style={{ fontSize: 'medium' }}>Total tax </label>

                </Col>
                <Col xs={6}>
                  <label style={{ fontSize: 'medium', float: 'right' }}>{values.total_tax}</label>
                </Col>
              </Row>
              <Row >
                <Col xs={6}>
                  <label style={{ fontSize: 'medium' }}>Total Discounts </label>

                </Col>
                <Col xs={6}>
                  <label style={{ fontSize: 'medium', float: 'right' }}>{values.total_discounts}</label>
                </Col>
              </Row>
              <hr />
              <Row >
                <Col xs={6}>
                  <label style={{ fontWeight: 'bold', fontSize: 'medium' }}>Total  </label>

                </Col>
                <Col xs={6}>
                  <label style={{ fontWeight: 'bold', fontSize: 'medium', float: 'right' }}>{values.total_price}</label>
                </Col>
              </Row>
            </CardBody>
          </Card>


        </div> : null}
      </div >

      {isLoading ? (
          <div id="app" class="loader" style={{ position: 'fixed',top: '50%',left: '50%',marginTop: '-50px',marginLeft: '-50px'}}></div>
      ) : null}

    </form>
  );
};

export default Login;
