import firebaseApp from "firebase/app"
const firebaseConfig = {
  apiKey: "AIzaSyBeu-fcDSj76dOgB0IEKOP8XzZNSsMtC20",
  authDomain: "flixbuy-c9ead.firebaseapp.com",
  projectId: "flixbuy-c9ead",
  storageBucket: "flixbuy-c9ead.appspot.com",
  messagingSenderId: "1015384729091",
  appId: "1:1015384729091:web:3842443c4c4db814351036"
};

firebaseApp.initializeApp(firebaseConfig);
export default firebaseApp;