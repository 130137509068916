import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement, CardElement } from '@stripe/react-stripe-js';
import { services } from "./service";
import { toast } from "react-toastify";

const StripePaymentStatus = ({ history }) => {
  const stripe = useStripe();
  const [message, setMessage] = useState("Please wait...");

  const payment = async (payment_method_id) => {
    var billing_address = services.getCookie("billing_address");
    var checkoutDetails = services.getCookie("checkoutDetails");
    checkoutDetails = JSON.parse(checkoutDetails);
    let data = {
      cart_id: localStorage.getItem("CID"),
      // billing_address: billing_address,
      amount: checkoutDetails.TotalPrice,
      currencyCode: checkoutDetails.currency_code,
      // save_card:true,
      // session_id:session_id,
      // payment_method: 'stripe',
      payment_method_id: payment_method_id,
    };
    services.payment(data).then(
      (response) => {
        if (response?.status == 200) {
          if (response?.data?.success == false) {
            var message = response.data.message;
            toast.error(message);
          }
          else {
            window.location.href = "/payment/success"
          }
        } else {
          toast.error(`Something Went Wrong`, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      },
      (error) => {
        toast.error(`Something Went Wrong`, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
  }
  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .then((response) => {
        let { payment_method } = response.paymentIntent;
        if (payment_method) {
          payment(payment_method);
        }
      });
  }, [
    stripe
  ]);


  return (
    <>
      <div>{message}</div>
    </>
  )
};

export default StripePaymentStatus;