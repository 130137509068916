import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom'
import { Link } from "react-router-dom";
import "./style.css";
import PhoneInput from "react-phone-input-2";
import { ToastContainer, toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import { services } from "./service";
import firebase from "./firebase";
import "firebase/auth";
import { unmountComponentAtNode } from "react-dom";
import logo from "../flix-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import FacebookIcon from '@material-ui/icons/Facebook';
import { confirmAlert } from "react-confirm-alert";
import { FcGoogle } from 'react-icons/fc';
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import {
  Col,
  Row,
  Button,
  CustomInput,
  FormGroup,
  Input,
  Card,
  CardBody,
  Label
} from "reactstrap";
const Login = ({ history }) => {
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  const [userID, setUserID] = useState(localStorage.getItem('userId'))
  const [isLoggedIn, setisLoggedIn] = useState(localStorage.getItem('isLoggedIn'))
  const [isVerifyEmail, setVerify] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [eye1, setEye1] = useState(<FontAwesomeIcon icon={faEye} />);
  const [faceBookDatas, setFacebookDatas] = useState({
    facebookId: "",
    facebookEmail: "",
  });
  const [isNewUser, setIsNewUser] = useState("");
  const [loginPopup, setLoginPopup] = useState(false);

  let FBData = null;
  const [flag, setFlag] = useState({
    isRegister: true,
    isFbAcive: true,
    isSuccessMsg: true,
    isErrorMsg: false,
    isErrorVerifyMsg: false,
    isEmailExistMsg: false,
    isFirstNameSelected: false,
    isEmailSelected: false,
    isPasswordSelected: false,
    isVerifyEmailSelected: false,
  });
  const setUpRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function (response) {
            //sentOTP(values.mobile);
          },
        }
      );
    } else {
    }
    const recaptchaVerifier = window.recaptchaVerifier;

    // [START auth_phone_recaptcha_render]
    recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  };
  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
    setEye1(
      passwordShown1 ? (
        <FontAwesomeIcon icon={faEye} />
      ) : (
        <FontAwesomeIcon icon={faEyeSlash} />
      )
    );
  };
  const sentOTP = (dailCode, phone) => {
    let phoneNumber = '+' + dailCode + phone;
   // console.log(phoneNumber);
    let appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(function (confirmationResult) {
        window.confirmationResult = confirmationResult;
        setOtp({
          otpSend: true,
        });
      })
      .catch(function (error) {
        unmountComponentAtNode(document.getElementById("recaptcha-container"));
        window.recaptchaVerifier.render().then((widgetId) => {
          window.recaptchaVerifier.reset(widgetId);
        });
        toast.error()
      });
  };
  const setUserDetailsTOLocal = (userDetails) => {
    localStorage.setItem("userId", userDetails.user_id);
    localStorage.setItem("isLoggedIn", true);
    services.setCookie("firstName", userDetails?.first_name);
    services.setCookie("lastName", userDetails?.last_name);
    services.setCookie("login_type", userDetails?.login_type);
    services.setCookie("phone", userDetails?.phone);
    services.setCookie("user_email", userDetails?.user_email);
    services.setCookie("user_id", userDetails?.user_id);
    services.setCookie("activedevice_id", userDetails?.activedevice_id);
  };
  const verifyOTPNumber = (otpInput) => {
    //let otpInput = this.state.otp;
    let optConfirm = window.confirmationResult;
    // console.log(codee);
    optConfirm
      .confirm(otpInput)
      .then(function (result) {
        // User signed in successfully.
        // console.log("Result" + result.verificationID);
        console.log("result",result.user);
        services.login(result.user, 'phone').then((res) => {
          if (res.success) {
            let userDetails = res?.data?.[0];
            setUserDetailsTOLocal(userDetails)
            toast.info("Loggedin...!");
            setLoginPopup(true)
            // setTimeout(() => {
            //   services.getUserAddress()
            //     .then((response) => {
            //         console.log("response",response)
            //         if (response.data && response.data.shipping_address) {
            //           services.setCookie("shipping_address", JSON.stringify(response.data.shipping_address));
            //           services.setCookie("billing_address", JSON.stringify(response.data.billing_address));
            //           services.setCookie("billing_shipping_same", response.data.billing_shipping_same.toString());
            //             history.push({
            //                 pathname: "/payment",

            //             });

            //         } else {

            //           var shipping_address = {
            //             phone : userDetails?.phone
            //           }
            //             services.setCookie("shipping_address", JSON.stringify(shipping_address));

            //              history.push(
            //               {
            //                 pathname:'/shippinginfo',

            //             })

            //         }
            //         console.log("response", response)
            //     })
            //     .catch((error) => {
            //         console.log("response", error)

            //     })
            // }, 2000);
            // setTimeout(() => {

            //   services.checkoutCheck().then((res) => {
            //     console.log("res 45", res);

            //     if (res?.status == 202) {
            //       history.push({ pathname: '/login' })
            //     } else if (res?.status == 203) {
            //       history.push(
            //         {
            //           pathname: '/shippinginfo',

            //         })
            //     } else if (res?.status == 200) {
            //       history.push({
            //         pathname: "/payment",

            //       });
            //     }
            //     console.log("res", res);
            //   }, (error) => {

            //   })
            // }, 2000);
          } else {
            toast.error("Error Occured...!");
          }
        }).catch(e => {
          toast.error("Error Occured...!");
        });
      })
      .catch(function (error) {
        //console.log(error);
        toast.error("Incorrect OTP!");
      });
  };
  const responseFacebook = (response, login_type) => {
    FBData = response;
     //console.log("FBData",FBData)
    setFacebookDatas({ ...faceBookDatas, facebookId: FBData.id });

    setFacebookDatas({ ...faceBookDatas, facebookEmail: FBData.email });

    if (response.id != undefined && response.id != null && response.id != "")
      services
        .facebookLogin(
          response.id,
          response.email,
          response.first_name,
          response.last_name,
          login_type
        )
        .then((response) => {
          if (response.status == 200) {
            // localStorage.setItem("isLoggedIn", true);
            // localStorage.setItem("userName", response.data[0].first_name);
            // services.setCookie("userId", response.data[0].user_id, 30);
            setIsNewUser(true);
            //   setIsVerify(true);
            var data = {
              'user_id': response.data[0].user_id,
              'first_name': FBData.first_name,
              'last_name': FBData.last_name,
              'user_email': FBData.email,
              'login_type': login_type
            }
            setUserDetailsTOLocal(data)
            setLoginPopup(true)
          //  console.log("entered jhgjh")
            // setTimeout(() => {

            //   services.checkoutCheck().then((res) => {
            //     console.log("res 45", res);

            //     if (res?.status == 202) {
            //       history.push({ pathname: '/login' })
            //     } else if (res?.status == 203) {
            //       history.push(
            //         {
            //           pathname: '/shippinginfo',

            //         })
            //     } else if (res?.status == 200) {
            //       history.push({
            //         pathname: "/payment",

            //       });
            //     } else if (res?.status == 206) {
            //       toast.error("No items in your cart ");
            //     }
            //     console.log("res", res);
            //   }, (error) => {

            //   })
            // }, 2000);
            //   localStorage.setItem("userId", response.data[0].user_id);
            // setTimeout(() => {
            //      history.push({
            //          pathname: '/shippinginfo',
            //        })

            //     }, 2000);
            //   services
            //     .userSubscription(response.data[0].user_id)
            //     .then((response) => {
            //       service.setCookie("isLoggedIn", "true", 30);
            //       var user_sub = response.data;
            //       if (user_sub.length > 0) {
            //         service.setCookie("isLoggedIn", "true", 30);
            //         setotherValues({
            //           ...otherValues,
            //           msgSuccess: "You are successfully registered",
            //         });

            //         setFlag({ ...flag, isSuccessMsg: true });
            //         setTimeout(function () {
            //           setFlag({ ...flag, isSuccessMsg: false });
            //         }, 5000);
            //         history.push("/home");
            //         if (sessionStorage.getItem("tvActivateFlag") == "true") {
            //           history.push("/tv");
            //         } else {
            //           //window.location.href = "/home";
            //         }
            //       } else {
            //         service.setCookie("isLoggedIn", "true", 30);
            //         setotherValues({
            //           ...otherValues,
            //           msgSuccess: "You are successfully registered",
            //         });

            //         setFlag({ ...flag, isSuccessMsg: true });
            //         setTimeout(function () {
            //           setFlag({ ...flag, isSuccessMsg: false });
            //         }, 5000);
            //         history.push("/home");
            //         if (sessionStorage.getItem("tvActivateFlag") == "true") {
            //           history.push("/tv");
            //         } else {
            //           // window.location.href = "/home";
            //         }
            //       }
            //     });
            // } else if (response.status == 202) {
            //   setotherValues({
            //     ...otherValues,
            //     msgError: "There was an error during registration",
            //   });

            //   setFlag({ ...flag, isErrorMsg: true });

            //   setTimeout(function () {
            //     setFlag({ ...flag, isErrorMsg: false });
            //   }, 5000);
            //  } else if (response.status == 203) {
            //   setFlag({ ...flag, isFbAcive: false });

            //   if (FBData.email) {
            //     setValues({
            //       ...values,
            //       ["firstname"]: FBData.first_name,
            //       ["email"]: FBData.email,
            //     });
            //   } else {
            //     setValues({
            //       ...values,
            //       ["firstname"]: FBData.first_name,
            //     });
            //   }
            //  } else if (response.status == 201) {
            //   setotherValues({
            //     ...otherValues,
            //     userRegisterId: response.data[0].user_id,
            //   });

            //   setFlag({ ...flag, isRegister: false });
            //   setotherValues({
            //     ...otherValues,
            //     msgSuccess:
            //       "OTP sent to your Email Id (Note:  If you do not find the email in your inbox, please check your spam filter or bulk email folder)",
            //   });

            //   setFlag({ ...flag, isSuccessMsg: true });
            //   setTimeout(function () {
            //     setFlag({ ...flag, isSuccessMsg: false });
            //   }, 5000);
          } else if (response.status == 204) {
          //  console.log("response.data[0]", response)
            confirmAlert({
              closeOnEscape: false,
              closeOnClickOutside: false,
              message: "Do you want to link your " + login_type + " account?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => onFBLink(login_type),
                },
                {
                  label: "No",
                  onClick: () => onFBNoLink(login_type),
                },
              ],
            });
          }
        });
  };
  const onFBLink = (login_type) => {
    services
      .facebookLink(
        FBData.id,
        FBData.email,
        FBData.first_name,
        FBData.last_name,
        login_type
      )
      .then((response) => {
        localStorage.setItem("isLoggedIn", true);
        var data = {
          'user_id': response.data[0].user_id,
          'first_name': FBData.first_name,
          'last_name': FBData.last_name,
          'user_email': FBData.email,
          'login_type': login_type
        }
        setUserDetailsTOLocal(data)
        setLoginPopup(true)

        // setTimeout(() => {

        //   services.checkoutCheck().then((res) => {
        //     console.log("res 45", res);

        //     if (res?.status == 202) {
        //       history.push({ pathname: '/login' })
        //     } else if (res?.status == 203) {
        //       history.push(
        //         {
        //           pathname: '/shippinginfo',

        //         })
        //     } else if (res?.status == 200) {
        //       history.push({
        //         pathname: "/payment",

        //       });
        //     } else if (res?.status == 206) {
        //       toast.error("No items in your cart ");
        //     }
        //     console.log("res", res);
        //   }, (error) => {

        //   })
        // }, 2000);
        // localStorage.setItem("userId", response.data[0].user_id);
        //   setTimeout(() => {
        //        history.push({
        //            pathname: '/shippinginfo',
        //          })

        //       }, 2000);
        //services.userSubscription(response.data[0].user_id).then((response) => {
        //services.setCookie("isLoggedIn", "true", 30);
        //var user_sub = response.data;
        // if (user_sub.length > 0) {
        //   service.setCookie("isLoggedIn", "true", 30);
        //   setotherValues({
        //     ...otherValues,
        //     msgSuccess: "You are successfully registered",
        //   });

        //   setFlag({ ...flag, isSuccessMsg: true });
        //   setTimeout(function () {
        //     setFlag({ ...flag, isSuccessMsg: false });
        //   }, 5000);
        //   history.push("/home");
        //   if (sessionStorage.getItem("tvActivateFlag") == "true") {
        //     history.push("/tv");
        //   } else {
        //     window.location.href = "/home";
        //   }
        // } else {
        //   service.setCookie("isLoggedIn", "true", 30);
        //   setotherValues({
        //     ...otherValues,
        //     msgSuccess: "You are successfully registered",
        //   });

        //   setFlag({ ...flag, isSuccessMsg: true });
        //   setTimeout(function () {
        //     setFlag({ ...flag, isSuccessMsg: false });
        //   }, 5000);
        //   history.push("/home");
        //   if (sessionStorage.getItem("tvActivateFlag") == "true") {
        //     history.push("/tv");
        //   } else {
        //     window.location.href = "/home";
        //   }
        // }
        // });
      });
  };
  // firebase.auth().onAuthStateChanged(function(user) {
  //   if (user) {
  //       console.log("USER LOGGED IN")
  //   } else {
  //       // No user is signed in.
  //       console.log("USER NOT LOGGED IN")
  //   }
  // });
  // const  resetCpatcha =()=>{
  //   window.recaptchaVerifier.render().then(function(widgetId) {
  //     grecaptcha.reset(widgetId)
  // })
  // }
  ///////////////////////////////////////////////////////////////////////////////////////
  const onFBNoLink = (login_type) => {
    // setFlag({ ...flag, isEmailExistMsg: true });
    // setTimeout(function () {
    //   setFlag({ ...flag, isEmailExistMsg: false });
    // }, 5000);
    toast.error("Email already exist, Please login.. ");
    //     setTimeout(() => {

    //                  services.checkoutCheck().then((res)=>{
    //                   console.log("res 45",res);

    //                   if(res?.status == 202){
    //                     history.push({pathname:'/login'})
    //                   }else if(res?.status == 203){
    //                      history.push(
    //                           {
    //                             pathname:'/shippinginfo',

    //                         })
    //                   }else if(res?.status == 200){
    //                        history.push({
    //                             pathname: "/payment",

    //                         });
    //                   }
    // console.log("res",res);
    //                  },(error)=>{

    //                 })
    //               }, 2000);
  };
  const handleLogin = (response) => {
  // console.log("response", response);
  }
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/ml_IN/sdk.js#xfbml=1&version=v11.0";
    document.body.appendChild(script);

    script.onload = () => {
     // console.log("success")
      //        window.FB.init({
      //         appId: "362146425651415",
      //         cookie: true,
      //         xfbml: true,
      //         version: 'v3.3'
      //       });
      //        var finished_rendering = function() {
      //   console.log("finished rendering plugins");

      // }
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "362146425651415",
          cookie: true,
          xfbml: true,
          version: 'v3.3'
        });
        // window.FB.getLoginStatus(function (response) {
        //   if (response.status === 'connected') {
        //    console.log("success authResponse", response.authResponse.userID)  
        //        window.FB.login(function (response) {
        //   if (response.status === 'connected') {
        //     console.log("success connected", response);
        //   } else {
        //     console.log("fail", response);
        //   }
        // });
        //   }
        // });
      };
      //window.FB.Event.subscribe('xfbml.render', finished_rendering);
      //   window.FB.getLoginStatus(function(response) {
      //   if (response.status === 'connected') {
      //     console.log(response.authResponse.accessToken);
      //   }
      // });
      //  window.FB.login(function(response){
      //         console.log("success response",response)
      //   // handle the response 
      // });
    };
    const script_two = document.createElement("script");
    script_two.src = "https://apis.google.com/js/api:client.js";
    document.body.appendChild(script_two);

    script_two.onload = () => {
      var googleUser = {};

      var startApp = function () {
        window.gapi.load('auth2', function () {
          // Retrieve the singleton for the GoogleAuth library and set up the client.
          window.auth2 = window.gapi.auth2.init({
            client_id: '68474774674-5vfk0e4b4okk30vs5s80tisas5k129ed.apps.googleusercontent.com',
            cookiepolicy: 'single_host_origin',
            // Request scopes in addition to 'profile' and 'email'
            //scope: 'additional_scope'
          });
          attachSignin(document.getElementById('customBtn'));
        });
      };
      startApp();
      function attachSignin(element) {
       // console.log("element",element);
        window.auth2.attachClickHandler(element, {},
          function (googleUser) {
          //  console.log("googleUser",googleUser.getBasicProfile().getId())
            var response = {
              'id': googleUser.getBasicProfile().getId(),
              'first_name': googleUser.getBasicProfile().getGivenName(),
              'last_name': googleUser.getBasicProfile().getFamilyName(),
              'email': googleUser.getBasicProfile().getEmail(),

            }
            responseFacebook(response, 'google')
          }, function (error) {
            //alert(JSON.stringify(error, undefined, 2));
          });
      }

    };
  }, []);
  const login = () => {
   // console.log("enter")
    window.FB.login(function (response) {
   //   console.log("enter response", response)
      window.FB.api('/me?fields=id,email,first_name,last_name', function (response) {
     //   console.log("enter response enter response", response);
        responseFacebook(response, 'facebook')
      });
    }, { scope: 'email' });
  }
  const [isLoginOTP, setIsLogInOTP] = useState(false);
  const [otp, setOtp] = useState({});
  const [error, setError] = useState({});

  const [values, setValues] = useState({ email: services.getCookie("user_email"),login_to_device:true });

  const staticValidation = () => {
    const emailReg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobileReg = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    let errors = {};

    let fromValidation = true;
    if (!isLoginOTP) {
      if (values.email) {
        if (!emailReg.test(String(values.email).toLowerCase())) {
          errors.email = "Please enter valid email";
          fromValidation = false;
        }
      } else {
        errors.email = "Email Required";
        fromValidation = false;
      }
      if (!values.password) {
        errors.password = "Password Required";
        fromValidation = false;
      }
    } else {
      if (values.mobile) {
        // if (!mobileReg.test(values.mobile)) {
        //   fromValidation = false;
        //   errors.mobile = "Invalid Mobile Number";
        // }
      } else {
        errors.mobile = "Mobile Number Required";
        fromValidation = false;
      }
    }
    setError(errors);
    return fromValidation;
  };

  const handleFocus = (name) => {
    setError({
      ...error,
      [name]: "",
    });
  };
  const handleValues = (value, name, country) => {
    if (name == "mobile") {
      let c = String(value).slice(country.dialCode.length);
      value = c;
      handleFocus(name);
      setValues({
        ...values,
        [name]: value,
        ['mobileCode']: country.dialCode
      });
    } else {
      handleFocus(name);
      setValues({
        ...values,
        [name]: value,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (staticValidation()) {
      if (isLoginOTP) {
        if (!otp?.otpSend) {
          setUpRecaptcha();
          sentOTP(values.mobileCode, values.mobile);
        }
      } else {
        services
          .login(values)
          .then((res) => {
           // console.log("res login", res);

            // if (res.status === 200 && ((localStorage.getItem("checkout_status")=='200'  && res?.data?.[0]?.user_id == localStorage.getItem("cart_user_id")) || (localStorage.getItem("checkout_status")=='202'))) {
            if (res.status === 200) {
              let userDetails = res?.data?.[0];
              setUserDetailsTOLocal(userDetails);
              toast.info("Loggedin...!");
              localStorage.setItem("userId", res?.data?.[0]?.user_id)
              setLoginPopup(true)
              setTimeout(() => {
                // services.getUserAddress()
                // .then((response) => {
                //     console.log("response",response)
                //     if (response.data && response.data.shipping_address) {
                //       services.setCookie("shipping_address", JSON.stringify(response.data.shipping_address));
                //       services.setCookie("billing_address", JSON.stringify(response.data.billing_address));
                //       services.setCookie("billing_shipping_same", response.data.billing_shipping_same.toString());
                //         history.push({
                //             pathname: "/payment",

                //         });

                //     } else {
                //         var shipping_address = {
                //           user_email : userDetails?.user_email,
                //           firstname : userDetails?.first_name,
                //           lastname : userDetails?.last_name,
                //           phone:userDetails?.phone
                //         }
                //         services.setCookie("shipping_address", JSON.stringify(shipping_address));

                //          history.push(
                //           {
                //             pathname:'/shippinginfo',

                //         })

                //     }
                //     console.log("response", response)
                // })
                // .catch((error) => {
                //     console.log("response", error)

                // })
              
              }, 2000);
            } else if (res?.status === 201) {
              let userDetails = res?.data?.[0];
              localStorage.setItem("userId", userDetails.user_id);
              setVerify(true);
            } else if (res?.status == 202) {
              toast.error("Invalid Credentials!");
            } else if (res?.status == 203) {
              toast.error("Login limit exceed!");
            } else {
              toast.error("Something went wrong!");
            }
          })
          .catch((e) => {
            toast.error("Error Occured!");
          });
      }
    }
  };
  const [OTPNumber, setOTPNumber] = useState("");
  const [OTPNumberEmail, setOTPNumberEmail] = useState("");
  const closePopup = (value) => {
//  console.log("values.login_to_device",values.login_to_device)
    setLoginPopup(false);
     services.loginToDevice(values.login_to_device).then((response) => {
        if (response.success) {
           services.checkoutCheck().then((res) => {
              //    console.log("res 45", res);

                  if (res?.status == 202) {
                    history.push({ pathname: '/login' })
                  } else if (res?.status == 203) {
                    history.push(
                      {
                        pathname: '/shippinginfo',

                      })
                  } else if (res?.status == 200) {
                    history.push({
                      pathname: "/payment",

                    });
                  } else if (res?.status == 206) {
                    toast.error("No items in your cart ");
                  }
                //  console.log("res", res);
                }, (error) => {

                })
        } else {
          toast.error("Something went wrong");
        }
      });
    //setBat(false);

    // history.push({
    //   pathname: "/shippinginfo",
    // });
  };

  const otpValidation = () => {
    let formValid = true;
    let errors = {};
    if (OTPNumber === "") {
      errors.otp = "OTP Required";
      formValid = false;
    }
    setError(errors);
    return formValid;
  };
  const otpValidationEmail = () => {
    let formValid = true;
    let errors = {};
    if (OTPNumberEmail === "") {
      errors.otpEmail = "OTP Required";
      formValid = false;
    }
    setError(errors);
    return formValid;
  };

  const handleOTPVerification = () => {
    if (otpValidation) {
      //console.log(OTPNumber);
      verifyOTPNumber(OTPNumber);
    }
  };
  const handleOTPVerificationEmail = () => {
    if (otpValidationEmail) {
      //console.log(OTPNumber);
      services.verifyEmail(OTPNumberEmail).then((response) => {
        if (response.success) {
          let userDetails = response?.data?.[0];
          setUserDetailsTOLocal(userDetails);
          toast.info("Loggedin...!");
          setTimeout(() => {
            history.push({
              pathname: "/shippinginfo",
            });
          }, 2000);
        } else {
          toast.error("Invalid Credentials!");
        }
      });
    }
  };

  return (
    <>
      {/*  {!isLoggedIn  || isLoggedIn == null ?( */}
      <div className="Login">
        {/* <div id="recaptcha-container"> */}
        <div className="LogCard" >
          <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '5%' }}>  <img src={logo} alt="Girl in a jacket" style={{ height: "50", display: 'block' }} />
          </div>
          <div className="section">
            {!isLoginOTP && !isVerifyEmail && (
              <>
                <div className="inputItem">
                  <label >Email</label>
                  <input
                    className="Input"
                    placeholder="Your Email"
                    defaultValue={values.email}
                    onChange={({ target }) => {
                      handleValues(target.value, "email");
                    }}
                  />
                  {error?.email && (
                    <div
                      className="errorClass"
                      style={{ marginTop: "-1.5rem" }}
                    >
                      {error?.email}
                    </div>
                  )}
                </div>
                <div className="inputItem">
                  <label>Password</label>
                  <input
                    className="Input"
                    placeholder="Password"
                    type={passwordShown1 ? "text" : "password"}
                    onChange={({ target }) => {
                      handleValues(target.value, "password");
                    }}
                  />
                  <i style={{ marginLeft: "90%", marginTop: "-7vh" }}
                    className="eyeIcon"
                    onClick={togglePasswordVisiblity1}
                  >
                    {eye1}
                  </i>
                  {error?.password && (
                    <div
                      className="errorClass"
                      style={{ marginTop: "-1.5rem" }}
                    >
                      {error?.password}
                    </div>
                  )}
                </div>
                <div className="newUser">
                  New User ?{" "}
                  <Link style={{ textDecoration: "none" }} to="/register">
                    Register here!
                  </Link>
                </div>
                <div style={{ marginBottom: "2rem" }}>
                  <button className="loginButton"
                    onClick={handleSubmit}
                  >

                    <label style={{ fontSize: 'smaller' }}> Log in</label>
                  </button>
                </div>
                <div className="orContainer orMargin">
                  <div className="orDivider"></div>
                  <div className="orCircle">
                    <div className="orText">OR</div>
                  </div>
                  <div className="orDivider"></div>
                </div>
                <div style={{ marginBottom: "2rem" }}>
                  <button
                    className="loginButton"
                    onClick={() => {
                      setIsLogInOTP(true);
                    }}
                  >

                    <label style={{ fontSize: 'smaller' }}> Log in with OTP</label>
                  </button>
                </div>
                <button className="loginButton" style={{ margin: "1.5rem 0rem" }} onClick={login}>
                  <FacebookIcon style={{ marginRight: 'auto', marginLeft: 'auto', width: '1.5em', height: '1.5em' }} /><label style={{ fontSize: 'smaller' }}> Continue With Facebook</label>
                </button>
                <div id="gSignInWrapper">
                  <div id="customBtn" class="customGPlusSignIn">
                    <button className="loginButton" style={{ margin: "1.5rem 0rem", backgroundColor: 'white', color: 'black', boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px' }} >
                      <FcGoogle style={{ marginRight: 'auto', marginLeft: 'auto', width: '1.5em', height: '1.5em' }} /><label style={{ fontSize: 'smaller' }}> Continue With Google</label>
                    </button>
                  </div>
                </div>

              </>
            )}
            {isVerifyEmail && (
              <>
                <div className="inputItem">
                  <label>OTP sent to your Email</label>

                  <div style={{ marginBottom: "1.3rem" }}>
                    (Note: If you do not find the email in your inbox, please
                    check your spam filter or bulk email folder)
                  </div>

                  <input
                    id="myInput"
                    value={OTPNumberEmail}
                    className="Input"
                    style={{ letterSpacing: "3px" }}
                    onChange={({ target }) => {
                      setOTPNumberEmail(target.value.trim());
                    }}
                    placeholder="OTP"
                  />
                </div>
                <div style={{ marginBottom: "2rem" }}>
                  <button
                    name="otpEmail"
                    className="loginButton"
                    onClick={() => {
                      handleOTPVerificationEmail();
                    }}
                  >
                    Verify
                  </button>
                </div>

              </>
            )}
            {isLoginOTP && !isVerifyEmail && !otp.otpSend && (
              <>
                <div id="recaptcha-container"></div>
                <div className="inputItem">
                  <label>Mobile Number</label>

                  <PhoneInput
                    country={localStorage
                      .getItem("C_Code")
                      ?.toLocaleLowerCase()}
                    autoFormat
                    className="loginButton"
                    style={{
                      height: "4rem",
                      minWidth: "90%",
                      marginTop: "1rem",
                    }}
                    onChange={(value, country) => {
                      handleValues(value, "mobile", country);
                    }}
                  />
                  {error?.mobile && (
                    <div
                      style={{
                        marginTop: "1.5rem",
                        color: "red",
                        fontSize: "1rem",
                      }}
                    >
                      {error?.mobile}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    fontSize: "1.3rem",
                    textAlign: "end",
                    marginTop: "0.5rem",
                  }}
                >
                  Signin with Password ?{" "}
                  <span
                    onClick={() => {
                      setIsLogInOTP(false);
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    Click here!
                  </span>
                </div>
                <div style={{ marginBottom: "2rem", marginTop: "2.5rem" }}>
                  <button name="otp" className="loginButton"
                    onClick={handleSubmit}
                  >
                    Send OTP
                  </button>
                </div>
              </>
            )}
            {otp && otp?.otpSend && (
              <>
                <div className="inputItem">
                  <label>OTP</label>
                  <input
                    id="myInput"
                    value={OTPNumber}
                    className="Input"
                    style={{ letterSpacing: "3px" }}
                    onChange={({ target }) => {
                      setOTPNumber(target.value.trim());
                    }}
                    placeholder="OTP"
                  />
                </div>
                <div style={{ marginBottom: "2rem" }}>
                  <button
                    name="otp"
                    className="loginButton"
                    onClick={handleOTPVerification}
                  >
                    Verify
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        {/* </div> */}
      </div>

      {/* ): (
        <Redirect to="/shippinginfo" />
      )} */}
      {loginPopup ? (
            <div className="popup">
              <div className="popup_inner">
              <Card style={{border: '6px solid #e5e5e5'}}>
                <CardBody>
                <h3
                  style={{
                    display: "flex",
                    //marginLeft: "10px",
                    marginBottom: "5%",
                    marginTop: "3%",
                    fontSize: "large",
                    fontWeight: "1000"
                  }}
                >
                  {" "}
                  With Flixbuy you can sync with your TV device and make purchase from TV!!!{" "}
                </h3>{" "}
                     <div
                  className="row"
                  style={{
                    // marginLeft: "50%",
                    marginBottom: "1%",
                  }}
                >
                  <Col xs={10}>
                  <Label>Login to my TV device(I have scanned QR code from my private TV)</Label>
          </Col>
          <Col xs={2}>
                    <input
                        type="checkbox"
                        style={{
                          width: "18px",
                          height: "40px",
                        }}
                        checked={values.login_to_device}
                        onChange={({ target }) => {
                          handleValues(target.checked, "login_to_device");
                        }}
                      />
          </Col>
                </div>{" "}
                 <div
                  style={{
                    marginBottom: "5%",
                    marginTop: "3%",
                    fontSize: "large",
                    fontWeight: "1000"
                  }}
                >
                  {" "}
For using this service you need to agree our <a href="https://watch.boondocknation.com/termsandconditions">terms and conditions</a></div>{"  "}

                <div
                  className="row"
                  style={{
                    // marginLeft: "50%",
                    marginBottom: "1%",
                  }}
                >
                  <button
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "fit-content",
                      height: "25px",
                      fontSize: "small",
                      backgroundColor: "#316F9C",
                      borderRadius: "5px",
                      border: "none",
                      color: "#FFFFFF",
                    }}
                    onClick={() => {
                      closePopup(true);
                    }}
                  >
                    {" "}
Agree & Proceed     </button>{" "}
               
                </div>{" "}
                 </CardBody>
                    </Card>
              </div>{" "}
            </div>
          ) : null}{" "}
      <ToastContainer
        style={{ fontSize: "2rem" }}
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Login;
