import React, {useEffect} from 'react'
import { services } from "./service";
import { toast } from "react-toastify";

function StripePaymentSuccess({ history }) {
   const params = new URLSearchParams(window.location.search);
   let session_id = params.get('session_id');

    useEffect(() => {
      var billing_address = services.getCookie("billing_address");
      var checkoutDetails=services.getCookie("checkoutDetails");
      checkoutDetails = JSON.parse(checkoutDetails);
        if (billing_address) {
          billing_address = JSON.parse(billing_address);
          billing_address.province = billing_address.state;
          billing_address.firstName = billing_address.firstname;
          billing_address.lastName = billing_address.lastname;
          billing_address.phone = "+" + billing_address.phone;

          delete billing_address["firstname"];
          delete billing_address["lastname"];
          delete billing_address["state"];
          delete billing_address["user_email"];
        }
        let data = {
          cart_id: localStorage.getItem("CID"),
          billing_address: billing_address,
          amount: checkoutDetails.TotalPrice,
          currencyCode: checkoutDetails.currencyCode,
          save_card:true,
          session_id:session_id,
          payment_method: 'stripe'
        };
        console.log("data",data)
        services.payment(data).then(
          (response) => {
            if (response?.status == 200) {
                 if(response?.data?.success == false){
                    var message = response.data.message;
                    toast.error(message);
                  }else{
                    history.push({
                      pathname: "/payment/success",
                    });
                  }
            } else {
              toast.error(`Something Went Wrong`, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          },
          (error) => {
              toast.error(`Something Went Wrong`, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
          }
        );
      }, [])
  return (
    <div className='text-success'>Please wait...</div>
  )
}

export default StripePaymentSuccess