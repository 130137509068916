const axios = require("axios");
const headers = {
    pubid: localStorage.getItem("pubid"),
    country_code: localStorage.getItem("C_Code"),
    dev_id: localStorage.getItem("deviceId"),
    ip: localStorage.getItem("IP"),
    device_type: "web",
    uid: localStorage.getItem("userId"),
    channelid: localStorage.getItem("channelid"),
    platform: 'ecommerce'


};



function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}



const getCardList = (pub_id) => {
    headers.uid = localStorage.getItem('userId')
    headers.tid = localStorage.getItem("trans_id")
    headers.pubid = localStorage.getItem("pubid");
    headers.channelid = localStorage.getItem("channelid");
    headers["access-token"] = localStorage.getItem("access-token");
    let url = process.env.REACT_APP_API_URL + "/ecommerce/user/cards"
    return axios.get(url, {
        headers
    }).then(response => {
        return response.data
    }).catch(e => {
        return {
            data: false
        }
    })
}
function checkoutCheck() {
    // headers.uid = localStorage.getItem("cart_user_id")
    headers.tid = localStorage.getItem("trans_id")
    headers.uid = localStorage.getItem("userId")
    headers.pubid = localStorage.getItem("pubid");
    headers.channelid = localStorage.getItem("channelid");
    headers["access-token"] = localStorage.getItem("access-token");

    //    headers.cart_id = localStorage.getItem("CID")
    var data = {
        cart_id: localStorage.getItem("CID"),
        apple_pay_token: localStorage.getItem("apple-pay-token"),
        cart_user_id:localStorage.getItem("cart_user_id")

    }
    let url =`${process.env.REACT_APP_API_URL}/ecommerce/checkout`;
    return axios
        .post(url, data, { headers: headers })
        .then((response) => {
            return response;
        })
        .catch((error) => {
        });
};
function payment(data) {
    headers.tid = localStorage.getItem("trans_id")
    headers.uid = localStorage.getItem("userId");
    headers.pubid = localStorage.getItem("pubid");
    headers.channelid = localStorage.getItem("channelid");
    headers["access-token"] = localStorage.getItem("access-token");

    //    headers.cart_id = localStorage.getItem("CID")
    // var data ={
    //     cart_id : localStorage.getItem("CID")
    // }

    let url =`${process.env.REACT_APP_API_URL}/ecommerce/checkout`
    return axios
        .post(url, data, { headers: headers })
        .then((response) => {
            return response;
        })
        .catch((error) => {
        });

    // return axios.post(process.env.REACT_APP_API_URL +"/ecommerce/payment", data, {
    //         headers
    //     })
    //     .then((response) => {
    //         return response;
    //     })
    //     .catch((error, response) => {
    //         return error.response;
    //     });

}
// function orderCreate(checkout_id,billing_address) {
//          headers.tid = localStorage.getItem("trans_id")

//     var requestBody = {
//         cart_id: checkout_id,
//         billing_address: billing_address
//     }
//     return axios.post("http://api.gizmott.com/test/api/ecommerce/order/create", requestBody, {
//             headers
//         })
//         .then((response) => {
//             return response;
//         })
//         .catch((error, response) => {
//             return error.response;
//         });

// }
function applyDiscount(code) {
    headers.tid = localStorage.getItem("trans_id")
    headers["access-token"] = localStorage.getItem("access-token");

    var requestBody = {
        cart_id: localStorage.getItem("CID"),
        discount_code: code
    }
    return axios.post(process.env.REACT_APP_API_URL + "/ecommerce/cart/discount", requestBody, {
        headers
    })
        .then((response) => {
            return response;
        })
        .catch((error, response) => {
            return error.response;
        });

}

function cardDetailsSave(data) {
    headers.tid = localStorage.getItem("trans_id")
    headers["access-token"] = localStorage.getItem("access-token");

    return axios.post(process.env.REACT_APP_API_URL + "/ecommerce/user/card", data, {
        headers
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });

}
const getOrderStatus = () => {
    headers.tid = localStorage.getItem("trans_id")
    headers.pubid = localStorage.getItem("pubid");
    headers.channelid = localStorage.getItem("channelid");
    headers.uid = localStorage.getItem('userId')
    headers["access-token"] = localStorage.getItem("access-token");

    return axios
        .get(
            process.env.REACT_APP_API_URL + "/ecommerce/order/received?cart_id=" + localStorage.getItem("CID"), {
            headers
        }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
}
const getAddress = () => {
    headers.tid = localStorage.getItem("trans_id")
    headers["access-token"] = localStorage.getItem("access-token");

    return axios
        .get(
            process.env.REACT_APP_API_URL + "/ecommerce/user/address", {
            headers
        }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return [];
        });
}
const authenticate = () => {
    headers.uid = localStorage.getItem('userId')
    headers.tid = localStorage.getItem("trans_id")
    headers.pubid = localStorage.getItem("pubid");
    headers.channelid = localStorage.getItem("channelid");

    return axios
        .get(
            process.env.REACT_APP_API_URL + "/account/authenticate", {
            headers
        }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
}
const spreedlyKey = () => {
    headers.uid = localStorage.getItem('userId')
    headers.tid = localStorage.getItem("trans_id")
    headers.pubid = localStorage.getItem("pubid");
    headers.channelid = localStorage.getItem("channelid");
    headers["access-token"] = localStorage.getItem("access-token");

    return axios
        .get(
            process.env.REACT_APP_API_URL + "/ecommerce/spreedly/keys", {
            headers
        }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
}
function getUserAddress() {
    headers.uid = localStorage.getItem('userId')
    headers.tid = localStorage.getItem("trans_id")
    headers["access-token"] = localStorage.getItem("access-token");

    return axios
        .get(process.env.REACT_APP_API_URL + "/ecommerce/user/address", { headers: headers })
        .then((response) => {

            return response.data;
        })
        .catch((error) => {
        });
}

function validateTheSession(url, callback) {

    var requestBody = {
        url: url
    }
    //sending validation url  to backend
    axios
        .post(
            "https://staging.poppo.tv/test/api/ecommerce/applepay/validateSession", { url }
        )
        .then(function (response) {
            callback(response.data);
        });
};

function pay(applePaymentToken, callback) {

    var data = {
        token: applePaymentToken
    }
    axios
        .post(
            "https://staging.poppo.tv/test/api/ecommerce/applepay/pay",
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }
        )
        .the(function (response) {
            callback(response.data);
        });
};

function stripeSessions(data) {
    headers.tid = localStorage.getItem("trans_id")
    headers.uid = localStorage.getItem("userId");
    headers.pubid = localStorage.getItem("pubid");
    headers.channelid = localStorage.getItem("channelid");
    let cart_id= localStorage.getItem("CID");
    return axios
        .get(`${process.env.REACT_APP_API_URL}/ecommerce/stripe/session?amount=${data.amount}&cart_id=${cart_id}`,{ headers: headers })
        .then((response) => {
            return response;
        })
        .catch((error) => {
        });
}

function createPaymentIntent(amount,currency_code){
        headers.tid = localStorage.getItem("trans_id")
        headers.uid = localStorage.getItem("userId")
        headers.pubid = localStorage.getItem("pubid");
        headers.channelid = localStorage.getItem("channelid");
        headers["access-token"] = localStorage.getItem("access-token");
        let cart_id= localStorage.getItem("CID");
        let data ={};
        let query =`?amount=${amount}&currency_code=${currency_code}&cart_id=${cart_id}`;
        let url=`${process.env.REACT_APP_API_URL}/ecommerce/createStripePaymentIntent${query}`
        return axios
            .post(url, data, { headers: headers })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
            });
}

export const services = {
    setCookie,
    getCookie,
    getCardList,
    payment,
    cardDetailsSave,
    getAddress,
    getUserAddress,
    // orderCreate,
    getOrderStatus,
    applyDiscount,
    checkoutCheck,
    validateTheSession,
    pay,
    authenticate,
    spreedlyKey,
    stripeSessions,
    createPaymentIntent
};
