import React, { useEffect, useState } from "react";
import axios from "axios";
import {services} from '../Service/service' 

import { useShopify } from "../hooks";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
const fpPromise = FingerprintJS.load();







function RedirectPage() {
  var url_string = window.location.href;
  var url = new URL(url_string);
  var productId = url.searchParams.get("p");
  var eventType = url.searchParams.get("e");
  var cartid = url.searchParams.get("c");

  

  const [deviceId_status, setDIDStatus] = useState("");
  const [product_id, set_product_id] = useState("");
  ///////////////////////////////////////////////////////
  const genarateDevideID = async () => {
    const fp = await fpPromise;
    const result = await fp.get();
    const visitorId = result.visitorId;
    localStorage.setItem("deviceId", visitorId);
    setDIDStatus(true);
  };

  ////////////////////////////////////////////////////////////
  const redirectCall = () => {
    console.log(cartid,typeof(cartid));
    if(cartid && cartid!==''){
    localStorage.setItem('CID',cartid);
    }
  services.redirectCall(productId, eventType)
  };
///////////////////////////////////////////////////////

useEffect(() => {


if(!localStorage.getItem('IP')){ services.getGeoInfo().then(res =>{
   if(res.status){
     localStorage.setItem('C_Code',res.countryCode);
     localStorage.setItem('IP',res.query);
   }
 })}
}, [])



///////////////////////////////////////////////////////
  useEffect(() => {
    var x = document.getElementById("redirect");
    x.innerHTML = `Redirecting...`;

    if (!localStorage.getItem("deviceId")) {
      genarateDevideID();
    }
    setTimeout(() => {
      localStorage.getItem("deviceId") &&
        redirectCall();
    }, 100);
  }, [productId]);

  useEffect(() => {
    deviceId_status != "" &&
      localStorage.getItem("deviceId") &&
      redirectCall();
  }, [deviceId_status]);

  return <div id="redirect" style={{ fontSize: "2rem" }}></div>;
}

export default RedirectPage;
