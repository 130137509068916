const axios = require("axios");
const headers = {
    pubid: localStorage.getItem("pubid"),
    country_code: localStorage.getItem("C_Code"),
    dev_id: localStorage.getItem("deviceId"),
    ip: localStorage.getItem("IP"),
    device_type: "web",
    uid: localStorage.getItem("userId"),
    channelid: localStorage.getItem("channelid"),
    platform: 'ecommerce'

};



function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}


function getOrderDetails(id) {
    headers.tid = localStorage.getItem("trans_id")
    headers.uid = localStorage.getItem("userId")
    headers.pubid = localStorage.getItem("pubid")
    headers.channelid = localStorage.getItem("channelid")
    headers.country_code = localStorage.getItem("C_Code")
    headers.dev_id = localStorage.getItem("deviceId")
    return axios
        .get(`${process.env.REACT_APP_API_URL}/ecommerce/order/${id}`, { headers: headers })
        .then((response) => {

            return response.data;
        })
        .catch((error) => {
        });
}
function getOrderList() {
    headers.tid = localStorage.getItem("trans_id")

    return axios
        .get(`${process.env.REACT_APP_API_URL}/ecommerce/order/list`, { headers: headers })
        .then((response) => {

            return response.data;
        })
        .catch((error) => {
            return error.response.data
        });
}
export const services = {
    setCookie,
    getCookie,
    getOrderDetails,
    getOrderList
};
