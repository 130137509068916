import React, { useEffect, useState } from "react"
import LineItem from "./LineItem"
import { useShopify } from "../hooks"
import { checkoutFetch } from '../redux/ducks/shopify/index'
import { MdShoppingCart, MdRemoveShoppingCart } from "react-icons/md"
import {
	useHistory
} from "react-router-dom";
import {
	services
} from '../Service/service'

import Menudetails from "./menu.js";

import { ToastContainer, toast } from "react-toastify";
import {
  Col,
  Row,
  Button,
  CustomInput,
  FormGroup,
  Input,
  Card,
  CardBody,
  Label
} from "reactstrap";
export default (props) => {
	const {
		cartStatus,
		closeCart,
		openCart,
		checkoutState,
		setCount,
		fetchCollection
	} = useShopify()


	var cartid = props.match.params.cartId
	localStorage.setItem("CID", cartid)
	var url_string = window.location.href;

	var url = new URL(url_string);
	var tid = url.searchParams.get("tid");
	//console.log("url", url)

	//console.log("uid", tid)
	localStorage.setItem("trans_id", tid)
	const [itemsCount, setItemsCount] = useState(0)
	const [loading, setLoading] = useState(true);
	const [lineItems, setLineItems] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [loginPopup, setLoginPopup] = useState(false);
  const [values, setValues] = useState({['login_to_device']:true});

	let history = useHistory();
	function openCheckout(e) {
		e.preventDefault()
		//console.log("checkoutState", lineItems)
		var checkoutDetails = {};

		let outOfstockProducts=lineItems.lineItems.filter(item=>item.available==false);
		//console.log("outOfstockProducts",outOfstockProducts);
		if(outOfstockProducts.length>0){
			toast.error("Please remove out of stock products")
		//	console.log("ERRRRRR");
		}
		else{
		if (lineItems.length !== 0) {
			checkoutDetails = {
				currencyCode: lineItems.currencyCode,
				id: lineItems.id,
				quantity: lineItems.lineItems[0].quantity,
				title: lineItems.lineItems[0].title,
				price: lineItems.lineItems[0].price,
				subtotalPrice: lineItems.subtotalPrice,
				totalTax: lineItems.totalTax,
				totalPrice: lineItems.totalPrice
			}
			services.setCookie("checkoutDetails", JSON.stringify(checkoutDetails));
		//	console.log("checkOutDetails", checkoutDetails)
			// if (localStorage.getItem('userId') && localStorage.getItem('userId') !== undefined && localStorage.getItem('isLoggedIn')) {
			// 			  console.log("checkOutDetails address")

			// 	  services.getUserAddress()
			// 		.then((response) => {
			// 			console.log("response",response)
			// 			if (response.data && response.data.shipping_address) {
			// 				history.push({
			// 					pathname: "/payment",

			// 				});

			// 			} else {
			// 				 history.push('/shippinginfo')

			// 			}
			// 			console.log("response", response)
			// 		})
			// 		.catch((error) => {
			// 			console.log("response", error)

			// 		})
			// 			  console.log("checkOutDetails hiii")
			// } else {
			// 	history.push({pathname:'/login',

			// })
			// }
			// if (localStorage.getItem("cart_user_id") == localStorage.getItem("userId")) {
			// 	console.log("already login")
			if(values.is_guest && localStorage.getItem("userId")){
          setLoginPopup(true);
      }else{

				services.checkoutCheck().then((response) => {
					if (response?.status == 202) {
						localStorage.setItem("checkout_status", 202)
						history.push({ pathname: '/login' })
					} else if (response?.status == 203) {
						localStorage.setItem("cart_user_id", localStorage.getItem("userId"));

						history.push({ pathname: '/shippinginfo' })
					} else if (response?.status == 200) {
						 localStorage.setItem("cart_user_id", localStorage.getItem("userId"));

						history.push({ pathname: '/payment' })
					}
					else if(response?.status == 209){
						toast.error("Some of the items in your cart became out of stock.")
					}
					else if(response?.status == 210){
						toast.error("Inventory for some of the items in your cart has changed.")
					}
				})
					.catch((error) => {
					//	console.log("response", error)

					})
			}
			// } else if ((localStorage.getItem("cart_user_id") != localStorage.getItem("userId")) && !localStorage.getItem("guest_user_id")) {
			// 	console.log("cart_user_id")


			// 	history.push({ pathname: '/login' })
			// } else if (localStorage.getItem("guest_user_id")) {
			// 	console.log("guest_user_id")

			// 	history.push({ pathname: '/login' })
			// }



			// console.log("response",response)
			//   	  if(response?.status == 202){
			//   	  	 localStorage.setItem("checkout_status",202)
			//               history.push({pathname:'/login',

			//            })
			//   }
			//   else if(response?.status == 200){
			//      localStorage.setItem("checkout_status",200)
			//     var cart_user_id =  localStorage.getItem("cart_user_id")
			//     var user_id = localStorage.getItem("userId")
			//     if(cart_user_id != user_id){
			//     	    services.setCookie("user_email", response.data.email);
			//                  history.push({pathname:'/login',

			//             })
			//     }else{
			//     	services.getUserAddress()
			//                   .then((response) => {
			//                  console.log("response",response)
			//                  if (response.data && response.data.shipping_address) {
			//                    services.setCookie("shipping_address", JSON.stringify(response.data.shipping_address));
			//                    services.setCookie("billing_address", JSON.stringify(response.data.billing_address));
			//                    services.setCookie("billing_shipping_same", response.data.billing_shipping_same.toString());
			//                      history.push({
			//                          pathname: "/payment",

			//                      });

			//                  } else {

			//                    var shipping_address = {
			//                      //phone : userDetails?.phone
			//                    }
			//                      services.setCookie("shipping_address", JSON.stringify(shipping_address));

			//                       history.push(
			//                        {
			//                          pathname:'/shippinginfo',

			//                      })

			//                  }
			//            })
			//            .catch((error) => {
			//                console.log("response", error)

			//            })
			//   } 
			// }




		}
	}
		//http://localhost:3000/
		// window.open(checkoutState.webUrl) // opens checkout in a new window
		//window.location.replace(checkoutState.webUrl) // opens checkout in same window
	}
	  const handleValues = (value, name, country) => {
  
      setValues({
        ...values,
        [name]: value,
      });
    
  };
const closePopup = (value) => {
 // console.log("values.login_to_device",values.login_to_device)
    setLoginPopup(false);
     services.loginToDevice(values.login_to_device).then((response) => {
        if (response.success) {
           // services.checkoutCheck().then((res) => {
           //        console.log("res 45", res);

           //        if (res?.status == 202) {
           //          history.push({ pathname: '/login' })
           //        } else if (res?.status == 203) {
           //          history.push(
           //            {
           //              pathname: '/shippinginfo',

           //            })
           //        } else if (res?.status == 200) {
           //          history.push({
           //            pathname: "/payment",

           //          });
           //        } else if (res?.status == 206) {
           //          toast.error("No items in your cart ");
           //        }
           //        console.log("res", res);
           //      }, (error) => {

           //      })
         	services.checkoutCheck().then((response) => {
					if (response?.status == 202) {
						localStorage.setItem("checkout_status", 202)
						history.push({ pathname: '/login' })
					} else if (response?.status == 203) {
						localStorage.setItem("cart_user_id", localStorage.getItem("userId"));

						history.push({ pathname: '/shippinginfo' })
					} else if (response?.status == 200) {
						 localStorage.setItem("cart_user_id", localStorage.getItem("userId"));

						history.push({ pathname: '/payment' })
					}
					else if(response?.status == 209){
						toast.error("Some of the items in your cart became out of stock.")
					}
					else if(response?.status == 210){
						toast.error("Inventory for some of the items in your cart has changed.")
					}
				})
					.catch((error) => {
					//	console.log("response", error)

					})
        } else {
          toast.error("Something went wrong");
        }
      });
    //setBat(false);

    // history.push({
    //   pathname: "/shippinginfo",
    // });
  };
	useEffect(() => {


		// function getCount() {
		// 	let lineItems =
		// 		checkoutState.lineItems && checkoutState.lineItems.length > 0
		// 			? checkoutState.lineItems
		// 			: []
		// 	let count = 0
		// 	lineItems.forEach((item) => {
		// 		count += item.quantity
		// 		return count
		// 	})

		// 	setCount(count)
		//           setItemsCount(count)
		// }

		// getCount()

		//console.log("checkoutState change change")
	}, [cartStatus, checkoutState])
	function decrementQuantity(index, lineItemId, lineItemQuantity, product_id, variant_id, e) {
	//	console.log("index, lineItemId, lineItemQuantity, product_id, variant_id, e",index, lineItemId, lineItemQuantity, product_id, variant_id, e);
		services.updateProductCount(product_id, variant_id, lineItemQuantity - 1).then((res) => {
			

			//console.log("res", res)
			if(res?.status == 209){
				toast.error("Product updated is out of stock")
			}
			if(res?.status == 210){
				toast.error("Product quantity updated is not available.")
			}
			else{
			if(res.data.data.lineItems.length == 0){
				setLineItems(res.data.data);

				setItemsCount(0)
			  setLoading(false);
			  setIsLoading(false)
      }else{
      	setLineItems(res.data.data);
      }
				//setLineItems(res.data.data);
			}
			
		},
			(error) => {
				//console.log("error", error)

			})


	}

	function incrementQuantity(index, lineItemId, lineItemQuantity, product_id, variant_id, e,quantityAvailable) {
		//console.log("index, lineItemId, lineItemQuantity, product_id, variant_id, e",index, lineItemId, lineItemQuantity, product_id, variant_id, e,quantityAvailable);
		if(lineItemQuantity<7 &&  lineItemQuantity <=quantityAvailable ){
		services.updateProductCount(product_id, variant_id, lineItemQuantity + 1).then((res) => {
			
			if(res?.status==209){
				toast.error("Product updated is out of stock")
			}
			if(res?.status == 210){
				toast.error("Product quantity updated is not available.")
			}
			else{
				setLineItems(res.data.data);
			}
		//	console.log("res", res)
		},
			(error) => {
			//	console.log("error", error)

			})
		}
	}

	function deleteLineItem(index, lineItemId, product_id, variant_id, e) {

		services.removeProduct(product_id, variant_id).then((res) => {
			//console.log("res", res)

			
			if(res.data.data.lineItems.length == 0){
			//	setLineItems(res.data.data);

				setItemsCount(0)
			  setLoading(false);
			  setIsLoading(false)
      }else{
      	setLineItems(res.data.data);
      }

		},
			(error) => {
			//	console.log("error", error)

			})

	}

	useEffect(() => {


		services.fetchCart(cartid,tid).then(res => {
	 if(res.success == true){
           setValues({
          ...values,
          ['is_guest']:res?.data?.is_guest
         })
			if (res?.data?.lineItems) {
				setLineItems(res.data)

				let count = 0
				res.data.lineItems.forEach((item) => {
					count += item.quantity
					return count
				})
				setCount(count)
				setItemsCount(count)
				setTimeout(() => {
					setLoading(false)
				   setIsLoading(false)

				}, 1000);
			}
		}else{
			if(res.success == false && res.message == 'No items found'){
			  setLoading(false);
			  setIsLoading(false)
      }else{
      setLoading(false)
			toast.error("Something went wrong")
      }
		}
		}).catch(e => {
			toast.error("Something went wrong")

			setLoading(false)
		//	console.log(e);
		})

	}, [])
	return (
		<div >
			<div className="logo_style_thumb">
				<Menudetails
					logo={true}

				/>
			</div>
			<div className="Your_cart">
				<div>

				</div>
				<header className="Cart__header">
					<h2>Your cart</h2>

				</header>
				{
					loading != true ? itemsCount != 0 ? <>
						<ul className="Cart__line-items">
							<LineItem
								lineItems={lineItems}
								decrementQuantity={decrementQuantity}
								incrementQuantity={incrementQuantity}
								deleteLineItem={deleteLineItem}
							/>
						</ul>
						<footer className="Cart__footer">
							<div className="Cart-info clearfix">
								<div className="Cart-info__total Cart-info__small">Subtotal</div>
								<div className="Cart-info__pricing">
									<span className="pricing">{lineItems.lineItemsSubtotalPrice}</span>
								</div>
							</div>
							<div className="Cart-info clearfix">
								<div className="Cart-info__total Cart-info__small">Shipping</div>
								<div className="Cart-info__pricing">
									<span className="pricing">{lineItems.totalShippingPrice}</span>
								</div>
							</div>
							<div className="Cart-info clearfix">
								<div className="Cart-info__total Cart-info__small">Taxes</div>
								<div className="Cart-info__pricing">
									<span className="pricing">{lineItems.totalTax}</span>
								</div>
							</div>
							<div className="Cart-info clearfix">
								<div className="Cart-info__total Cart-info__small">Total</div>
								<div className="Cart-info__pricing">
									<span className="pricing">{lineItems.totalPrice}</span>
								</div>
							</div>
							<button

								className="Cart__checkout button"
								onClick={(e) => openCheckout(e)}
							>
								Checkout
							</button>
						</footer>
					</> :
						<div>
						<label style={{fontSize:'medium',marginLeft: '5%'}}>	Your cart is empty</label>
						</div> :
						<div>
							Loading...
						</div>

				}
			</div>
			    {loginPopup ? (
            <div className="popup">
              <div className="popup_inner">
              <Card style={{border: '6px solid #e5e5e5'}}>
                <CardBody>
                <h3
                  style={{
                    display: "flex",
                    //marginLeft: "10px",
                    marginBottom: "5%",
                    marginTop: "3%",
                    fontSize: "large",
                    fontWeight: "1000"
                  }}
                >
                  {" "}
                  With Flixbuy you can sync with your TV device and make purchase from TV!!!{" "}
                </h3>{" "}
                     <div
                  className="row"
                  style={{
                    // marginLeft: "50%",
                    marginBottom: "1%",
                  }}
                >
                  <Col xs={10}>
                  <Label>Login to my TV device(I have scanned QR code from my private TV)</Label>
          </Col>
          <Col xs={2}>
                    <input
                        type="checkbox"
                        style={{
                          width: "18px",
                          height: "40px",
                        }}
                        checked={values.login_to_device}
                        onChange={({ target }) => {
                          handleValues(target.checked, "login_to_device");
                        }}
                      />
          </Col>
                </div>{" "}
                 <div
                  style={{
                    marginBottom: "5%",
                    marginTop: "3%",
                    fontSize: "large",
                    fontWeight: "1000"
                  }}
                >
                  {" "}
For using this service you need to agree our <a href="https://watch.boondocknation.com/termsandconditions">terms and conditions</a></div>{"  "}

                <div
                  className="row"
                  style={{
                    // marginLeft: "50%",
                    marginBottom: "1%",
                  }}
                >
                  <button
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "fit-content",
                      height: "25px",
                      fontSize: "small",
                      backgroundColor: "#316F9C",
                      borderRadius: "5px",
                      border: "none",
                      color: "#FFFFFF",
                    }}
                    onClick={() => {
                      closePopup(true);
                    }}
                  >
                    {" "}
Agree & Proceed     </button>{" "}
               
                </div>{" "}
                 </CardBody>
                    </Card>
              </div>{" "}
            </div>
          ) : null}{" "}
			<ToastContainer
        style={{
          fontSize: "2rem",
        }}
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
			{isLoading ? (
          <div id="app" class="loader" style={{ position: 'fixed',top: '50%',left: '50%',marginTop: '-50px',marginLeft: '-50px'}}></div>
        ) : null}
		</div>
	)
}
