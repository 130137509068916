import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import DehazeIcon from '@material-ui/icons/Dehaze';
import IconButton from '@material-ui/core/IconButton';
// import logo from 'flix-logo.png';
import  logo  from "./flix-logo.png";
 import "./style.css";
import {
  Col,
  Row,
  Button,
  CustomInput,
  FormGroup,
  Input,
  
} from "reactstrap";
const Menudetails = (props) => {

  
  return (
    <>
   {props.logo ?null:<Row className="hearder_display" >
   <Col lg={10}>
   <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={props.handleClick}
      >
        <DehazeIcon style ={{ fill: 'white',width: '2em', height: '3em' }} />
      </IconButton> 

   <Menu
        keepMounted
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        open={Boolean(props.anchorEl)}
      >
        <MenuItem onClick={() => {
                        props.Orders()
                      }}>My Orders</MenuItem>
        
      </Menu>
        </Col>
   <Col>

  <img className="logo" src={logo} alt="Girl in a jacket" style={{height:"50" ,display: 'block',width:'50%'}}/>
   </Col>

    </Row>}
    {props.logo ?null:<Row className="hearder_display_mobile">
   <Col>
   <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={props.handleClick}
      >
        <DehazeIcon style ={{ fill: props.color?'#0d6efd':'white',width: '2em', height: '3em' }} />
      </IconButton> 

   <Menu
        keepMounted
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        open={Boolean(props.anchorEl)}
      >
        <MenuItem onClick={() => {
                        props.Orders()
                      }}>My Orders</MenuItem>
        
      </Menu>
        </Col>
   <Col>

  <img className="logo" src={logo} alt="Girl in a jacket" style={{height:"50" ,display: 'block',width:'50%',marginTop: '25px'}}/>
   </Col>

    </Row>}
   {props.logo ?<img className="logo" src={logo} alt="Girl in a jacket" style={{height:"50" ,display: 'block'}}/>:null}

    </>
  );
}
export default Menudetails;
