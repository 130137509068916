import React, { useState, useEffect } from "react";
import "./style.css";
import Image from "../../assets/delivery.svg";
import { services } from "./service";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import { ToastContainer, toast } from "react-toastify";
import { useShopify } from "../../hooks";
import Menudetails from "../menu.js";

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const Login = ({ history }) => {
  const { addAddress } = useShopify();
  // var shipping_address = services.getCookie("shipping_address");
  // var billing_address = services.getCookie("billing_address");
  // var billing_shipping_same = services.getCookie("billing_shipping_same");
  // var checkoutDetails = services.getCookie("checkoutDetails");
  // var billing_address_backup, shipping_address_backup;
  // billing_address_backup = billing_address = billing_address
  //   ? JSON.parse(billing_address)
  //   : null;
  // shipping_address_backup = shipping_address = shipping_address
  //   ? JSON.parse(shipping_address)
  //   : null;
  //checkoutDetails = checkoutDetails ? JSON.parse(checkoutDetails) : null;

  // if (shipping_address && billing_address) {
  //   var shipping_address = [shipping_address].map(
  //     ({ country, state, ...item }) => item
  //   )[0];
  //   shipping_address.country = {
  //     value: shipping_address_backup.country,
  //     label: shipping_address_backup.country,
  //   };
  //   shipping_address.state = {
  //     value: shipping_address_backup.state,
  //     label: shipping_address_backup.state,
  //   };
  //   var billing_address = [billing_address].map(
  //     ({ country, state, ...item }) => item
  //   )[0];
  //   billing_address.country = {
  //     value: billing_address_backup.country,
  //     label: billing_address_backup.country,
  //   };
  //   billing_address.state = {
  //     value: billing_address_backup.state,
  //     label: billing_address_backup.state,
  //   };
  //   var billing_shipping_same = JSON.parse(billing_shipping_same.toLowerCase());
  // } else {
  //   billing_shipping_same = true;
  //   if (!shipping_address) {
  //     shipping_address = {
  //       country: { label: "United States",
  //           value: "US"},
  //     };
  //   } else {
  //     shipping_address.country = { label: "United States",
  //           value: "US"};
  //   }

  //   billing_address = {
  //     country: { label: "United States",
  //           value: "US"},
  //   };
  // }
  // var billing_shipping_same = billing_shipping_same;
  // const [values, setValues] = useState(shipping_address);
  // const [billingValues, setBillingValues] = useState(billing_address);

  // const [error, setError] = useState({});
  // const [billingError, setBillingError] = useState({});
  // const [shipBillSame, setShipBillSame] = useState(billing_shipping_same);
  const [values, setValues] = useState({});
  const [billingValues, setBillingValues] = useState({});
  const [address, setAddress] = useState({})
  const [error, setError] = useState({});
  const [billingError, setBillingError] = useState({});
  const [shipBillSame, setShipBillSame] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [newCountryList, setBillingCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [billingStateList, setBillingStateList] = useState([]);
  const [checkoutDetails, setCheckoutDetails] = useState({});
  const [previosAddress, setPreviosAddress] = useState(true);
  const [previosAddressBilling, setPreviosAddressBilling] = useState(true);

  const [prefechedAddress, setPreFetchedAddress] = useState([]);
  const [prefechedAddressBilling, setPreFetchedAddressBilling] = useState([]);
  const [billingUserEmailFlag, setBillingUserEmailFlag] = useState(false);
  const [shippingUserEmailFlag, setShippingUserEmailFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleFocus = (name) => {
    setError({
      ...error,
      [name]: "",
    });
  };
  const handleBillingFocus = (name) => {
    setBillingError({
      ...billingError,
      [name]: "",
    });
  };
  const Orders = () => {


    window.location.href = "/order/list";

  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleValues = (value, name, country) => {
    if (name == "phone") {
      //let c = String(value).slice(country.dialCode.length);
      //value = c;
      handleFocus(name);
      setValues({
        ...values,
        [name]: value,
        ["mobileCode"]: country.dialCode,
      });
    } else {
      handleFocus(name);
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleBillingValues = (value, name, country) => {
    if (name == "phone") {
      //  let c = String(value).slice(country.dialCode.length);
      //  value = c;
      handleFocus(name);
      setBillingValues({
        ...billingValues,
        [name]: value,
        ["mobileCode"]: country.dialCode,
      });
    } else {
      handleFocus(name);
      setBillingValues({
        ...billingValues,
        [name]: value,
      });
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "1.4rem",
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: "sans-serif",
        fontSize: "1.4rem",
      };
    },
    input: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: "sans-serif",
        fontSize: "1.4rem",
      };
    },
  };
  const validateShipping = () => {
    let formValid = true;
    let formErrors = {};
    if (!values.firstname) {
      formErrors.firstname = "Required";
      formValid = false;
    }
    if (!values.lastname) {
      formErrors.lastname = "Required";
      formValid = false;
    }
    if (!values.address1) {
      formErrors.address1 = "Required";
      formValid = false;
    }
    // if (!values.address2) {
    //   formErrors.address2 = "Required";
    //   formValid = false;
    // }
    if (!values.user_email) {
      formErrors.user_email = "Required";
      formValid = false;
    } else {
      if (!emailReg.test(String(values.user_email).toLowerCase())) {
        formErrors.user_email = "Please enter valid email";
        formValid = false;
      }
    }
    if (!values.country) {
      formErrors.country = "Required";
      formValid = false;
    }
    if (!values.city) {
      formErrors.city = "Required";
      formValid = false;
    }
    if (!values.state) {
      formErrors.state = "Required";
      formValid = false;
    }
    if (!values.zip) {
      formErrors.zip = "Required";
      formValid = false;
    }
    if (values.phone) {
      if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(values.phone, 'US'), 'US') || phoneUtil.isValidNumberForRegion(phoneUtil.parse(values.phone, 'CA'), 'CA') || phoneUtil.isValidNumberForRegion(phoneUtil.parse(values.phone, 'IN'), 'IN')) {

      } else {
        formErrors.phone = "Invalid Phone Number";
        formValid = false;
      }
    } else {
      formErrors.phone = "Required";
      formValid = false;
    }

    setError(formErrors);
    return formValid;
  };
  const validateBilling = () => {
    let formValid = true;
    let formErrors = {};

    if (!billingValues.firstname) {
      formErrors.firstname = "Required";
      formValid = false;
    }
    if (!billingValues.lastname) {
      formErrors.lastname = "Required";
      formValid = false;
    }
    if (!billingValues.address1) {
      formErrors.address1 = "Required";
      formValid = false;
    }
    // if (!billingValues.address2) {
    //   formErrors.address2 = "Required";
    //   formValid = false;
    // }
    if (!billingValues.user_email) {
      formErrors.user_email = "Required";
      formValid = false;
    } else {
      if (!emailReg.test(String(billingValues.user_email).toLowerCase())) {
        formErrors.user_email = "Please enter valid email";
        formValid = false;
      }
    }
    if (!billingValues.country) {
      formErrors.country = "Required";
      formValid = false;
    }
    if (!billingValues.city) {
      formErrors.city = "Required";
      formValid = false;
    }
    if (!billingValues.state) {
      formErrors.state = "Required";
      formValid = false;
    }
    if (!billingValues.zip) {
      formErrors.zip = "Required";
      formValid = false;
    }
    if (billingValues.phone) {
    } else {
      formErrors.phone = "Required";
      formValid = false;
    }

    setBillingError(formErrors);
    return formValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var shipping_address = {
      firstname: values.firstname,
      lastname: values.lastname,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state.label,
      zip: values.zip,
      country: values.country.label,
      phone: values.phone,
      user_email: values.user_email,
    };
    var billing_address;

    try {
      if (shipBillSame) {
        setBillingError({});
        if (validateShipping()) {

          try {
            // await addAddress(
            //     localStorage.getItem("CID"),
            //     shipping_address
            // ).then(async(res) => {

            // if (res.addresResponse.shippingAddress && res.emailReponse.email) {
            //     if (res.addresResponse.shippingAddress.province == values.state.label && res.addresResponse.shippingAddress.country == values.country.label && res.addresResponse.shippingAddress.zip == values.zip) {

            services.shippindAddressAdd(values, false).then((response) => {
            //  console.log("response", response);
              if (response?.data?.success == true) {
                services.shippindAddressAdd(values, true).then((response) => {
                  // services.setCookie(
                  //     "shipping_address",
                  //     JSON.stringify(shipping_address)
                  // );
                  // services.setCookie(
                  //     "billing_address",
                  //     JSON.stringify(shipping_address)
                  // );
                  // services.setCookie("billing_shipping_same", "true");
                  setTimeout(() => {
                    history.push({
                      pathname: "/payment",
                    });
                  }, 100);
                })
                  .catch((error) => {
                    toast.error("Invalid shipping address or email");

                  })
              } else {
            //    console.log("error res", response)

                toast.error(response?.data?.message);

              }

            })
              .catch((error) => {
               // console.log("error")
                toast.error("Invalid shipping address or email");

              })


            //      } else {
            //          toast.error("Invalid shipping address or email");
            //      }
            //  } else {
            //      toast.error("Invalid shipping address or email");
            // }
            // });

          } catch (error) {
            toast.error("Something went wrong, Please Reload the page!");
          }
        }
      } else {
        let valid = true;
        if (validateShipping()) {
          valid = validateBilling();
        } else {
          valid = false;
          validateBilling();
        }
        if (valid) {
         // console.log("shipping_address", shipping_address)
          try {
            // await addAddress(
            //   localStorage.getItem("CID"),
            //   shipping_address
            // ).then(async (res) => {
            //    console.log("res res",res)
            //   console.log("res res 4",res.addresResponse.shippingAddress,res.emailReponse.email)
            //   if(res.addresResponse.shippingAddress && res.emailReponse.email){
            //      console.log("res res 56",res.addresResponse.shippingAddress.state,values.state.label,res.addresResponse.shippingAddress.country, values.country.label ,res.addresResponse.shippingAddress.zip, values.zip)
            // if(res.addresResponse.shippingAddress.province == values.state.label && res.addresResponse.shippingAddress.country == values.country.label && res.addresResponse.shippingAddress.zip == values.zip){
            services.shippindAddressAdd(values, false).then((response) => {
              if (response?.data?.success == true) {
                services.shippindAddressAdd(billingValues, true).then((response) => {
                  // await services.shippindAddressAdd(values, false);
                  // await services.shippindAddressAdd(billingValues, true);
                  // var shipping_address = {
                  //     firstname: values.firstname,
                  //     lastname: values.lastname,
                  //     address1: values.address1,
                  //     address2: values.address2,
                  //     city: values.city,
                  //     state: values.state.label,
                  //     zip: values.zip,
                  //     country: values.country.label,
                  //     phone: values.phone,
                  //     user_email: values.user_email,
                  // };
                  // billing_address = {
                  //     firstname: billingValues.firstname,
                  //     lastname: billingValues.lastname,
                  //     address1: billingValues.address1,
                  //     address2: billingValues.address2,
                  //     city: billingValues.city,
                  //     state: billingValues.state.label,
                  //     zip: billingValues.zip,
                  //     country: billingValues.country.label,
                  //     phone: billingValues.phone,
                  //     user_email: billingValues.user_email,
                  // };
                  // services.setCookie(
                  //     "shipping_address",
                  //     JSON.stringify(shipping_address)
                  // );
                  // services.setCookie(
                  //     "billing_address",
                  //     JSON.stringify(billing_address)
                  // );
                  // services.setCookie("billing_shipping_same", "false");
                  setTimeout(() => {
                    history.push({
                      pathname: "/payment",
                    });
                  }, 100);
                })
                  .catch((error) => {
                  //  console.log("response", error)

                  })
              } else {
              //  console.log("error res", response)

                toast.error(response?.data?.message);

              }

            })
              .catch((error) => {
               // console.log("response", error)

              })
            //         }else{
            // toast.error("Invalid shipping address or email");

            //         }
            //       }else{
            //        toast.error("Invalid shipping address or email");

            //       }
            //   });

          } catch (error) {
           // console.log("error", error)
            toast.error("Something went wrong, Please Reload the page!");
          }
        }
      }
    } catch (error) {
      toast.error("Something went wrong, Please Reload the page!");
    }
  };

  useEffect(() => {
    services.getUserAddress().then((response) => {

      var shipping_address = response.data.shipping_address;

      var billing_address = response.data.billing_address;
      var billing_shipping_same = response.data.billing_shipping_same;
      // var checkoutDetails = services.getCookie("checkoutDetails");
      var billing_address_backup, shipping_address_backup;
      //   billing_address = JSON.parse(billing_address);
      billing_address_backup = billing_address;
   //   console.log("response shipping_address", shipping_address)

      //  shipping_address = JSON.parse(shipping_address);
      shipping_address_backup = shipping_address;
      // checkoutDetails = checkoutDetails ? JSON.parse(checkoutDetails) : null;

      if (shipping_address && billing_address) {
        if (billing_address.user_email) {
          setBillingUserEmailFlag(true)
        }
        if (shipping_address.user_email) {
          setShippingUserEmailFlag(true)

        }
        var shipping_address = [shipping_address].map(
          ({ country, state, ...item }) => item
        )[0];
       // console.log("shipping_address", shipping_address)

        shipping_address.country = {
          value: shipping_address_backup.country,
          label: shipping_address_backup.country,
        };
      //  console.log("shipping_address country", shipping_address)

        shipping_address.state = {
          value: shipping_address_backup.state,
          label: shipping_address_backup.state,
        };
      //  console.log("shipping_address state", shipping_address)

        var billing_address = [billing_address].map(
          ({ country, state, ...item }) => item
        )[0];
       // console.log("billing_address", shipping_address)

        billing_address.country = {
          value: billing_address_backup.country,
          label: billing_address_backup.country,
        };
       // console.log("billing_address country", shipping_address)

        billing_address.state = {
          value: billing_address_backup.state,
          label: billing_address_backup.state,
        };
       // console.log("billing_address state", shipping_address)
       // console.log("billing_address billing_shipping_same", billing_shipping_same)

        var billing_shipping_same = billing_shipping_same;

      } else {
        billing_shipping_same = true;
        if (!shipping_address) {
          shipping_address = {
            country: {
              label: "United States",
              value: "US"
            },
          };
        } else {
          shipping_address.country = {
            label: "United States",
            value: "US"
          };
        }

        billing_address = {
          country: {
            label: "United States",
            value: "US"
          },
        };
      }
    //  console.log("billing_address state", shipping_address)
      if (!shipping_address?.country?.value) {
        shipping_address.country = { label: "United States", value: "US" };
      }
      if (!billing_address?.country?.value) {
        billing_address.country = { label: "United States", value: "US" };
      }
      var billing_shipping_same = billing_shipping_same;
      setValues(shipping_address);
      setBillingValues(billing_address);
      setShipBillSame(billing_shipping_same);
      setAddress({
        ...address,
        shipping_address: shipping_address,
        billing_address: billing_address,

      })
      setIsLoading(false)


    }).catch((error) => {

    })

    services.fetchCart().then((res) => {
      var checkoutDetails = { "subtotalPrice": res.data.lineItemsSubtotalPrice, "totalShippingPrice": res.data.totalShippingPrice, "totalTax": res.data.totalTax, "totalPrice": res.data.totalPrice }
      setCheckoutDetails(checkoutDetails)
    //  console.log("res", res)
    }).catch((error) => {

    })

    services
      .getCountryList()
      .then((res) => {
        let data = [];
        //data.push({label:'Country',values:'country', disabled:true})

        let countries = res.data;
        var dropdown_countries = countries.map((country) => {
          return {
            ...country,
            label: country.name,
            value: country.shortName,
          };
        });
      //  console.log("selected_country", address.shipping_address)

        if (address.shipping_address) {
          let selected_country = dropdown_countries.filter(
            (item) => item.label == address.shipping_address.country.label
          )[0];

          setValues({
            ...values,
            country: selected_country,
          });
        }
        if (address.billing_address) {
          let selected_billing_country = dropdown_countries.filter(
            (item) => item.label == address.billing_address.country.label
          )[0];
          setBillingValues({
            ...billingValues,
            country: selected_billing_country,
          });
        }
        setCountryList(dropdown_countries);
        setBillingCountryList(dropdown_countries);
      })
      .catch((e) => { });
  }, []);
  useEffect(() => {
    services
      .getCountryList()
      .then((res) => {
        let data = [];
        //data.push({label:'Country',values:'country', disabled:true})

        let countries = res.data;
        var dropdown_countries = countries.map((country) => {
          return {
            ...country,
            label: country.name,
            value: country.shortName,
          };
        });
      //  console.log("selected_country", address.shipping_address)

        if (address.shipping_address) {
          let selected_country = dropdown_countries.filter(
            (item) => item.label == address.shipping_address.country.label
          )[0];

          setValues({
            ...values,
            country: selected_country,
          });
        }
        if (address.billing_address) {
          let selected_billing_country = dropdown_countries.filter(
            (item) => item.label == address.billing_address.country.label
          )[0];
          setBillingValues({
            ...billingValues,
            country: selected_billing_country,
          });
        }
        setCountryList(dropdown_countries);
        setBillingCountryList(dropdown_countries);
      })
      .catch((e) => { });
  }, [address.shipping_address, address.billing_address]);
  // useEffect(() => {
  //   if (address.billing_address) {
  //           let selected_billing_country = dropdown_countries.filter(
  //             (item) => item.label == address.billing_address.country.label
  //           )[0];
  //           setBillingValues({
  //             ...billingValues,
  //             country: selected_billing_country,
  //           });
  //         }
  //    }, [address.billing_address]);
  useEffect(() => {
    values.country &&
      values.country.shortName &&
      services
        .getStateList(values.country.shortName)
        .then((res) => {
          var dropdown_states = [];

          // setStateList([
          //   {
          //     value: "1",
          //     label: "Loading..",
          //     isDisabled: true,
          //     isSelected: true,
          //   },
          // ]);

          if (res.data.length !== 0) {
            dropdown_states = res.data.map((item, index) => {

              return {
                value: index,
                label: item.name,
                shortCode: item.shortName,
              };
            });

          }

          setTimeout(() => {
            if (values?.state) {
              let selectedState = {};
              if (values?.state) {
                selectedState = dropdown_states?.filter(item => address?.shipping_address?.state.label === item.label)[0];

              }
              if (values?.state?.shortCode) {
                selectedState = dropdown_states.filter(
                  (item) => values.state?.shortCode === item.shortCode
                )[0];
              }
              // else{
              //   selectedState = dropdown_states.filter(
              //     (item) => shipping_address.state?.shortCode === item.shortCode
              //   )[0];
              // }
              if (!selectedState?.value) {
                selectedState = dropdown_states[0];
              }

              setValues({
                ...values,
                state: selectedState,
              });
            }
            setStateList(dropdown_states);
          }, 1000);
        })
        .catch((e) => { });
  }, [values.country]);

  useEffect(() => {
    billingValues.country &&
      billingValues.country.shortName &&
      services
        .getStateList(billingValues.country.shortName)
        .then((res) => {
          var dropdown_states = [];
          // setBillingStateList([
          //   {
          //     value: "1",
          //     label: "Loading..",
          //     isDisabled: true,
          //     isSelected: true,
          //   },
          // ]);
          if (res.data.length !== 0) {
            dropdown_states = res.data.map((item, index) => {
              return {
                value: index,
                label: item.name,
                shortCode: item.shortName,
              };
            });
          }



          setTimeout(() => {
            let selectedState = {};
            if (billingValues?.state) {
              selectedState = dropdown_states?.filter(item => address?.billing_address?.state.label === item.label)[0];


            }
            if (billingValues?.state?.shortCode) {
              selectedState = dropdown_states?.filter(
                (item) => billingValues?.state?.shortCode === item.shortCode
              )[0];

            }
            if (!selectedState?.value) {
              selectedState = dropdown_states[0];
            }
            setBillingValues({
              ...billingValues,
              state: selectedState,
            });
            setBillingStateList(dropdown_states);
          }, 1000);
        })
        .catch((e) => { });
  }, [billingValues.country]);

  useEffect(() => {
  //  console.log("Values.address1");
   // console.log("values.address1", values.address1);
   // console.log("address", address);
    // console.log(values.address1);

    if (previosAddress && values.address1) {
    //  console.log("Values.address1 inside 1");

      // if (previosAddress &&values.address1  !== values.address1) {
    //  console.log("Values.address1 inside 2");

      services.getAddressCompleted(values.address1).then((res) => {
        let data = res.data;
        setPreFetchedAddress(
          data ? data.map((address) => ({ ...address?.address })) : []
        );
      });
      // }
    }
  }, [values.address1]);

  useEffect(() => {
    if (
      previosAddressBilling &&
      billingValues.address1

    ) {
      // if (
      //   previosAddressBilling &&
      //   billingValues.address1 &&
      //   address.billing_address.address1 !== billingValues.address1
      // ) {
      services.getAddressCompleted(billingValues.address1).then((res) => {
        let data = res.data;
        setPreFetchedAddressBilling(
          data ? data.map((address) => ({ ...address?.address })) : []
        );
      });
      // }
    }
  }, [billingValues.address1]);

  const handleSelect = async (selectedAddress) => {
    setPreviosAddress(false);
    let state = await {
      label: selectedAddress.prov,
      value: selectedAddress.prov,
      shortCode: selectedAddress.prov,
    };

    let c = await countryList.filter((item) => {
      return item.value === selectedAddress.country;
    })[0];

    if (c && state) {
      let newvalues = await {
        ...values,
        address1: selectedAddress.address,
        city: selectedAddress.city,
        zip: selectedAddress.pc,
        country: c,
        state: state,
      };

      setValues(newvalues);

      setTimeout(() => {
        setPreFetchedAddress([]);
        setPreviosAddress(true);
      }, 100);
    }
  };



  const handleSelectBilling = async (selectedAddress) => {
    setPreviosAddressBilling(false);
    let state = await {
      label: selectedAddress.prov,
      value: selectedAddress.prov,
      shortCode: selectedAddress.prov,
    };

    let c = await newCountryList.filter((item) => {
      return item.value === selectedAddress.country;
    })[0];

    if (c && state) {
      let newvalues = await {
        ...billingValues,
        address1: selectedAddress.address,
        city: selectedAddress.city,
        zip: selectedAddress.pc,
        country: c,
        state: state,
      };

      await setBillingValues(newvalues);



      setTimeout(() => {
        setPreFetchedAddressBilling([]);
        setPreviosAddressBilling(true);
      }, 100);
    }
  };



  useEffect(() => {
    if (stateList) {
      let newSatate = stateList.filter(
        (item) => values.state?.shortCode === item.shortCode
      )[0];

      if (newSatate) {
        setValues({ ...values, state: newSatate });
      }
    }
  }, [values.state]);



  useEffect(() => {
    if (billingStateList) {
      let newSatate = billingStateList.filter(
        (item) => billingValues.state?.shortCode === item.shortCode
      )[0];

      if (newSatate) {
        setBillingValues({ ...billingValues, state: newSatate });
      }
    }
  }, [billingValues.state]);



  return (
    <form className="shipping" onSubmit={handleSubmit}>
      <div className="Heading" style={{ textAlign: "left" }}>
        <Menudetails
          handleClick={handleClick}
          anchorEl={anchorEl}
          handleClose={handleClose}
          Orders={Orders}
        />

        <div className="addressCard">
          <div className="address_section">
            <div className="Heading"> Shipping Details </div>
            <div className="row">
              <div className="ship_bill"> Shipping Address </div>
              <div className="col col-sm-6 feilds">
                {/* <label>First Name</label> */}
                <input
                  value={values.firstname}
                  id="myInput"
                  className="InputAddress"
                  placeholder="First Name"
                  onChange={({ target }) => {
                    handleValues(target.value.trimLeft().trimLeft(), "firstname");
                  }}
                />
                {error?.firstname && (
                  <div className="errorClass"> {error?.firstname} </div>
                )}
              </div>
              <div className="col col-sm-6 feilds">
                {/* <label>Last Name</label> */}
                <input
                  id="myInput"
                  value={values.lastname}
                  className="InputAddress"
                  placeholder="Last Name"
                  onChange={({ target }) => {
                    handleValues(target.value.trimLeft(), "lastname");
                  }}
                />
                {error?.lastname && (
                  <div className="errorClass"> {error?.lastname} </div>
                )}
              </div>
              <div className="col col-sm-12 feilds">
                {/* <label>Appartment</label> */}
                <input
                  id="myInputAddress"
                  value={values.address1}
                  className="InputAddress"
                  placeholder="Address"
                  onChange={({ target }) => {
                    handleValues(target.value.trimLeft(), "address1");
                  }}
                />
                <div className="prefetchedAddress">
                  {prefechedAddress &&
                    prefechedAddress.map((addressElement, index) => {
                      return (
                        <li
                          className="listItem"
                          key={index}
                          onClick={() => {
                            handleSelect(addressElement);
                          }}
                        >
                          <strong>{addressElement.address}</strong>,
                          <small style={{ paddingLeft: '.5rem' }}>{addressElement.city}</small>,
                          <small style={{ paddingLeft: '.5rem' }}>{addressElement.prov}</small>
                          <small style={{ paddingLeft: '.7rem' }}>{addressElement.pc}</small>
                        </li>
                      );
                    })}
                </div>

                {error?.address1 && (
                  <div className="errorClass"> {error?.address1} </div>
                )}
              </div>

              <div className="col col-sm-12 feilds">
                {/* <label>Address</label> */}
                <input
                  id="myInput"
                  value={values.address2}
                  className="InputAddress"
                  placeholder="Apartment, suite, etc."
                  onChange={({ target }) => {
                    handleValues(target.value.trimLeft(), "address2");
                  }}
                />
                {error?.address2 && (
                  <div className="errorClass"> {error?.address2} </div>
                )}
              </div>
              <div
                style={{
                  marginBottom: ".5rem",
                }}
              >
                <PhoneInput
                  country={'us'}
                  value={values.phone}
                  onlyCountries={['us', 'ca', 'in']}
                  autoFormat
                  className="loginButton"
                  style={{
                    height: "4rem",
                    minWidth: "90%",
                  }}
                  onChange={(value, country) => {
                    handleValues(value, "phone", country);
                  }}
                />
                {error?.phone && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "1rem",
                    }}
                  >
                    {error?.phone}
                  </div>
                )}
              </div>
              <div className="col col-sm-12 feilds">
                {/* <label>City</label> */}
                <input
                  id="myInput"
                  className="InputAddress"
                  placeholder="Email"
                  disabled={shippingUserEmailFlag ? true : false}
                  value={values.user_email}
                  onChange={({ target }) => {
                    handleValues(target.value.trimLeft(), "user_email");
                  }}
                />
                {error?.user_email && (
                  <div className="errorClass"> {error?.user_email} </div>
                )}
              </div>
              <div className="col col-sm-12 feilds">
                {/* <label>City</label> */}
                <input
                  id="myInput"
                  className="InputAddress"
                  placeholder="City"
                  value={values.city}
                  onChange={({ target }) => {
                    handleValues(target.value.trimLeft(), "city");
                  }}
                />
                {error?.city && <div className="errorClass"> {error?.city} </div>}
              </div>
              <div className="row noGutter">
                <div className="col col-sm-4 feilds">
                  {/* <label>Country</label> */}
                  {/* <select name="cars" id="cars" className="InputAddress"
                                                onChange={({target})=>{console.log(JSON.stringify(target.text));}}
                                                >
                                                  <option value='select' >Country</option>
                                                  {
                                                    countries.length !==0 && countries.map(country =>{
                                                return <option value={country.shortName} key={country.shortName} >{country.name} </option>
                                                    })
                                                  }
                                                </select> */}
                  <Select
                    placeholder="Country"
                    options={newCountryList}
                    className="myInput"
                    classNamePrefix="innerClass"
                    styles={customStyles}
                    value={values.country}
                    onChange={(value) => {
                      handleValues(value, "country");
                    }}
                  />
                  {error?.country && (
                    <div
                      className="errorClass"
                      style={{
                        marginTop: ".1rem",
                      }}
                    >
                      {error?.country}
                    </div>
                  )}
                </div>
                <div className="col col-sm-4 feilds">
                  {/* <label>State</label> */}
                  {/* <select name="cars" id="cars" className="InputAddress">
                                                  <option value="volvo">State</option>
                                                </select> */}
                  <Select
                    placeholder="State"
                    options={stateList}
                    className="myInput"
                    classNamePrefix="innerClass"
                    styles={customStyles}
                    value={values.state}
                    onChange={(value) => {
                      handleValues(value, "state");
                    }}
                  />
                  {error?.state && (
                    <div
                      className="errorClass"
                      style={{
                        marginTop: ".1rem",
                      }}
                    >
                      {error?.state}
                    </div>
                  )}
                </div>
                <div className="col col-sm-4 feilds">
                  {/* <label>ZIP-Code</label> */}
                  <input
                    id="myInput"
                    className="InputAddress"
                    placeholder="ZIP-Code"
                    type="number"
                    value={values.zip}
                    onChange={({ target }) => {
                      handleValues(target.value.trimLeft(), "zip");
                    }}
                  />
                  {error?.zip && <div className="errorClass"> {error?.zip} </div>}
                </div>
              </div>
              <div className="checkboxField">
                <input
                  type="checkbox"
                  style={{
                    width: "18px",
                    height: "40px",
                  }}
                  checked={shipBillSame}
                  onChange={() => {
                    setShipBillSame(!shipBillSame);
                  }}
                />
                <label
                  style={{
                    paddingLeft: "5px",
                  }}
                >
                  Shipping address same as Billing Address
                </label>
              </div>
            </div>
            {!shipBillSame && (
              <div className="row">
                <div className="ship_bill"> Billing Address </div>
                <div className="col col-sm-6 feilds">
                  {/* <label>First Name</label> */}
                  <input
                    value={billingValues.firstname}
                    id="myInput"
                    className="InputAddress"
                    placeholder="First Name"
                    onChange={({ target }) => {
                      handleBillingValues(
                        target.value.trimLeft().trimLeft(),
                        "firstname"
                      );
                    }}
                  />
                  {billingError?.firstname && (
                    <div className="errorClass"> {billingError?.firstname} </div>
                  )}
                </div>
                <div className="col col-sm-6 feilds">
                  {/* <label>Last Name</label> */}
                  <input
                    id="myInput"
                    value={billingValues.lastname}
                    className="InputAddress"
                    placeholder="Last Name"
                    onChange={({ target }) => {
                      handleBillingValues(target.value.trimLeft(), "lastname");
                    }}
                  />
                  {billingError?.lastname && (
                    <div className="errorClass"> {billingError?.lastname} </div>
                  )}
                </div>
                <div className="col col-sm-12 feilds">
                  {/* <label>Appartment</label> */}
                  <input
                    id="myInput"
                    value={billingValues.address1}
                    className="InputAddress"
                    placeholder="Address"
                    onChange={({ target }) => {
                      handleBillingValues(target.value.trimLeft(), "address1");
                    }}
                  />
                  <div className="prefetchedAddress">
                    {prefechedAddressBilling &&
                      prefechedAddressBilling.map((addressElement, index) => {
                        return (
                          <li
                            className="listItem"
                            key={index}
                            onClick={() => {
                              handleSelectBilling(addressElement);
                            }}
                          >
                            <strong>{addressElement.address}</strong>,
                            <small style={{ paddingLeft: '.5rem' }}>{addressElement.city}</small>,
                            <small style={{ paddingLeft: '.5rem' }}>{addressElement.prov}</small>
                            <small style={{ paddingLeft: '.7rem' }}>{addressElement.pc}</small>
                          </li>
                        );
                      })}
                  </div>
                  {billingError?.address1 && (
                    <div className="errorClass"> {billingError?.address1} </div>
                  )}
                </div>
                <div className="col col-sm-12 feilds">
                  {/* <label>Address</label> */}
                  <input
                    id="myInput"
                    value={billingValues.address2}
                    className="InputAddress"
                    placeholder="Apartment, suite, etc."
                    onChange={({ target }) => {
                      handleBillingValues(target.value.trimLeft(), "address2");
                    }}
                  />
                  {billingError?.address2 && (
                    <div className="errorClass"> {billingError?.address2} </div>
                  )}
                </div>
                <div
                  style={{
                    marginBottom: ".5rem",
                  }}
                >
                  <PhoneInput
                    country={localStorage.getItem("C_Code")?.toLocaleLowerCase()}
                    value={billingValues.phone}
                    onlyCountries={['us', 'ca', 'in']}
                    autoFormat
                    className="loginButton"
                    style={{
                      height: "4rem",
                      minWidth: "90%",
                    }}
                    onChange={(value, country) => {
                      handleBillingValues(value, "phone", country);
                    }}
                  />
                  {billingError?.phone && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "1rem",
                      }}
                    >
                      {billingError?.phone}
                    </div>
                  )}
                </div>
                <div className="col col-sm-12 feilds">
                  {/* <label>City</label> */}
                  <input
                    id="myInput"
                    className="InputAddress"
                    placeholder="Email"
                    disabled={billingUserEmailFlag? true : false}

                    value={billingValues.user_email}
                    onChange={({ target }) => {
                      handleBillingValues(target.value.trimLeft(), "user_email");
                    }}
                  />
                  {billingError?.user_email && (
                    <div className="errorClass"> {billingError?.user_email} </div>
                  )}
                </div>
                <div className="col col-sm-12 feilds">
                  {/* <label>City</label> */}
                  <input
                    id="myInput"
                    className="InputAddress"
                    placeholder="City"
                    value={billingValues.city}
                    onChange={({ target }) => {
                      handleBillingValues(target.value.trimLeft(), "city");
                    }}
                  />
                  {billingError?.city && (
                    <div className="errorClass"> {billingError?.city} </div>
                  )}
                </div>
                <div className="row noGutter">
                  <div className="col col-sm-4 feilds">
                    {/* <label>Country</label> */}
                    {/* <select name="cars" id="cars" className="InputAddress"
                                                       onChange={({target})=>{console.log(JSON.stringify(target.text));}}
                                                       >
                                                         <option value='select' >Country</option>
                                                         {
                                                           countries.length !==0 && countries.map(country =>{
                                                       return <option value={country.shortName} key={country.shortName} >{country.name} </option>
                                                           })
                                                         }
                                                       </select> */}
                    <Select
                      menuPlacement="top"
                      placeholder="Country"
                      options={countryList}
                      className="myInput"
                      classNamePrefix="innerClass"
                      styles={customStyles}
                      value={billingValues.country}
                      onChange={(value) => {
                        handleBillingValues(value, "country");
                      }}
                    />
                    {billingError?.country && (
                      <div
                        className="errorClass"
                        style={{
                          marginTop: ".1rem",
                        }}
                      >
                        {billingError?.country}
                      </div>
                    )}
                  </div>
                  <div className="col col-sm-4 feilds">
                    {/* <label>State</label> */}
                    {/* <select name="cars" id="cars" className="InputAddress">
                                                         <option value="volvo">State</option>
                                                       </select> */}
                    <Select
                      menuPlacement="top"
                      placeholder="State"
                      options={billingStateList}
                      className="myInput"
                      classNamePrefix="innerClass"
                      styles={customStyles}
                      value={billingValues.state}
                      onChange={(value) => {
                        handleBillingValues(value, "state");
                      }}
                    />
                    {billingError?.state && (
                      <div
                        className="errorClass"
                        style={{
                          marginTop: ".1rem",
                        }}
                      >
                        {billingError?.state}
                      </div>
                    )}
                  </div>
                  <div className="col col-sm-4 feilds">
                    {/* <label>ZIP-Code</label> */}
                    <input
                      id="myInput"
                      className="InputAddress"
                      placeholder="ZIP-Code"
                      type="number"
                      value={billingValues.zip}
                      onChange={({ target }) => {
                        handleBillingValues(target.value.trimLeft(), "zip");
                      }}
                    />
                    {billingError?.zip && (
                      <div className="errorClass"> {billingError?.zip} </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="pricingShipMobile">
              <div className="PricingItems">
                <hr />
                <div className="PricingItem">
                  <div className="PricingItemHead"> SubTotal </div>
                  <div> {checkoutDetails?.subtotalPrice} </div>
                </div>
                <div className="PricingItem">
                  <div className="PricingItemHead"> Shipping </div>
                  <div> {checkoutDetails?.totalShippingPrice} </div>
                </div>
                <div className="PricingItem">
                  <div className="PricingItemHead"> Taxes </div>
                  <div> {checkoutDetails?.totalTax} </div>
                </div>
                <hr />
                <div className="PricingItem">
                  <div className="PricingItemHeadTotal"> TOTAL </div>
                  <div
                    style={{
                      fontWeight: "900",
                      fontSize: "1.5rem",
                    }}
                  >
                    {checkoutDetails?.totalPrice}
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonDiv">
              <button type="submit" className="checkoutButton">
                Checkout
              </button>
            </div>
          </div>
          <div className="details_section">
            <div className="pricingShip">
              <div className="pricingHead">
                <div> Pricing Details </div>
              </div>
              <div className="PricingItems">
                <div className="PricingItem">
                  <div className="PricingItemHead"> SubTotal </div>
                  <div> {checkoutDetails?.subtotalPrice} </div>
                </div>
                <div className="PricingItem">
                  <div className="PricingItemHead"> Shipping </div>
                  <div> {checkoutDetails?.totalShippingPrice} </div>
                </div>
                <div className="PricingItem">
                  <div className="PricingItemHead"> Taxes </div>
                  <div> {checkoutDetails?.totalTax} </div>
                </div>
                <hr />
                <div className="PricingItem">
                  <div className="PricingItemHeadTotal"> TOTAL </div>
                  <div
                    style={{
                      fontWeight: "900",
                      fontSize: "1.5rem",
                    }}
                  >
                    {checkoutDetails?.totalPrice}
                  </div>
                </div>
              </div>
            </div>
            <div className="shopingIMG">
              <img src={Image} />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        style={{
          fontSize: "2rem",
        }}
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isLoading ? (
          <div id="app" class="loader" style={{ position: 'fixed',top: '50%',left: '50%',marginTop: '-50px',marginLeft: '-50px'}}></div>
      ) : null}
    </form>
  );
};

export default Login;
