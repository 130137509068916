import { useSelector, useDispatch } from "react-redux"
import Client from "shopify-buy"
import {services} from '../../../Service/service'
// Creates the client with Shopify-Buy and store info
//
// const client = Client.buildClient({
// 	storefrontAccessToken: "b6126dc3a425f6924d70b6bc376fe190",
// 	domain: "d-wisconsin.myshopify.com",
// })


const client = Client.buildClient({
	// storefrontAccessToken: "dd4d4dc146542ba7763305d71d1b3d38",
	// domain: "graphql.myshopify.com",
	storefrontAccessToken: "6d04b901ecfa818d8b0d5e7d6469f816",
	domain: "poppotv.myshopify.com",
})

//
// Example Storefront
//
// const client = Client.buildClient({
// 	storefrontAccessToken: "dd4d4dc146542ba7763305d71d1b3d38",
// 	domain: "graphql.myshopify.com",
// })

const PRODUCTS_FOUND = "shopify/PRODUCTS_FOUND"
const PRODUCT_FOUND = "shopify/PRODUCT_FOUND"
const COLLECTION_FOUND = "shopify/COLLECTION_FOUND"
const CHECKOUT_FOUND = "shopify/CHECKOUT_FOUND"
const SHOP_FOUND = "shopify/SHOP_FOUND"
const ADD_VARIANT_TO_CART = "shopify/ADD_VARIANT_TO_CART"
const UPDATE_QUANTITY_IN_CART = "shopify/UPDATE_QUANTITY_IN_CART"
const REMOVE_LINE_ITEM_IN_CART = "shopify/REMOVE_LINE_ITEM_IN_CART"
const OPEN_CART = "shopify/OPEN_CART"
const CLOSE_CART = "shopify/CLOSE_CART"
const CART_COUNT = "shopify/CART_COUNT"

const initialState = {
	isCartOpen: false,
	cartCount: 0,
	checkout: {},
	products: [],
	featured: [],
	product: {},
	shop: {},
}

export default (state = initialState, action) => {
	switch (action.type) {
		case PRODUCTS_FOUND:
			return { ...state, products: action.payload }
		case PRODUCT_FOUND:
			return { ...state, product: action.payload }
		case COLLECTION_FOUND:
			return { ...state, featured: action.payload }
		case CHECKOUT_FOUND:
			return { ...state, checkout: action.payload }
		case SHOP_FOUND:
			return { ...state, shop: action.payload }
		case ADD_VARIANT_TO_CART:
			return { ...state, checkout: action.payload }
		case UPDATE_QUANTITY_IN_CART:
			return { ...state, checkout: action.payload }
		case REMOVE_LINE_ITEM_IN_CART:
			return { ...state, checkout: action.payload }
		case OPEN_CART:
			return { ...state, isCartOpen: true }
		case CLOSE_CART:
			return { ...state, isCartOpen: false }
		case CART_COUNT:
			return { ...state, cartCount: action.payload }
		case 'ADD_ADDRESS':
			return { ...state, address: action.payload }
		case 'UPDATE_EMAIL':
			return { ...state, email: action.payload }
		default:
			return state
	}
}

// Gets all the products from Shopify
function getProducts() {
	return (dispatch) => {
		client.product.fetchAll().then((resp) => {
			dispatch({
				type: PRODUCTS_FOUND,
				payload: resp,
			})
		})
	}
}

// Gets individual item based on id
function getProduct(id) {
	return async (dispatch) => {
		const resp = await client.product.fetch(id)
		dispatch({
			type: PRODUCT_FOUND,
			payload: resp,
		})
		//console.log(resp);
		return resp
	}
}

//
// Gets a  collection based on that collection's id
//
// function getCollection() {
// 	const collectionId = "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzIwODAyMDYwMzAzMw=="
// 	return (dispatch) => {
// 		client.collection.fetchWithProducts(collectionId).then((resp) => {
// 			dispatch({
// 				type: COLLECTION_FOUND,
// 				payload: resp.products,
// 			})
// 		})
// 	}
// }

// Creates initial checkout state from Shopify
function checkout() {
	return (dispatch) => {
		client.checkout.create().then((resp) => {
			localStorage.setItem('CID',resp.id)
			dispatch({
				type: CHECKOUT_FOUND,
				payload: resp,
			})
		return resp
		})
	}
}

// Gets Shopify store information
function shopInfo() {
	return (dispatch) => {
		client.shop.fetchInfo().then((resp) => {
			dispatch({
				type: SHOP_FOUND,
				payload: resp,
			})
		})
	}
}

// Adds variants to cart/checkout
function addVariantToCart(checkoutId, lineItemsToAdd,product) {
	var newProduct = lineItemsToAdd[lineItemsToAdd.length -1];
	return async (dispatch) => {
	if(localStorage.getItem('CID')){
		const response = await client.checkout.addLineItems(
			checkoutId,
			lineItemsToAdd
		)
		services.addProduct(localStorage.getItem('trans_id'),product,newProduct.quantity,newProduct.variantId)
		dispatch({
			type: ADD_VARIANT_TO_CART,
			payload: response,
		})
		return response
	}
	else{
		window.alert('Someting went wrong')
	}
		
		
		
	}
}

// Updates quantity of line items in cart and in checkout state
function updateQuantityInCart(lineItemId, quantity, checkoutId,type,product_id,variant_id) {

	const lineItemsToUpdate = [
		{ id: lineItemId, quantity: parseInt(quantity, 10) },
	]

	return async (dispatch) => {
		const resp = await client.checkout.updateLineItems(
			checkoutId,
			lineItemsToUpdate
		)
		services.updateProductCount(localStorage.getItem('trans_id'),product_id,variant_id,type)
		dispatch({
			type: UPDATE_QUANTITY_IN_CART,
			payload: resp,
		})
		return resp
	}
}

// Removes line item from cart and checkout state
function removeLineItemInCart(checkoutId, lineItemId,product_id,variant_id) {
	
	return (dispatch) => {
		client.checkout.removeLineItems(checkoutId, [lineItemId]).then((resp) => {
			services.removeProduct(localStorage.getItem('trans_id'),product_id,variant_id)
			dispatch({
				type: REMOVE_LINE_ITEM_IN_CART,
				payload: resp,
			})
		
		})
	}
}


// add address
function addAddressShopify(checkoutId, address) {

let addressShopify ={
   address1: address.address1,
   address2: address.address2,
   city: address.city,
   company: null,
   country: address.country,
   firstName: address.firstname,
   lastName: address.lastname,
   phone: "+"+address.phone,
   province: address.state,
   zip: address.zip
}
let email = address.user_email


	return async(dispatch) => {
		const resp = await client.checkout.updateShippingAddress(checkoutId, addressShopify)
		const emailUpdateResponse = await	client.checkout.updateEmail(checkoutId, email)
				

				dispatch({
					type: 'ADD_ADDRESS',
					payload: resp,
				})
				dispatch({
					type: 'UPDATE_EMAIL',
					payload: emailUpdateResponse,
				})

				
				return {addresResponse:resp,emailReponse:emailUpdateResponse}
				

			
			};
			
	}


// To close the cart
function handleCartClose() {
	return {
		type: CLOSE_CART,
	}
}

// To open the cart
function handleCartOpen() {
	return {
		type: OPEN_CART,
	}
}

// Set the count of items in the cart
function handleSetCount(count) {
	return {
		type: CART_COUNT,
		payload: count,
	}
}

const fetchCart =(checkoutId)=>{
	return async (dispatch) => {
	services.fetchCart().then(res =>{
  	console.log("res",res);
  	if(res?.data?.lineItems){
  		setTimeout(() => {
				dispatch({
					type: ADD_VARIANT_TO_CART,
					payload: res,
				})

		}, 1000);
    }
  }).catch(e =>{
      console.log(e);
  })
  
}
}
export function useShopify() {
	const dispatch = useDispatch()
	const cartStatus = useSelector((appState) => appState.shopifyState.isCartOpen)
	const cartCount = useSelector((appState) => appState.shopifyState.cartCount)
	const products = useSelector((appState) => appState.shopifyState.products)
	const product = useSelector((appState) => appState.shopifyState.product)
	const featured = useSelector((appState) => appState.shopifyState.featured)
	const checkoutState = useSelector(
		(appState) => appState.shopifyState.checkout
	)
	const shopDetails = useSelector((appState) => appState.shopifyState.shop)
	const fetchProducts = () => dispatch(getProducts())
	const fetchProduct = (id) => dispatch(getProduct(id))
	const fetchCollection = (id) => dispatch(fetchCart(id))
	const createCheckout = () => dispatch(checkout())
	const createShop = () => dispatch(shopInfo())
	const closeCart = () => dispatch(handleCartClose())
	const openCart = () => dispatch(handleCartOpen())
	const setCount = (count) => dispatch(handleSetCount(count))

	const addVariant = (checkoutId, lineItemsToAdd,product) =>
		dispatch(addVariantToCart(checkoutId, lineItemsToAdd,product))
	const updateQuantity = (lineItemId, quantity, checkoutID,type,product_id,variant_id) =>
		dispatch(updateQuantityInCart(lineItemId, quantity, checkoutID,type,product_id,variant_id))
	const removeLineItem = (checkoutId, lineItemId,product_id,variant_id) =>
		dispatch(removeLineItemInCart(checkoutId, lineItemId,product_id,variant_id))
	const addAddress = (checkoutId, address) =>
		dispatch(addAddressShopify(checkoutId, address));
	return {
		products,
		product,
		featured,
		cartStatus,
		checkoutState,
		cartCount,
		shopDetails,
		addVariant,
		fetchProducts,
		fetchProduct,
		fetchCollection,
		createCheckout,
		createShop,
		closeCart,
		openCart,
		updateQuantity,
		removeLineItem,
		setCount,
		addAddress
		
	}
}

export const checkoutFetch ={
	fetchCart
}
