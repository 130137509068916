import React, { Fragment, useState, useEffect } from "react";
import "../Login/style.css";
import Image from "../../assets/delivery.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import edit from "../../assets/edit.svg";
import Menudetails from "../menu.js";
import logo from "../flix-logo.png";

import {
  Col,
  Row,
  Button,
  CustomInput,
  FormGroup,
  Input,
  Card,
  ListGroup, ListGroupItem,
  CardBody,
  Label
} from "reactstrap";
import { Link } from "react-router-dom";

import "./order.css";


import { services } from "./service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowForwardIcon from '@material-ui/icons/ChevronRight';

var CryptoJS = require("crypto-js");
const OrderDetails = ({ history }) => {


  const handleSubmit = (e) => {
    e.preventDefault();
  //  console.log("Submit");
  };

  const fetchOrderDetails = (id) => {
    history.push({
      pathname: "/order/details",
      state: { id: id }
    });
  }
  const [orderDetails, setOrderDetails] = useState([]);

  const [values, setValues] = useState({

  });
  const [isLoading, setIsLoading] = useState(true)

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    services.getOrderList().then(
      (response) => {

        if (response?.success == true) {
         // console.log("response.data", response.data)
          setOrderDetails(response.data)
          setIsLoading(false)
        } else {
          //console.log(" entered")
          setOrderDetails([])
          setIsLoading(false)
        }
      },
      (error) => {
       // console.log("data error", error)

      }
    );
  }, [values.id]);
  const hangleChange = (value, name) => {
   // console.log("value", value, "name", name)
    setValues({
      ...values,
      [name]: value,
    });
  };

  const sample = (id) => {
   // console.log("enter", id)
    history.push({
      pathname: "/order/details",
      state: { id: id }
    });
  };




  return (
    <form className="order_list" >
      <div className="order_details" >

        <Row style={{ marginTop: '10px' }}>
          <Col xs={6}>
            <h2 style={{ marginLeft: '6%', marginTop: '5%', fontSize: '2.5rem', marginBottom: '10px' }} className="order_hr order_mobile "><b>My Orders</b></h2>
          </Col>
          <Col xs={4}>

            <img src={logo} alt="Girl in a jacket" className="logo_align" style={{ height: "50", display: 'block', maxWidth: '70%', maxHeight: '80%', marginTop: '10px' }} />
          </Col>

        </Row >

      </div>

      <div >
        <Row>
          <div className="order_web" >

            {orderDetails.length > 0 && <h1 style={{ marginTop: '40px', marginLeft: '0px' }} className="order_hr"><b>My Orders</b></h1>}
            {orderDetails &&
              orderDetails.length > 0 ?
              orderDetails.map((data, index) => {
                return (
                  <>
                    <Card style={{ marginRight: '30%', marginBottom: '1%' }}>
                      <CardBody>
                        <Row >
                          <Col xs={11}>



                            {data.line_items &&
                              data.line_items.length > 0 &&
                              data.line_items.map((element, index) => {
                                return (
                                  <>   <Row >
                                    <Col xs={2} className="mousechange" onClick={() => { sample(data.id) }}>
                                      <img src={element.image} alt="Girl in a jacket" style={{ maxWidth: '100px', maxHeight: '150px' }} />

                                    </Col>
                                    <Col xs={5}>
                                      <label style={{ fontSize: 'medium' }}>
                                        {element.title}</label>

                                      <ul style={{ listStyleType: 'none', marginLeft: '-10px' }}>
                                        <li>Variant  : {element.variant_title}</li>
                                        <li>Seller : {element.vendor}</li>
                                        <li>Quantity  : {element.quantity}</li>

                                      </ul>
                                    </Col>
                                    <Col lg={1}>
                                      <label style={{ fontSize: 'medium' }}> {element.price}</label>

                                    </Col>
                                    <Col lg={4}>
                                      <ul style={{ marginLeft: '10px' }}>
                                        <li style={{ fontSize: 'medium' }}>{"Ordered  on " + months[parseInt(data.created_at.split("T")[0].split("-")[1]) - 1] + " " + data.created_at.split("T")[0].split("-")[2] + "," + data.created_at.split("T")[0].split("-")[0]}</li>
                                        {/*   <li style={{listStyleType: 'none',marginLeft: '-10px'}}>Your item has delivered</li> */}
                                        <li style={{ listStyleType: 'none' }}>Order : {data.name}</li>
                                      </ul>
                                    </Col>

                                  </Row>  </>
                                );
                              })}
                          </Col>
                          <Col xs={1} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                            <ArrowForwardIcon style={{ width: '2em', height: '1.5em', marginLeft: '30%' }} onClick={() => {
                              fetchOrderDetails(data.id);
                            }} />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>   </>
                );
              }) : <p className="no_data" >
                <br>
                </br>
                <h1><span ><strong style={{ color: 'white' }}>No Orders Available! </strong></span></h1></p>}
          </div>
        </Row>
      </div >
      <div >
        {isLoading ? <div id="app" class="loader" style={{ position: 'fixed',top: '50%',left: '50%',marginTop: '-50px',marginLeft: '-50px'}}></div>: <div className="order_mobile" >


          {orderDetails &&
            orderDetails.length > 0 ?
            orderDetails.map((data, index) => {
              return (
                <>

                  <Card className="card_style" >
                    <CardBody>

                      <Row >
                        <Col xs={10}>

                          {data.line_items &&
                            data.line_items.length > 0 &&
                            data.line_items.map((element, index) => {
                              return (
                                <>      <Row >
                                  <Col xs={3}>
                                    <img src={element.image} alt="Girl in a jacket" style={{ maxWidth: '80px', maxHeight: '80px' }} />

                                  </Col>
                                  <Col xs={9}>
                                    <label style={{ fontSize: 'small', marginLeft: '18px', fontWeight: 'bold' }}>
                                      {data.delivery_message}</label>

                                    <ul style={{ listStyleType: 'none' }}>
                                      {/* <li>Variant  : {element.variant_title}</li>
      <li>Seller : {element.vendor}</li>
      <li>Quantity  : {element.quantity}</li> */}
                                      <li style={{ marginTop: '10px' }} > {element.title} </li>
                                    </ul>
                                  </Col>

                                </Row>
                                </>
                              );
                            })}
                        </Col>
                        <Col xs={1} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                          <ArrowForwardIcon style={{ width: '2em', height: '1.5em', marginLeft: '30%' }} onClick={() => {
                            fetchOrderDetails(data.id);
                          }} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </>
              );
            }) : <p>
              <br>
              </br>
              <div>
            <label style={{fontSize:'medium',marginLeft: '10%'}}> No orders found</label>
            </div></p>}


        </div>}
      </div >
    </form>
  );
};

export default OrderDetails;
