import React, { useEffect, useState } from "react";
import axios from "axios";
import {services} from '../Service/service' 

import { useShopify } from "../hooks";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
const fpPromise = FingerprintJS.load();







function RedirectRoot() {
  var url_string = window.location.href;
  var url = new URL(url_string);
  var productUrl = url.searchParams.get("url");
  
  useEffect(() => {
    setTimeout(() => {
      window.location.href = productUrl;
    }, 500);
  }, [])


  return <div id="redirect" style={{ fontSize: "2rem" }}></div>;
}

export default RedirectRoot;
