import React, { Fragment, useState, useEffect } from "react";
import "../Login/style.css";
import Image from "../../assets/delivery.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import edit from "../../assets/edit.svg";
import { useShopify } from "../../hooks";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Button,
  CustomInput,
  FormGroup,
  Input,
  Card,
  Spinner
} from "reactstrap";
import { Link } from "react-router-dom";

import "./payment.css";
import "./spreedly.css";
import Menudetails from "../menu.js";

import { services } from "./service";
// import ApplePay from "./ApplePay";
import {
  PaymentRequestButtonElement,
  PaymentElement
} from "@stripe/react-stripe-js";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Stripe from "./Stripe";
import StripePaymentStatus from "./StripePaymentStatus";
let stripePromise ;
// loadStripe('pk_test_51Hn9UMFkgI4DLRDUgGco6HkS2onWNKtIpUYMG4beza7ze82gslIsPAKycBdHxUyyFgcPbNYjLhcLiaVf7orahIT500bYmsa6hV');

var CryptoJS = require("crypto-js");
const Login = ({ history }) => {
  const { addAddress } = useShopify();

  // var shipping_address = services.getCookie("shipping_address");
  var billing_address = services.getCookie("billing_address");
  var billing_shipping_same = services.getCookie("billing_shipping_same");
  // var checkoutDetail = services.getCookie("checkoutDetails");

  // shipping_address = shipping_address ? JSON.parse(shipping_address) : null;
  // checkoutDetail = checkoutDetail ? JSON.parse(checkoutDetail) : null;
  const [checkoutDetails, setCheckoutDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [paymentButton, setPaymentButton] = useState(false);
  const [paymentButtonLabel, setPaymentButtonLabel] = useState("Pay");
  const [eye1, setEye1] = useState(<FontAwesomeIcon icon={faEye} />);
  const [passwordShown, setPasswordShown] = useState(false);


  const [disable, setDisable] = useState(true);
  const [count, setcount] = useState(0);
  const [oneTime, setoneTime] = useState(false);

  const [loader, setLoader] = useState(true);

  // var first_name = shipping_address
  //   ? shipping_address.firstname + " " + shipping_address.lastname
  //   : null;
  // var shippingAddress = shipping_address
  //   ? shipping_address.firstname +
  //     " " +
  //     shipping_address.lastname +
  //     ", " +
  //     shipping_address.address1 +
  //     ", " +
  //     shipping_address.address2 +
  //     ", " +
  //     shipping_address.city +
  //     ", " +
  //     shipping_address.state +
  //     ", " +
  //     shipping_address.zip +
  //     ", " +
  //     shipping_address.country +
  //     ", +" +
  //     shipping_address.phone
  //   : null;
  // var email = shipping_address ? shipping_address.user_email : null;

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const toggleCvv = (e) => {
    setPasswordShown(e)
     setEye1(
      passwordShown ? (
        <FontAwesomeIcon icon={faEye} />
      ) : (
        <FontAwesomeIcon icon={faEyeSlash} />
      )
    );
  window.Spreedly.toggleMask();
  };
  const [order, setOrder] = useState([]);
  const [bat, setBat] = useState(false);
  const [savePopup, setSavePopup] = useState({});
  const [saveCard, setSaveCard] = useState(true);
  const [values, setValues] = useState({
    // contact: email,
    // ship_to: shippingAddress,
    method: "International Shipping · $18.00",
    saveCard: true,
    // shipping_address:shipping_address
  });
  const [error, setError] = useState({});
  const [cardError, setCardError] = useState({});
  // const stripe = useStripe();
  // const elements = useElements();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [clientSecret,setClientSecret]= useState("");
  const [stripeOptions,setStripeOptions] =useState()
  const [publishableKey,setPublishableKey]= useState("");


  useEffect(() => {

  services.authenticate().then(
      (response) => {
 if(response?.status == 200){
      //  console.log("response token",response?.data?.token)
    localStorage.setItem("access-token",response?.data?.token)

  services.spreedlyKey().then(
      (response) => {
    if(response?.data?.data){
        var token = CryptoJS.AES.decrypt(
              response.data.data.access_key,
              process.env.REACT_APP_SPREEDLY_SECRET
            ).toString(CryptoJS.enc.Utf8);
        
    const script = document.createElement("script");
    const script1 = document.createElement("script");

    script.src = "https://core.spreedly.com/iframe/iframe-v1.min.js";


    script.id = "spreedly-iframe";
   // script["data-environment-key"] = "TbWAYgG7qaagGBh7hPjXQxS4DM4";
    script["data-number-id"] = "spreedly-number-test";
    script["data-cvsv-id"] = "spreedly-cvv-test";
    document.body.appendChild(script);
    script.onload = () => {
      window.Spreedly.init(token, {
        numberEl: "spreedly-number-test",
        cvvEl: "spreedly-cvv-test",
      });
      window.Spreedly.on("ready", function (frame) {
        window.Spreedly.setFieldType("number", "text");
        window.Spreedly.setFieldType("cvv", "text");
        window.Spreedly.setNumberFormat("maskedFormat");
        window.Spreedly.setPlaceholder("number", "Card Number");
        window.Spreedly.setPlaceholder("cvv", "CVV");

        window.Spreedly.setStyle(
          "number",
          "width: 87%; border-radius: 3px;  display: inline-block;border: thin solid #fafafa;border-bottom: solid medium #DDDADA;color: #444;background-color: #fafafa;padding: 10px 10px 10px 10px;border-top-left-radius: 10px;border-top-right-radius: 10px;font-size: medium;"
        );
        window.Spreedly.setStyle(
          "cvv",
          "width: 68%; border-radius: 3px; display: inline-block;border: thin solid #fafafa;border-bottom: solid medium #DDDADA;color: #444;background-color: #fafafa;padding: 10px 10px 10px 10px;border-top-left-radius: 10px;border-top-right-radius: 10px;font-size: medium;"
        );
      });
      // document.getElementById('first_name').value = shipping_address ?.firstname ;
      // document.getElementById('last_name').value = shipping_address ?.lastname ;

      window.Spreedly.on("errors", function (errors) {
        var messageEl = document.getElementById("errors");
        var errorBorder = "1px solid red";
        for (var i = 0; i < errors.length; i++) {
          var error = errors[i];
          if (error["attribute"]) {
            var masterFormElement = document.getElementById(error["attribute"]);
            if (masterFormElement) {
              masterFormElement.style.border = errorBorder;
            } else {
              window.Spreedly.setStyle(
                error["attribute"],
                "border: " + errorBorder + ";"
              );
            }
          }
          messageEl.innerHTML += error["message"] + "<br/>";
        }
      });
      window.Spreedly.on(
        "fieldEvent",
        function (name, event, activeElement, inputData) {
          var errors = {};
          if (event == "input") {
            if (inputData["validCvv"]) {
              //    window.Spreedly.setStyle('cvv', "background-color: #CDFFE6;")
            } else {
              // window.Spreedly.setStyle('cvv', "background-color: #f07122;")
              errors.cvv = "Invalid Cvv";
            }
            if (inputData["validNumber"]) {
              //  window.Spreedly.setStyle('number', "background-color: #CDFFE6;")
            } else {
              //  window.Spreedly.setStyle('number', "background-color: #f07122;")
              errors.number = "Invalid Number";
            }
            setCardError(errors);
          }
        }
      );
    };
  }
   },
      (error) => {
        return false;
      }
    );

    services.getCardList().then(
      (response) => {
        var cardList = [];
        response.data &&
          response.data.map((item, index) => {
            let rowItem = {};

            // var token = CryptoJS.AES.decrypt(
            //   item.card_number,
            //   process.env.REACT_APP_SPREEDLY_SECRET
            // ).toString(CryptoJS.enc.Utf8);
            // var tok = CryptoJS.AES.decrypt(
            //   item.spreedly_token,
            //   process.env.REACT_APP_SPREEDLY_SECRET
            // ).toString(CryptoJS.enc.Utf8);
            rowItem.card_number = item.last4digits;
            rowItem.card_type =
            item.card_type ? item.card_type[0].toUpperCase() + item.card_type.slice(1) : null;
            rowItem.card_id = item.card_id ? item.card_id :null;
            cardList.push(rowItem);
          });
        setOrder(cardList);
        setValues({
          ...values,
          ["card"]: 0,
        });
      },
      (error) => {
        return false;
      }
    );

    fetchCardDetails();

//     let amount;
//     let currency_code;
//     console.log("AMOUNT initial",amount,currency_code)
//     services.checkoutCheck().then(
//       (res) => {
//         if (res?.status == 202) {
//           history.push({ pathname: "/login" });
//         } else if (res?.status == 203) {
//           history.push({
//             pathname: "/shippinginfo",
//           });
//         } else if (res?.status == 200) {
//           console.log("res.data.address",res.data.address)
// const concatObject = (obj, separator) =>
//     Object.values(obj)
//         .filter((val) => val)
//         .join(separator);


// let myAddress = {
//     firstname: res.data.address.firstname+" "+res.data.address.lastname,
//     address1: res.data.address.address1,
//     address2: res.data.address.address2,
//     city: res.data.address.city,
//     state:res.data.address.state,
//     zip:res.data.address.zip,
//     country:res.data.address.country,
//     phone:res.data.address.phone
// }

// // var addr = concatObject(myAddress, ', ')
// //           console.log("myAddress",addr)

//           var first_name = res.data.address
//             ? res.data.address.firstname + " " + res.data.address.lastname
//             : null;
//           // var shippingAddress = res.data.address
//           //   ? res.data.address.firstname +
//           //   " " +
//           //   res.data.address.lastname +
//           //   ", " +
//           //   res.data.address.address1?res.data.address.address1:"" +
//           //   ", " +
//           //   res.data.address.address2?res.data.address.address2:"" +
//           //   ", " +
//           //   res.data.address.city +
//           //   ", " +
//           //   res.data.address.state +
//           //   ", " +
//           //   res.data.address.zip +
//           //   ", " +
//           //   res.data.address.country +
//           //   ", +" +
//           //   res.data.address.phone
//           //   : null;
//           var shippingAddress = res.data.address
//             ? concatObject(myAddress, ', '): null;
//           var email = res.data.address ? res.data.address.user_email : null;
//           if(res.data.data.payment_method){
//             document.getElementById("first_name").value = res.data.address.firstname;
//             document.getElementById("last_name").value = res.data.address.lastname;
//           }
//           let payment_method = res.data.payment_method;
//           amount = res.data.data.totalPrice.substring(1);
//           currency_code = res.data.data.lineItems[0].currencyCode;
//           console.log("INSIDE",amount,currency_code)
//           var checkoutDetails = {
//             subtotalPrice: res.data.data.lineItemsSubtotalPrice,
//             totalShippingPrice: res.data.data.totalShippingPrice,
//             totalTax: res.data.data.totalTax,
//             totalPrice: res.data.data.totalPrice,
//             merchant_name: res.data.data.lineItems[0].merchant.merchant_name,
//             currency_code: res.data.data.lineItems[0].currencyCode,
//             TotalPrice: res.data.data.totalPrice.substring(1),
//             payment_method
//           };
//           services.setCookie("checkoutDetails", JSON.stringify(checkoutDetails));
//           setCheckoutDetails(checkoutDetails);
//           setValues({
//             ...values,
//             contact: email,
//             ship_to: shippingAddress,
//             shipping_address: shippingAddress,
//             ["card"]: 0,
//             payment_method
//             // checkoutDetails:checkoutDetails
//           });
//           setIsLoading(false);

//         }
//       },
//       (error) => { }
//     );

//     console.log("AMOUNT final",amount,currency_code)
//     // services.createPaymentIntent()
//     // .then(data=>{
//     //   console.log("resposne")
//     // })
//     // .catch(error=>{
//     //   console.log("ERROR",error)
//     // })
  }
  },
      (error) => {
        return false;
      }
    );
  }, []);



  useEffect(() => {

  }, [])
  useEffect(() => {


    if (values.shipping_address && localStorage.getItem("CID")) {
      var shipping_address = {
        firstname: values.shipping_address.firstname,
        lastname: values.shipping_address.lastname,
        address1: values.shipping_address.address1,
        address2: values.shipping_address.address2,
        city: values.shipping_address.city,
        state: values.shipping_address.state,
        zip: values.shipping_address.zip,
        country: values.shipping_address.country,
        phone: values.shipping_address.phone,
        user_email: values.contact,
      };
      // addAddress(
      //             localStorage.getItem("CID"),
      //             shipping_address
      //           ).then(async (res) => {
      //             if(res.addresResponse.shippingAddress == null && res.emailReponse.email == null){
      //               setBat(true)
      //             }

      //           });
    }
  }, [values.shipping_address]);


  // useEffect(() => {
  //   if(clientSecret.length>0){

  //   }
  // }, [clientSecret])
  


  // useEffect(() => {
  //   // let isIOS = (/iPad|iPhone|iPod/.test(window.navigator.platform) ||(window.navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&!window.MSStream
  //   // const isIOS = typeof navigator.standalone === 'boolean';
  //   var isIOS =
  //     /constructor/i.test(window.HTMLElement) ||
  //     (function (p) {
  //       return p.toString() === "[object SafariRemoteNotification]";
  //     })(
  //       !window["safari"] ||
  //       (typeof window["safari"] !== "undefined" &&
  //         window["safari"].pushNotification)
  //     );

  //   // alert("isIOS",isIOS)
  //   //  if(isIOS){
  //   if (!stripe || !elements) {
  //     // alert("exist case of no stripe and elements")
  //     return;
  //   }


  //   // creating an instance of stripe.paymentRequest with all required options.
  //   // requestPayerName: true,
  //   const pr = stripe.paymentRequest({
  //     country: "US",
  //     currency: "usd",
  //     total: {
  //       label: "Demo total",
  //       amount: 1,
  //     },
  //     requestPayerName: false,
  //     requestPayerEmail: true,
  //   });
  //   // alert("payment-request generated");

  //   //  setPaymentRequest(pr);
  //   // Check the availability of the Payment Request API.
  //   pr.canMakePayment().then((result) => {

  //     if (result) {
  //       // alert("result from payment request API",result);
  //       // alert("setting flag for apple-pay")
  //       setPaymentRequest(pr);
  //     }
  //     else {
  //       // alert("apple-pay button is not set since no result from payment request API")
  //     }
  //   });

  //   // Listen to the paymentmethod event to receive a PaymentMethod object. Pass the PaymentMethod ID and the PaymentIntent’s client secret to stripe.confirmCardPayment to complete the payment.
  //   pr.on('paymentmethod', async (e) => {
  //     const { backendError, clientSecret } = await fetch(
  //       "https://staging.poppo.tv/api/v1/ecommerce/createPaymentIntent",
  //       {
  //         method: 'POST',
  //         body: JSON.stringify({
  //           paymentMethodType: 'card',
  //           currency: 'usd',
  //           amount: 1
  //         }),
  //         headers: {
  //           'Content-type': 'application/json; charset=UTF-8'
  //         }
  //       }
  //     ).then((r) => {
  //       // alert("payment-method response",r)
  //       return r.json();
  //     }

  //     ).catch(e => {
  //       // alert("error in payment",e.message)
  //     })
  //     // alert("client-secret",clientSecret)

  //     if (backendError) {
  //       // alert("paymentIntent -error",backendError.message);
  //       return;
  //     }

  //     // alert("Client secret returned",clientSecret);

  //     const { stripeError, paymentIntent } =
  //       await stripe.confirmCardPayment(
  //         clientSecret,
  //         {
  //           payment_method: e.paymentMethod.id,
  //         },
  //         { handleActions: false }
  //       );

  //     if (stripeError) {
  //       // Show error to your customer (e.g., insufficient funds)
  //       // alert(stripeError.message);
  //       e.complete('fail')

  //       return;
  //     }

  //     e.complete('success');
  //     if (paymentIntent.status == 'requires_action') {
  //       stripe.confirmCardPayment(clientSecret);
  //     }

  //     // Show a success message to your customer
  //     // There's a risk of the customer closing the window before callback
  //     // execution. Set up a webhook or plugin to listen for the
  //     // payment_intent.succeeded event that handles any business critical
  //     // post-payment actions.
  //     // alert(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
  //   });
  //   // }
  // }, [stripe, elements]);

  const fetchCardDetails=async ()=>{
    let amount;
    let currency_code;
    await services.checkoutCheck().then(
      (res) => {
        if (res?.status == 202) {
          history.push({ pathname: "/login" });
        } else if (res?.status == 203) {
          history.push({
            pathname: "/shippinginfo",
          });
        } else if (res?.status == 200) {
          console.log("res.data.address",res.data.address)
const concatObject = (obj, separator) =>
    Object.values(obj)
        .filter((val) => val)
        .join(separator);


let myAddress = {
    firstname: res.data.address.firstname+" "+res.data.address.lastname,
    address1: res.data.address.address1,
    address2: res.data.address.address2,
    city: res.data.address.city,
    state:res.data.address.state,
    zip:res.data.address.zip,
    country:res.data.address.country,
    phone:res.data.address.phone
}

// var addr = concatObject(myAddress, ', ')
//           console.log("myAddress",addr)

          var first_name = res.data.address
            ? res.data.address.firstname + " " + res.data.address.lastname
            : null;
          // var shippingAddress = res.data.address
          //   ? res.data.address.firstname +
          //   " " +
          //   res.data.address.lastname +
          //   ", " +
          //   res.data.address.address1?res.data.address.address1:"" +
          //   ", " +
          //   res.data.address.address2?res.data.address.address2:"" +
          //   ", " +
          //   res.data.address.city +
          //   ", " +
          //   res.data.address.state +
          //   ", " +
          //   res.data.address.zip +
          //   ", " +
          //   res.data.address.country +
          //   ", +" +
          //   res.data.address.phone
          //   : null;
          var shippingAddress = res.data.address
            ? concatObject(myAddress, ', '): null;
          var email = res.data.address ? res.data.address.user_email : null;
          if(res.data.data.payment_method){
            document.getElementById("first_name").value = res.data.address.firstname;
            document.getElementById("last_name").value = res.data.address.lastname;
          }
          let payment_method = res.data.payment_method;
          amount = res.data.data.totalPrice.substring(1);
          currency_code = res.data.data.lineItems[0].currencyCode;
          console.log("INSIDE",amount,currency_code)
          var checkoutDetails = {
            subtotalPrice: res.data.data.lineItemsSubtotalPrice,
            totalShippingPrice: res.data.data.totalShippingPrice,
            totalTax: res.data.data.totalTax,
            totalPrice: res.data.data.totalPrice,
            merchant_name: res.data.data.lineItems[0].merchant.merchant_name,
            currency_code: res.data.data.lineItems[0].currencyCode,
            TotalPrice: res.data.data.totalPrice.substring(1),
            payment_method
          };
          services.setCookie("checkoutDetails", JSON.stringify(checkoutDetails));
          setCheckoutDetails(checkoutDetails);
          setValues({
            ...values,
            contact: email,
            ship_to: shippingAddress,
            shipping_address: shippingAddress,
            ["card"]: 0,
            payment_method
            // checkoutDetails:checkoutDetails
          });
          // setIsLoading(false);

        }
      },
      (error) => { }
    );

    if(!new URLSearchParams(window.location.search).get('payment_intent_client_secret')){
    await services.createPaymentIntent(amount,currency_code)
    .then(data=>{
      let {client_secret,publishable_key} = data;
      localStorage.setItem("client_secret",client_secret,"publishable_key",publishable_key)
      setClientSecret(client_secret);
      setPublishableKey(publishable_key)
      stripePromise = loadStripe(publishable_key);
      setStripeOptions({clientSecret:client_secret})
            setIsLoading(false);
    })
    .catch(error=>{
      toast.error('Failed to create payment intent')
    })
   }
   else{
    localStorage.getItem("client_secret")
    setStripeOptions({clientSecret:client_secret})
    setIsLoading(false);
   }
  }
  const hangleChange = (value, name) => {
    setValues({
      ...values,
      [name]: value,
    });
  };
  const RepeatedPayment = (card_id) => {
    var billing_address = services.getCookie("billing_address");
    if (billing_address) {
      billing_address = JSON.parse(billing_address);
      billing_address.province = billing_address.state;
      billing_address.firstName = billing_address.firstname;
      billing_address.lastName = billing_address.lastname;
      billing_address.phone = "+" + billing_address.phone;

      delete billing_address["firstname"];
      delete billing_address["lastname"];
      delete billing_address["state"];
      delete billing_address["user_email"];
    }
    let data = {
      cart_id: localStorage.getItem("CID"),
      billing_address: billing_address,
      card_id: card_id,
      amount: parseFloat(checkoutDetails.TotalPrice),
      currencyCode: checkoutDetails.currencyCode,
      save_card: false,
    };
    setIsLoading(true);

    services.payment(data).then(
      (response) => {
        if (response?.status == 200) {
          //localStorage.removeItem("CID");
             if(response?.data?.success == false){

                setIsLoading(false);
                setPaymentButtonLabel("Pay");
                var message = response.data.message;
                toast.error(message);
              }else{
                history.push({
                  pathname: "/payment/success",
                });
              }
          // history.push({
          //   pathname: "/payment/success",
          // });
        } else {
          setIsLoading(false);
          setPaymentButtonLabel("Pay");
          toast.error(`Something Went Wrong`, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      },
      (error) => {
        setIsLoading(false);
          setPaymentButtonLabel("Pay");
          toast.error(`Something Went Wrong`, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
       // return false;
      }
    );
  };
  const sample = () => { };

  const closePopup = (value) => {
    setBat(false);

    history.push({
      pathname: "/shippinginfo",
    });
  };



  const closePopupWithoutSave = (value) => {
    setBat(false);
  };
  const Orders = () => {
    window.location.href = "/order/list";
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const applyDiscount = () => {
    services.applyDiscount(values.discount).then(
      (response) => {

        checkoutDetails.currencyCode = response.data.data.currencyCode;
        checkoutDetails.subtotalPrice = response.data.data.subtotalPrice;
        checkoutDetails.totalPrice = response.data.data.totalPrice;
        checkoutDetails.totalTax = response.data.data.totalTax;
        setCheckoutDetails({
          ...checkoutDetails,
        });
        toast.success("Discount applied  successfully");

        services.setCookie("checkoutDetails", JSON.stringify(checkoutDetails));

      },
      (error) => {
        return false;
      }
    );
  };



  const validate = () => {
    let formValid = true;
    let formErrors = {};
    var first_name = document.getElementById("first_name").value;
    var last_name = document.getElementById("last_name").value;
    var month = document.getElementById("month").value;
    var year = document.getElementById("year").value;
    var d = new Date();

  
    if (!first_name) {
      formErrors.first_name = "Required";
      formValid = false;
    }
    if (!last_name) {
      formErrors.last_name = "Required";
      formValid = false;
    }
    if (!month) {
      formErrors.expire = "Required";
      formValid = false;
    } else {
      if (parseInt(month, 10) < d.getMonth() + 1 && year < d.getFullYear()) {

        formErrors.expire = "Invalid Date";
        formValid = false;
      }
    }
    if (!year) {
      formErrors.expire = "Required";
      formValid = false;
    } else {
      if (year < d.getFullYear()) {

        formErrors.expire = "Invalid Date";
        formValid = false;
      }
    }
    if (Object.keys(cardError).length > 0) {
      if (cardError.cvv) {
        formErrors.cvv = "Invalid Cvv";
      }
      if (cardError.number) {
        formErrors.number = "Invalid Number";
      }
      formValid = false;
    }
    setError(formErrors);
    return formValid;
  };
  function submitPaymentForm() {
    var normalBorder = "1px solid #ccc";
    var numberParent = document.getElementById("spreedly-number-test").value;

    if (validate()) {
      // These are the fields whose values we want to transfer *from* the
      // master form *to* the payment frame form. Add the following if
      // you're displaying the address:
      // ['address1', 'address2', 'city', 'state', 'zip', 'country']
      var paymentMethodFields = ["first_name", "last_name", "month", "year"];
      var options = {};
      for (var i = 0; i < paymentMethodFields.length; i++) {
        var field = paymentMethodFields[i];

        // Reset existing styles (to clear previous errors)
        var fieldEl = document.getElementById(field);
        fieldEl.style.border = normalBorder;

        // add value to options
        options[field] = fieldEl.value;
      }

      // Reset frame styles
      window.Spreedly.setStyle("number", "border: " + normalBorder + ";");
      window.Spreedly.setStyle("cvv", "border: " + normalBorder + ";");

      // Reset previous messages
      document.getElementById("errors").innerHTML = "";
      document.getElementById("message").innerHTML = "";

      // Tokenize!
      window.Spreedly.tokenizeCreditCard(options);
      window.Spreedly.on("paymentMethod", function (token, pmData) {
        var ciphertext = CryptoJS.AES.encrypt(
          token,
          process.env.REACT_APP_SPREEDLY_SECRET
        ).toString();
        var billing_address = services.getCookie("billing_address");
        if (billing_address) {
          billing_address = JSON.parse(billing_address);
          billing_address.province = billing_address.state;
          billing_address.firstName = billing_address.firstname;
          billing_address.lastName = billing_address.lastname;
          billing_address.phone = "+" + billing_address.phone;

          delete billing_address["firstname"];
          delete billing_address["lastname"];
          delete billing_address["state"];
          delete billing_address["user_email"];
        }
        let data = {
          cart_id: localStorage.getItem("CID"),
          billing_address: billing_address,
          token: ciphertext,
          amount: parseFloat(checkoutDetails.totalPrice),
          currencyCode: checkoutDetails.currencyCode,
        };
        if (values.saveCard) {
          data.save_card = true;
        } else {
          data.save_card = false;
        }
        setIsLoading(true);
        setPaymentButtonLabel("Processing...");
        services.payment(data).then(
          (response) => {
            if (response?.status == 200) {
              //   localStorage.removeItem("CID");

              // var r = window.confirm("Save card details for future use ?");
              // let paymentSave = {
              //   card_number: response.data.data.encrypted_card_last_four_digits,
              //   card_type: response.data.data.card_type,
              //   token: response.data.data.encrypted_token,
              // };
              // if(values.saveCard){

              //   services.cardDetailsSave(paymentSave).then(
              //      (response) => {

              //      history.push({
              //        pathname: "/payment/success",
              //       });
              //      },
              //     (error) => {
              //          toast.error(`Something Went Wrong`, {
              //            position: toast.POSITION.BOTTOM_LEFT,
              //          });
              //        }
              //    );
              // }else{
              if(response?.data?.success == false){
                setIsLoading(false);
                setPaymentButtonLabel("Pay");
                var message = response.data.message;
                toast.error(message);
              }else{
                history.push({
                  pathname: "/payment/success",
                });
              }

              
              //  }
            }else {
               setIsLoading(false);
               setPaymentButtonLabel("Pay");
              var message = response.data.message;
              toast.error(message, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            }
          },
          (error) => {
            setIsLoading(false);
            setPaymentButtonLabel("Pay");

            return false;
          }
        );

        // var tokenField = document.getElementById("payment_method_token");
        // tokenField.setAttribute("value", token);
        // var masterForm = document.getElementById('payment-form');

        // var messageEl = document.getElementById('message');
        // messageEl.innerHTML = "Success! The returned payment method token is: " + token;
      });
    }
    return false;
  }
  const [shipBillSame, setShipBillSame] = useState(true);

  function submitStripePaymentForm() {
    setIsLoading(true);
    setPaymentButtonLabel("Processing...");
    // stripe session api
    let data = {amount: parseFloat(checkoutDetails.totalPrice.substring(1))};
    setIsLoading(true);
    setPaymentButtonLabel("Processing...");
    services.stripeSessions(data).then(
      (response) => {
        if (response?.status == 200) {
          // redirect to the url in response
          if(response.data.url){
          window.location.href = response.data.url;
          }else{
            toast.error("No stripe sesssion url")
          }
        }
      },
      (error) => {
        setIsLoading(false);
        setPaymentButtonLabel("Pay");
        return false;
      }
    );
return false;
}
// for stripe payment intent
const client_secret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
  return (



    <Fragment>
      {stripeOptions &&
      <Elements stripe={stripePromise} options={stripeOptions}> 
      {client_secret ? (
        <StripePaymentStatus/>
      ):(
      <form className="shipping" onSubmit={handleSubmit}>
        <div className="payment_heading">
          <Menudetails
            handleClick={handleClick}
            anchorEl={anchorEl}
            handleClose={handleClose}
            Orders={Orders}
          />
          <div className="addressCard">
            <div className="address_section">
              <div className="payment_heading" style={{ marginBottom: "auto" }}>
                {" "}
                Shipping Details{" "}
              </div>{" "}
              <div className="row">
                <div className="ship_bill">
                  {" "}
                  <b> Contact </b>{" "}
                  {/*  <Button className="checkoutButton" style={{border: 'none',marginTop:'-5px',fontSize: 'small',marginLeft: '55%',backgroundColor: '#979da5'}}>
                                           <FontAwesomeIcon icon="edit" />
                                  </Button> */}{" "}
                  <Link
                    to={{
                      pathname: "/shippinginfo",
                    }}
                  >
                    <span
                      style={{
                        padding: "2px",
                        marginTop: "-5px",
                        marginLeft: "55%",
                      }}
                    >
                      <img
                        src={edit}
                        style={{
                          width: "25px",
                          marginLeft: "80%",
                          marginTop: "-25px",
                        }}
                      />{" "}
                      {/* <FontAwesomeIcon icon="edit" />*/}{" "}
                    </span>{" "}
                  </Link>{" "}
                </div>

                <div className="col col-sm-12 feilds">
                  <label
                    style={{
                      fontSize: "1.5rem",
                    }}
                  >
                    {" "}
                    {values.contact}{" "}
                  </label>
                </div>
              </div>{" "}
              <hr />
              <div className="row">
                <div className="ship_bill">
                  {" "}
                  <b> Shipping Address </b>{" "}
                  <Link
                    to={{
                      pathname: "/shippinginfo",
                    }}
                  >
                    <span
                      style={{
                        padding: "2px",
                        marginTop: "-5px",
                        marginLeft: "55%",
                      }}
                    >
                      <img
                        src={edit}
                        style={{
                          width: "25px",
                          marginLeft: "80%",
                          marginTop: "-25px",
                        }}
                      />{" "}
                      {/* <FontAwesomeIcon icon="edit" />*/}{" "}
                    </span>{" "}
                  </Link>{" "}
                </div>
                <div className="col col-sm-12 feilds">
                  <label
                    style={{
                      fontSize: "1.5rem",
                    }}
                  >
                    {" "}
                    {values.ship_to}{" "}
                  </label>
                </div>{" "}
                <div className="payment_heading" style={{ marginBottom: "auto" }}>
                  {" "}
                  Discount{" "}
                </div>{" "}
                <Row style={{ marginTop: "10px", marginBottom: "-20px" }}>
                  <div className="spf-field spf-field-discount">
                    <Input
                      type="text"
                      className="spf-input-text"
                      onChange={({ target }) => {
                        hangleChange(target.value, "discount");
                      }}
                      placeholder="Discount Code"
                      style={{
                        display: "inline-block",
                        border: "thin solid #fafafa",
                        borderBottom: "solid medium #DDDADA",
                        color: "#444",
                        backgroundColor: "#fafafa",
                        padding: " 10px 10px 10px 10px",
                        borderTopTeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </div>

                  <div className="spf-field spf-field-discount-arrow mousechange">
                    <ArrowForwardIcon
                      style={{ width: "2em", height: "3em" }}
                      onClick={() => {
                        applyDiscount();
                      }}
                    />
                  </div>
                </Row>

                {values?.payment_method=='spreedly' &&
                <>
                <div className="payment_heading" style={{ marginBottom: "auto" }}>
                  {" "}
                  Payment{" "}
                </div>{" "}
                </>
                }

                {order &&
                  order.map((data, index) => (
                    <Card
                      body
                      style={{
                        borderTop: "none",
                      }}
                    >
                      <CustomInput
                        type="radio"
                        name="type"
                        id="track"
                        value={data.card_number}
                        checked={index === values.card}
                        style={{
                          marginTop: "10px",
                        }}
                        label={
                          <Fragment>
                            {" "}
                            <span
                              style={{
                                fontSize: "small",
                              }}
                            >
                              {" "}
                              <b>
                                {" "}
                                &nbsp; {data.card_type}Card Ending With{" "}
                                {data.card_number}{" "}
                              </b>
                            </span>{" "}
                          </Fragment>
                        }
                        onChange={(value) => {
                          hangleChange(index, "card");
                        }}
                      />{" "}
                      <br />
                      {values.card == index ? (
                        <Button
                          style={{
                            marginLeft: "70%",
                            border: "none",
                            backgroundColor: "#316F9C",
                            height: "30px",
                            borderRadius: "5px",
                            width: "20%",
                            paddingTop: "2px",
                          }}
                          className="font_size button_margin_top"
                          onClick={() => {
                            RepeatedPayment(data.card_id);
                          }}
                        >
                          {" "}
                          Continue{" "}
                        </Button>
                      ) : null}{" "}
                    </Card>
                  ))}
                  {/* stripe payment */}
                   {values?.payment_method=='stripe' && <Stripe/> }
                   {/*<>
                  <form
                  id="payment-form"
                  acceptCharset="UTF-8"
                  className="spf-form"
                  method="POST"
                  onSubmit={submitStripePaymentForm}
                  >
                    <Input
                      type="submit"
                      className="new_payment"
                      value='Pay with new card'
                      disabled={paymentButton}
                    />
                    <div id="message"></div>
                    <div id="errors"></div>
                </form>
                </>} */}

                {/* <ApplePay /> */}
               {/* {paymentRequest && (
                  <PaymentRequestButtonElement options={{ paymentRequest }} />
                )} */} 
                {values?.payment_method=='spreedly' &&
                <>
                <form
                  id="payment-form"
                  acceptCharset="UTF-8"
                  className="spf-form"
                  method="POST"
                  onSubmit={submitPaymentForm}
                >
                  <Input
                    type="hidden"
                    name="payment_method_token"
                    id="payment_method_token"
                    value=""
                  />
                  <fieldset className="spf-fs-cc">
                    <div className="ship_bill" style={{ marginTop: "auto" }}>
                      {" "}
                      <b>New Card </b>
                    </div>{" "}
                    {/*  <fieldset className="spf-fs-name">
      <h2>Name</h2>*/}
                    <div className="spf-field spf-field-fname">
                      <Input
                        type="text"
                        className="spf-input-text"
                        id="first_name"
                        placeholder="First Name"
                        style={{
                          display: "inline-block",
                          border: "thin solid #fafafa",
                          borderBottom: "solid medium #DDDADA",
                          color: "#444",
                          backgroundColor: "#fafafa",
                          padding: " 10px 10px 10px 10px",
                          borderTopTeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      />
                    </div>
                    <div className="spf-field spf-field-lname">
                      <Input
                        type="text"
                        className="spf-input-text"
                        id="last_name"
                        placeholder="Last Name"
                        style={{
                          display: "inline-block",
                          border: "thin solid #fafafa",
                          borderBottom: "solid medium #DDDADA",
                          color: "#444",
                          backgroundColor: "#fafafa",
                          padding: " 10px 10px 10px 10px",
                          borderTopTeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      />
                    </div>
                    {/* </fieldset> */}
                    {error?.first_name && (
                      <div
                        className="spf-field spf-field-fname"
                        style={{ color: "red" }}
                      >
                        {" "}
                        {error?.first_name}{" "}
                      </div>
                    )}
                    {error?.last_name && (
                      <div
                        className="spf-field spf-field-lname"
                        style={{ color: "red" }}
                      >
                        {" "}
                        {error?.last_name}{" "}
                      </div>
                    )}
                    <div className="spf-field" style={{ marginBottom: "-30px",width: "80%" }}>
                      <div
                        id="spreedly-number-test"
                        className="spf-field-group spf-number spf-field-cc"
                      ></div>

                      <div
                      style={{marginLeft:"3%"}}
                        id="spreedly-cvv-test"
                        className="spf-field-group spf-verification_value spf-field-cc"
                      ></div>
<div style={{marginLeft: "110%",marginTop: "-25%"}}>
 <i 
                    className="eyeIcon"
                    onClick={() => {
                      toggleCvv(!passwordShown);
                    }}
                  >
                    {eye1}
                  </i>
</div>
                    </div>
               
                    {error?.number && (
                      <div
                        className="spf-field spf-field-fname"
                        style={{ color: "red" }}
                      >
                        {" "}
                        {error?.number}{" "}
                      </div>
                    )}
                    {error?.cvv && (
                      <div
                        className="spf-field spf-field-lname"
                        style={{ color: "red", marginLeft: "19%", width: "30%" }}
                      >
                        {" "}
                        {error?.cvv}{" "}
                      </div>
                    )}
                    <div className="spf-field spf-field-exp" style={{marginTop: "1rem"}}>
                      <div className="spf-field-group spf-month">
                        <Input
                          type="text"
                          className="spf-input-text spf-exp"
                          id="month"
                          size="3"
                          maxLength="2"
                          placeholder="MM"
                          pattern="0[1-9]|1[0-2]"
                          title="Month must be  two digit number and maximum value is 12"
                          style={{
                            display: "inline-block",
                            border: "thin solid #fafafa",
                            borderBottom: "solid medium #DDDADA",
                            color: "#444",
                            backgroundColor: "#fafafa",
                            padding: " 10px 10px 10px 10px",
                            borderTopTeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      </div>
                      <span className="spf-exp-divider">/</span>
                      <div className="spf-field-group spf-year">
                        <Input
                          type="text"
                          className="spf-input-text spf-exp"
                          id="year"
                          size="5"
                          maxLength="4"
                          placeholder="YYYY"
                          style={{
                            display: "inline-block",
                            border: "thin solid #fafafa",
                            borderBottom: "solid medium #DDDADA",
                            color: "#444",
                            backgroundColor: "#fafafa",
                            padding: " 10px 10px 10px 10px",
                            borderTopTeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      </div>
                    </div>
                    {error?.expire && (
                      <div
                        className="spf-field spf-field-exp"
                        style={{ color: "red" }}
                      >
                        {" "}
                        {error?.expire}{" "}
                      </div>
                    )}
                    <div className="checkboxField">
                      <input
                        type="checkbox"
                        style={{
                          width: "18px",
                          height: "40px",
                        }}
                        checked={values.saveCard}
                        onChange={({ target }) => {
                          hangleChange(target.checked, "saveCard");
                        }}
                      />{" "}
                      <label
                        style={{
                          paddingLeft: "5px",
                          fontSize: "medium",
                        }}
                      >
                        Save this card for future payments{" "}
                      </label>{" "}
                    </div>
                  </fieldset>

                  <fieldset className="spf-field-submit">
                    <Input
                      type="submit"
                      className="new_payment"
                      value={paymentButtonLabel}
                      disabled={paymentButton}
                    />
                    <div id="message"></div>
                    <div id="errors"></div>
                  </fieldset>





                </form></>}
              </div>
              <div className="pricingShipMobile">
                <div className="PricingItems">
                  <hr />
                  <div className="PricingItem">
                    <div className="PricingItemHead"> SubTotal </div>{" "}
                    <div> {checkoutDetails?.subtotalPrice} </div>{" "}
                  </div>{" "}
                  <div className="PricingItem">
                    <div className="PricingItemHead"> Shipping </div>{" "}
                    <div> {checkoutDetails?.totalShippingPrice} </div>{" "}
                  </div>{" "}
                  <div className="PricingItem">
                    <div className="PricingItemHead"> Taxes </div>{" "}
                    <div> {checkoutDetails?.totalTax} </div>{" "}
                  </div>{" "}
                  <hr />
                  <div className="PricingItem">
                    <div className="PricingItemHeadTotal"> TOTAL </div>{" "}
                    <div
                      style={{
                        fontWeight: "900",
                        fontSize: "1.5rem",
                      }}
                    >
                      {checkoutDetails ? checkoutDetails?.totalPrice : "00"}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
            </div>{" "}
            <div className="details_section">
              <div className="pricingShip">
                <div className="pricingHead">
                  <div> Pricing Details </div>{" "}
                </div>{" "}
                <div className="PricingItems">
                  <div className="PricingItem">
                    <div className="PricingItemHead"> SubTotal </div>{" "}
                    <div> {checkoutDetails?.subtotalPrice} </div>{" "}
                  </div>{" "}
                  <div className="PricingItem">
                    <div className="PricingItemHead"> Shipping </div>{" "}
                    <div> {checkoutDetails?.totalShippingPrice} </div>{" "}
                  </div>{" "}
                  <div className="PricingItem">
                    <div className="PricingItemHead"> Taxes </div>{" "}
                    <div> {checkoutDetails?.totalTax} </div>{" "}
                  </div>{" "}
                  <hr />
                  <div className="PricingItem">
                    <div className="PricingItemHeadTotal"> TOTAL </div>{" "}
                    <div
                      style={{
                        fontWeight: "900",
                        fontSize: "1.5rem",
                      }}
                    >
                      {checkoutDetails ? checkoutDetails?.totalPrice : "00"}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
              <div className="shopingIMG">
                <img src={Image} />{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
          {bat ? (
            <div className="popup">
              <div className="popup_inner">
                <h3
                  style={{
                    display: "flex",
                    marginLeft: "10px",
                    marginBottom: "5%",
                    marginTop: "3%",
                    fontSize: "Medium",
                  }}
                >
                  {" "}
                  Invalid shipping address.Do you want to change ?{" "}
                </h3>{" "}
                <div
                  className="row"
                  style={{
                    // marginLeft: "50%",
                    marginBottom: "1%",
                  }}
                >
                  <button
                    style={{
                      marginLeft: "20%",
                      width: "30%",
                      height: "25px",
                      fontSize: "small",
                      backgroundColor: "#316F9C",
                      borderRadius: "5px",
                      border: "none",
                      color: "#FFFFFF",
                    }}
                    onClick={() => {
                      closePopupWithoutSave(true);
                    }}
                  >
                    {" "}
                    cancel{" "}
                  </button>{" "}
                  <button
                    style={{
                      marginLeft: "1%",
                      width: "30%",
                      height: "25px",
                      fontSize: "small",
                      backgroundColor: "#316F9C",
                      borderRadius: "5px",
                      border: "none",
                      color: "#FFFFFF",
                    }}
                    onClick={() => {
                      closePopup(false);
                    }}
                  >
                    {" "}
                    ok{" "}
                  </button>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          ) : null}{" "}
           <ToastContainer
          style={{
            fontSize: "2rem",
          }}
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        </div>
       
        {isLoading ? (
          <div id="app" class="loader" style={{ position: 'fixed',top: '50%',left: '50%',marginTop: '-50px',marginLeft: '-50px'}}></div>
        ) : null}
      </form>
        )}
      </Elements>}
    </Fragment>
  );
};


export default Login;
