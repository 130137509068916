import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Products from "./Products";
import Cart from "./Cart";
import Home from "./Home";
import ProductView from "./ProductView";
import CartPage from "./CartPage";
import RedirectPage from "./RedirectPage";
import { useShopify } from "../hooks";
import Login from "./Login/Login";
import Register from "./Login/Register";
import ShippingDetails from "./Login/ShippingAddress";
import PaymentDetails from "./Payment/payment";
import PaymentSuccess from "./Payment/paymentRedirect";
import orderList from "./OrderDetails/orderList";
import registertest from "./Login/RegisterTest";
import logintest from "./Login/LoginTest";
import PaymentDetails2 from "./Payment-apple/Payment";
import RedirectRoot from "./RedirectRoot";
import orderDetails from "./OrderDetails/orderDetails";
import StripePaymentSuccess from "./Payment/StripePaymentSuccess";
import StripePaymentError from "./Payment/StripePaymentError";
import StripePaymentStatus from "./Payment/StripePaymentStatus";

export default (props) => {
  // const {
  // 	createShop,
  // 	createCheckout,
  // 	fetchProducts,
  // 	//fetchCollection,
  // } = useShopify()

  // useEffect(() => {

  // 	//createCheckout()
  // 	//fetchCollection()
  // }, [])

  return (
    <Router>
      <div id="App">
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/Home" />} />
          <Route path="/Home" component={Home} />
          <Route path="/Home" component={Products} />
          <Route path="/Product" component={ProductView} />
          <Route path="/Cart/:cartId" component={CartPage} />
          {/* <Route path="/redirect" component={RedirectPage} /> */}
          <Route path="/login" exact component={logintest} />
          <Route path="/register" exact component={registertest} />
          <Route path="/register/test" exact component={registertest} />
          <Route path="/shippinginfo" exact component={ShippingDetails} />
          <Route path="/payment" exact component={PaymentDetails} />
          
          <Route path="/payment/success" exact component={PaymentSuccess} />
          <Route path="/order/list" exact component={orderList} />
          
          <Route path="/order/details" exact component={orderDetails} />
          <Route path="/payment-two" exact component={PaymentDetails2} />
          <Route path="/stripePayment/success" exact component={StripePaymentSuccess} />
          <Route path="/stripePayment/error" exact component={StripePaymentError} />
          <Route path="/stripePaymentIntent" exact component={StripePaymentStatus} />
          <Route path="/redirect" component={RedirectRoot} />
          <Route path="/" component={Cart} />
        </Switch>
      </div>
    </Router>
  );
};
