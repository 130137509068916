import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import "./style.css";
//import "./style_one.css";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { services } from "./service";
import { confirmAlert } from "react-confirm-alert";
import FacebookIcon from '@material-ui/icons/Facebook';
import { ToastContainer, toast } from 'react-toastify';
import { FcGoogle } from 'react-icons/fc';
import "react-confirm-alert/src/react-confirm-alert.css";
import 'react-toastify/dist/ReactToastify.css';
import firebase from "./firebase";
import { unmountComponentAtNode } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "firebase/auth";
import {
  Col,
  Row,
  Button,
  CustomInput,
  FormGroup,
  Input,
  Card,
  CardBody,
  Label
} from "reactstrap";
//import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
const Register = ({ history }) => {
  const [error, setError] = useState({});
  const [values, setValues] = useState({['login_to_device']:true});
  const [isNewUser, setIsNewUser] = useState("");
  const [isVerify, setIsVerify] = useState(false);
  const [vetifyOTP, setOTPtest] = useState('')
  const [otp, setOtp] = useState({});
  const [OTPNumber, setOTPNumber] = useState("");
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [eye1, setEye1] = useState(<FontAwesomeIcon icon={faEye} />);
  const [registering, setRegistering] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState({});
  const [faceBookDatas, setFacebookDatas] = useState({
    facebookId: "",
    facebookEmail: "",
  });
  const [flag, setFlag] = useState({
    isRegister: true,
    isFbAcive: true,
    isSuccessMsg: true,
    isErrorMsg: false,
    isErrorVerifyMsg: false,
    isEmailExistMsg: false,
    isFirstNameSelected: false,
    isEmailSelected: false,
    isPasswordSelected: false,
    isVerifyEmailSelected: false,
  });
  const [loginPopup, setLoginPopup] = useState(false);

  let FBData = null;
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   window.scrollTo(0, 0);

  //   if (state.location.state) {
  //     let FBData = state.location.state.facebookData;

  //     setFlag({ ...flag, isFbAcive: false });

  //     setFacebookDatas({ ...faceBookDatas, facebookId: FBData.id });

  //     if (FBData.email) {
  //       setValues({
  //         ...values,
  //         ["firstname"]: FBData.first_name,
  //         ["email"]: FBData.email,
  //       });
  //     } else {
  //       setValues({
  //         ...values,
  //         ["firstname"]: FBData.first_name,
  //       });
  //     }
  //   }
  // }, []);
  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
    setEye1(
      passwordShown1 ? (
        <FontAwesomeIcon icon={faEye} />
      ) : (
        <FontAwesomeIcon icon={faEyeSlash} />
      )
    );
  };
//   window.history.pushState(null, null, document.URL);
// window.addEventListener('popstate', function(event) {
//   console.log("refresh")
//   window.location.reload()
//       // window.location.replace(
//       //   `http://localhost:3008/register`
//       // );
// });
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/ml_IN/sdk.js#xfbml=1&version=v11.0";
    document.body.appendChild(script);

    script.onload = () => {
     // console.log("success")
      //        window.FB.init({
      //         appId: "362146425651415",
      //         cookie: true,
      //         xfbml: true,
      //         version: 'v3.3'
      //       });
      //        var finished_rendering = function() {
      //   console.log("finished rendering plugins");

      // }
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "362146425651415",
          cookie: true,
          xfbml: true,
          version: 'v3.3'
        });
        // window.FB.getLoginStatus(function (response) {
        //   if (response.status === 'connected') {
        //    console.log("success authResponse", response.authResponse.userID)  
        //        window.FB.login(function (response) {
        //   if (response.status === 'connected') {
        //     console.log("success connected", response);
        //   } else {
        //     console.log("fail", response);
        //   }
        // });
        //   }
        // });
      };
      //window.FB.Event.subscribe('xfbml.render', finished_rendering);
      //   window.FB.getLoginStatus(function(response) {
      //   if (response.status === 'connected') {
      //     console.log(response.authResponse.accessToken);
      //   }
      // });
      //  window.FB.login(function(response){
      //         console.log("success response",response)
      //   // handle the response 
      // });
    };
    const script_two = document.createElement("script");
    script_two.src = "https://apis.google.com/js/api:client.js";
    document.body.appendChild(script_two);

    script_two.onload = () => {
      var googleUser = {};

      var startApp = function () {
        window.gapi.load('auth2', function () {
          // Retrieve the singleton for the GoogleAuth library and set up the client.
          window.auth2 = window.gapi.auth2.init({
            client_id: '68474774674-5vfk0e4b4okk30vs5s80tisas5k129ed.apps.googleusercontent.com',
            cookiepolicy: 'single_host_origin',
            // Request scopes in addition to 'profile' and 'email'
            //scope: 'additional_scope'
          });
          attachSignin(document.getElementById('customBtn'));
        });
      };
      startApp();
      function attachSignin(element) {
       // console.log(element.id);
        window.auth2.attachClickHandler(element, {},
          function (googleUser) {
            var response = {
              'id': googleUser.getBasicProfile().getId(),
              'first_name': googleUser.getBasicProfile().getGivenName(),
              'last_name': googleUser.getBasicProfile().getFamilyName(),
              'email': googleUser.getBasicProfile().getEmail(),

            }
           // console.log("googleUser.getBasicProfile().", response)
            responseFacebook(response, 'google')
          }, function (error) {
            // alert(JSON.stringify(error, undefined, 2));
          });
      }

    };
  }, []);
  const login = () => {
   // console.log("enter")
    window.FB.login(function (response) {
     // console.log("enter response", response)
      window.FB.api('/me?fields=id,email,first_name,last_name', function (response) {
     //   console.log("enter response enter response", response);
        responseFacebook(response, 'facebook')
      });
    }, { scope: 'email' });
  }
   const closePopup = (value) => {
  //console.log("values.login_to_device",values.login_to_device)
    setLoginPopup(false);
   
     services.loginToDevice(values.login_to_device).then((response) => {
        if (response.success) {
           setTimeout(() => {
              history.push({
                pathname: '/shippinginfo',
              })

            }, 2000);
     // services.loginToDevice(values.login_to_device).then((response) => {
     //    if (response.success) {
     //       services.checkoutCheck().then((res) => {
     //              console.log("res 45", res);

     //              if (res?.status == 202) {
     //                history.push({ pathname: '/login' })
     //              } else if (res?.status == 203) {
     //                history.push(
     //                  {
     //                    pathname: '/shippinginfo',

     //                  })
     //              } else if (res?.status == 200) {
     //                history.push({
     //                  pathname: "/payment",

     //                });
     //              } else if (res?.status == 206) {
     //                toast.error("No items in your cart ");
     //              }
     //              console.log("res", res);
     //            }, (error) => {

     //            })
        } else {
          toast.error("Something went wrong");
        }
      });
    //setBat(false);

    // history.push({
    //   pathname: "/shippinginfo",
    // });
  };
  const staticValidation = () => {
    const emailReg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobileReg = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    let errors = {};

    let fromValidation = true;
    if (values.f_name) {
      if (values.f_name.length < 3) {
        errors.f_name = "Minimum 3 chareceters";
        fromValidation = false;
      }
    } else {
      errors.f_name = "First Name Required";
      fromValidation = false;
    }
    if (values.l_name) {
    } else {
      errors.l_name = "Last Name Required";
      fromValidation = false;
    }

    if (values.email) {
      if (!emailReg.test(String(values.email).toLowerCase())) {
        errors.email = "Please enter valid email";
        fromValidation = false;
      }
    } else {
      errors.email = "Email Required";
      fromValidation = false;
    }
    if (!values.password) {
      errors.password = "Password Required";
      fromValidation = false;
    }
    if (!values.c_password) {
      errors.c_password = "Confirm Password Required";
      fromValidation = false;
    } else {
      if (values.password !== values.c_password) {
        errors.c_password = "Password Not Matching";
        fromValidation = false;
      }
    }

    if (values.mobile) {
      // if (values.mobile.length < 10) {
      //   errors.mobile = "Invalid Mobile Number";
      //   fromValidation = false;
      // }
    } else {
      errors.mobile = "Mobile Number Required";
      fromValidation = false;
    }
    //console.log(fromValidation);
    setError(errors);
    return fromValidation;
  };

  const handleFocus = (name) => {
    setError({
      ...error,
      [name]: "",
    });
  };
  const handleValues = (value, name, country) => {
    setRegistering(false)
    //console.log("value", value, "name", name, "country", country)
    if (name == "mobile" && country != undefined) {
      // let c = String(value).slice(country?.dialCode?.length);
      // value = c;
    }
    handleFocus(name);
    setValues({
      ...values,
      [name]: value,
    });
  };
//const userExists = () => {
async function userExistsCheck (){
   let errors = {};
   var validEmail = true;
  services.userExists(values.email,values.mobile).then((response) => {
    console.log("response",response.success)
        if (response.success == true) {

          if(response.message == "Email already exist "){
             validEmail = false;
             errors.email = "Email  already used!";
          }else if(response.message == "Phone already exist "){
             validEmail = false;
             errors.mobile = "Phone already used!";
          }
                     setError(errors);

          return validEmail;

        } else {
              console.log("response 01",response.success)

           validEmail = true;
           return validEmail;

          //toast.error("Something went wrong");
        }
  });

             // return validEmail;

  };
  const verifyOTPNumber = (otpInput) => {
    // e.preventDefault();
    //let otpInput = this.state.otp;
    let optConfirm = window.confirmationResult;
    // console.log(codee);
    optConfirm
      .confirm(otpInput)
      .then(function (result) {
        // User signed in successfully.
        console.log("Result", result.user.uid);
        services.register(values,result.user.uid).then((response) => {
          if (response.success) {
            setIsNewUser(true);
            setLoginPopup(true);

            localStorage.setItem("userId", response.data[0].user_id);
            
          } else {
            toast.error(response.message);
            setIsNewUser(false);
          }
        }).catch(e => {
         // console.log("error 123", e)
          toast.error("Error Occured!");
        })
      })
      .catch(function (error) {
        toast.error("Incorrect OTP!");

     //   console.log("otpInput error", error)


        //toast.error("Incorrect OTP!");
      });
  };
  const handleOTPVerification = (e) => {
    e.preventDefault();
  //  console.log("enter")
    if (otpValidation) {
  //    console.log("enter01", OTPNumber);
      verifyOTPNumber(OTPNumber);
    }
  };
  const setUpRecaptcha = () => {
    if (!window.recaptchaVerifier || localStorage.getItem("backEvent") == 1) {
        localStorage.removeItem("backEvent")

      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function (response) {
            //sentOTP(values.mobile);
          },
        }
      );
    
     } else {
       }
       const recaptchaVerifier = window.recaptchaVerifier;

    // [START auth_phone_recaptcha_render]
    recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;

    });



    
   
  };
 // window.history.pushState(null, null, document.URL);
window.addEventListener('popstate', function(event) {
 // console.log("entered")
  localStorage.setItem("backEvent",1)
window.location.href="/login"     
     
});

  const sentOTP = () => {

    let phoneNumber = '+' + values.mobile;
   // console.log(phoneNumber);
    let appVerifier = window.recaptchaVerifier;
  //  console.log("enter 2", appVerifier)

    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(function (confirmationResult) {
        toast.success("Verification code send to " + phoneNumber);

       // console.log("enter 3", confirmationResult)
        window.confirmationResult = confirmationResult;
        setOtp({
          otpSend: true,
        });
        setIsVerify(true);

      })
      .catch(function (error) {
        console.log("error code", error)
        unmountComponentAtNode(document.getElementById("recaptcha-container"));
        window.recaptchaVerifier.render().then((widgetId) => {
          window.recaptchaVerifier.reset(widgetId);
        });
        setRegistering(false);

        toast.error("Invalid phone number");
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  

     if (staticValidation()) {
      //  console.log("userExistsCheck",userExistsCheck())
      //      if (!userExistsCheck()) {
             setRegistering(true);
             setUpRecaptcha();
             sentOTP();
            // } else {
            //   toast.error("Something went wrong!")
            // }
       
      }
   
  };

  const otpValidation = () => {
    let formValid = true;
    let errors = {};
    if (OTPNumber === "") {
      errors.otp = "OTP Required";
      formValid = false;
    }
    setError(errors);
    return formValid;
  };
  const responseFacebook = (response, login_type) => {
    FBData = response;
   // console.log("FBData", FBData)
    setFacebookDatas({ ...faceBookDatas, facebookId: FBData.id });

    setFacebookDatas({ ...faceBookDatas, facebookEmail: FBData.email });

    if (response.id != undefined && response.id != null && response.id != "")
      services
        .facebookLogin(
          response.id,
          response.email,
          response.first_name,
          response.last_name,
          login_type
        )
        .then((response) => {
          if (response.status == 200) {
            // localStorage.setItem("isLoggedIn", true);
            // localStorage.setItem("userName", response.data[0].first_name);
            // services.setCookie("userId", response.data[0].user_id, 30);
            setIsNewUser(true);
            //   setIsVerify(true);
            setLoginPopup(true)
             localStorage.setItem("userId", response.data[0].user_id);
            // setTimeout(() => {
            //   history.push({
            //     pathname: '/shippinginfo',
            //   })

            // }, 2000);
            //   services
            //     .userSubscription(response.data[0].user_id)
            //     .then((response) => {
            //       service.setCookie("isLoggedIn", "true", 30);
            //       var user_sub = response.data;
            //       if (user_sub.length > 0) {
            //         service.setCookie("isLoggedIn", "true", 30);
            //         setotherValues({
            //           ...otherValues,
            //           msgSuccess: "You are successfully registered",
            //         });

            //         setFlag({ ...flag, isSuccessMsg: true });
            //         setTimeout(function () {
            //           setFlag({ ...flag, isSuccessMsg: false });
            //         }, 5000);
            //         history.push("/home");
            //         if (sessionStorage.getItem("tvActivateFlag") == "true") {
            //           history.push("/tv");
            //         } else {
            //           //window.location.href = "/home";
            //         }
            //       } else {
            //         service.setCookie("isLoggedIn", "true", 30);
            //         setotherValues({
            //           ...otherValues,
            //           msgSuccess: "You are successfully registered",
            //         });

            //         setFlag({ ...flag, isSuccessMsg: true });
            //         setTimeout(function () {
            //           setFlag({ ...flag, isSuccessMsg: false });
            //         }, 5000);
            //         history.push("/home");
            //         if (sessionStorage.getItem("tvActivateFlag") == "true") {
            //           history.push("/tv");
            //         } else {
            //           // window.location.href = "/home";
            //         }
            //       }
            //     });
            // } else if (response.status == 202) {
            //   setotherValues({
            //     ...otherValues,
            //     msgError: "There was an error during registration",
            //   });

            //   setFlag({ ...flag, isErrorMsg: true });

            //   setTimeout(function () {
            //     setFlag({ ...flag, isErrorMsg: false });
            //   }, 5000);
            //  } else if (response.status == 203) {
            //   setFlag({ ...flag, isFbAcive: false });

            //   if (FBData.email) {
            //     setValues({
            //       ...values,
            //       ["firstname"]: FBData.first_name,
            //       ["email"]: FBData.email,
            //     });
            //   } else {
            //     setValues({
            //       ...values,
            //       ["firstname"]: FBData.first_name,
            //     });
            //   }
            //  } else if (response.status == 201) {
            //   setotherValues({
            //     ...otherValues,
            //     userRegisterId: response.data[0].user_id,
            //   });

            //   setFlag({ ...flag, isRegister: false });
            //   setotherValues({
            //     ...otherValues,
            //     msgSuccess:
            //       "OTP sent to your Email Id (Note:  If you do not find the email in your inbox, please check your spam filter or bulk email folder)",
            //   });

            //   setFlag({ ...flag, isSuccessMsg: true });
            //   setTimeout(function () {
            //     setFlag({ ...flag, isSuccessMsg: false });
            //   }, 5000);
          } else if (response.status == 204) {
            confirmAlert({
              closeOnEscape: false,
              closeOnClickOutside: false,
              message: "Do you want to link your Facebook account?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => onFBLink(login_type),
                },
                {
                  label: "No",
                  onClick: () => onFBNoLink(login_type),
                },
              ],
            });
          }
        });
  };
  const onFBLink = (login_type) => {
    services
      .facebookLink(
        FBData.id,
        FBData.email,
        FBData.first_name,
        FBData.last_name,
        login_type
      )
      .then((response) => {
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userId", response.data[0].user_id);
        setLoginPopup(true)
        // setTimeout(() => {
        //   history.push({
        //     pathname: '/shippinginfo',
        //   })

        // }, 2000);
        //services.userSubscription(response.data[0].user_id).then((response) => {
        //services.setCookie("isLoggedIn", "true", 30);
        //var user_sub = response.data;
        // if (user_sub.length > 0) {
        //   service.setCookie("isLoggedIn", "true", 30);
        //   setotherValues({
        //     ...otherValues,
        //     msgSuccess: "You are successfully registered",
        //   });

        //   setFlag({ ...flag, isSuccessMsg: true });
        //   setTimeout(function () {
        //     setFlag({ ...flag, isSuccessMsg: false });
        //   }, 5000);
        //   history.push("/home");
        //   if (sessionStorage.getItem("tvActivateFlag") == "true") {
        //     history.push("/tv");
        //   } else {
        //     window.location.href = "/home";
        //   }
        // } else {
        //   service.setCookie("isLoggedIn", "true", 30);
        //   setotherValues({
        //     ...otherValues,
        //     msgSuccess: "You are successfully registered",
        //   });

        //   setFlag({ ...flag, isSuccessMsg: true });
        //   setTimeout(function () {
        //     setFlag({ ...flag, isSuccessMsg: false });
        //   }, 5000);
        //   history.push("/home");
        //   if (sessionStorage.getItem("tvActivateFlag") == "true") {
        //     history.push("/tv");
        //   } else {
        //     window.location.href = "/home";
        //   }
        // }
        // });
      });
  };
  const onFBNoLink = () => {
    // setTimeout(() => {
    //    history.push({
    //        pathname: '/shippinginfo',
    //    })
    //  }, 2000);
    toast.error("Email already exist, Please login.. ");

  };
  const verifyHandler = (e) => {
    e.preventDefault();
    if (otpValidation()) {
      services.verifyEmail(vetifyOTP).then(
        res => {
          if (res.success) {
            localStorage.setItem("isLoggedIn", true);
            toast.success(res.message)
            var shipping_address = {
              user_email: values.email,
              phone: values.mobile,
              firstname: values.f_name,
              lastname: values.l_name
            }
            services.setCookie("shipping_address", JSON.stringify(shipping_address));
            setTimeout(() => {
              history.push({
                pathname: '/shippinginfo',
              })

            }, 2000);
          } else {
            setInvalidOTP({
              message: res.message
            })
          }
        }
      )

    }
  };
  //   const handleLogin = async googleData => {
  //   const res = await fetch("/api/v1/auth/google", {
  //       method: "POST",
  //       body: JSON.stringify({
  //       token: googleData.tokenId
  //     }),
  //     headers: {
  //       "Content-Type": "application/json"
  //     }
  //   })
  //   const data = await res.json()
  //   console.log("data",data)
  //   // store returned user somehow
  // }
  const handleLogin = (response) => {
  //  console.log("response", response);
  }

  return (
    <>
      {!isVerify ? (
        <form className="Register" onSubmit={handleSubmit}>
          <div id="recaptcha-container"></div>
          <div className="LogCard">
            <div className="Heading" >Register Here</div>
            <div className="section">
              <div className="row">
                <div className="col col-sm-6 feilds">
                  {/* <label>First Name</label> */}
                  <input
                    className="InputAddress"
                    placeholder="First Name"
                    onChange={({ target }) => {
                      handleValues(target.value, "f_name");
                    }}
                  />
                  {error?.f_name && (
                    <div className="errorClass">{error?.f_name}</div>
                  )}
                </div>
                <div className="col col-sm-6 feilds">
                  {/* <label>Last Name</label> */}
                  <input
                    className="InputAddress"
                    placeholder="Last Name"
                    onChange={({ target }) => {
                      handleValues(target.value, "l_name");
                    }}
                  />
                  {error?.l_name && (
                    <div className="errorClass">{error?.l_name}</div>
                  )}
                </div>
              </div>
              <div className="inputItem">
                <input
                  className="InputAddress"
                  placeholder="Email"
                  onChange={({ target }) => {
                    handleValues(target.value, "email");
                  }}
                />
                {error?.email && (
                  <div className="errorClass">{error?.email}</div>
                )}
              </div>
              <PhoneInput
                country={'us'}
                autoFormat
                countryCodeEditable={true}
                style={{
                  height: "4rem",
                  minWidth: "90%",
                  marginBottom: "1rem",
                }}
                placeholder="+1 (555) 555-1234"
                onChange={(value, country) => {
                  handleValues(value, "mobile", country);
                }}
              />
              {error?.mobile && (
                <div className="errorClass">{error?.mobile}</div>
              )}
              <div className="inputItem">
                <input
                  className="InputAddress"
                  placeholder="Password"
                  type={passwordShown1 ? "text" : "password"}
                  onChange={({ target }) => {
                    handleValues(target.value, "password");
                  }}
                />
                <i style={{ marginLeft: "90%", marginTop: "-4vh" }}
                  className="eyeIcon"
                  onClick={togglePasswordVisiblity1}
                >
                  {eye1}
                </i>
                {error?.password && (
                  <div className="errorClass" style={{marginTop: '5%',marginBottom: '-2%'}}>{error?.password}</div>
                )}
              </div>



              <div className="inputItem" style={{ marginTop: '7%' }}>
                <input
                  className="InputAddress"
                  placeholder="Confirm Password"
                  onChange={({ target }) => {
                    handleValues(target.value, "c_password");
                  }}
                />
                {error?.c_password && (
                  <div className="errorClass">{error?.c_password}</div>
                )}
              </div>

              <div style={{ textAlign: 'end', fontSize: '1.4rem' }} >Already Registered? <Link style={{ textDecoration: 'none' }} to='/login'>Login here!</Link></div>
              <div >
                <button className="loginButton" style={{ margin: "1.5rem 0rem" }} disabled={registering} onClick={handleSubmit}>

                  <label style={{ fontSize: 'smaller' }}>{!registering ? 'Register' : 'Processing!'}</label>
                </button>
              </div>
              <div className="orContainer orMargin">
                <div className="orDivider"></div>
                <div className="orCircle">
                  <div className="orText">OR</div>
                </div>
                <div className="orDivider"></div>
              </div>

              <button className="loginButton" style={{ margin: "1.5rem 0rem", }} onClick={login}>
                <FacebookIcon style={{ marginRight: 'auto', marginLeft: 'auto', width: '1.5em', height: '1.5em' }} /> <label style={{ fontSize: 'smaller' }}> Continue With Facebook</label>
              </button>
              <div id="gSignInWrapper">
                <div id="customBtn" class="customGPlusSignIn">
                  <button className="loginButton" style={{ margin: "1.5rem 0rem", backgroundColor: 'white', color: 'black', boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px' }} >
                    <FcGoogle style={{ marginRight: 'auto', marginLeft: 'auto', width: '1.5em', height: '1.5em' }} /> <label style={{ fontSize: 'smaller' }}> Continue With Google</label>
                  </button>
                </div>
              </div>


            </div>
          </div>
        </form>
      ) : (
        <form onSubmit={handleOTPVerification}>
          <div id="recaptcha-container"></div>

          <div className="Login">
            <div className="LogCard">
              <div className="Heading" style={{ marginBottom: '0rem' }} >Verification</div>
              <div className="sectionOTP" style={{ marginTop: '3rem' }}>
                <div className="inputItem">
                  <div style={{ fontSize: '1.3rem', color: 'black', fontWeight: '550' }}>Verification code send to {'+' + values.mobile}</div>
                  {/*<div style={{marginBottom:'1.3rem'}}>(Note:  If you do not find the email in your inbox, please check your spam filter or bulk email folder)</div>*/}
                  <input
                    value={OTPNumber}
                    max={30}
                    id="myInput"
                    className="Input"
                    placeholder="OTP"
                    onChange={({ target }) => {
                      setOTPNumber(target.value.trim());
                    }}
                    style={{ textAlign: 'center', letterSpacing: '1rem' }}
                  />

                </div>
                {invalidOTP?.message && (
                  <div className="errorClass">{invalidOTP?.message}</div>
                )}
                <div style={{ textAlign: 'end', fontSize: '1.4rem' }} ><Link style={{ textDecoration: 'none' }} onClick={sentOTP}>Resend OTP</Link></div>

                <button className="loginButton" type="submit">
                  Verify
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
         {loginPopup ? (
            <div className="popup">
              <div className="popup_inner">
              <Card style={{border: '6px solid #e5e5e5'}}>
                <CardBody>
                <h3
                  style={{
                    display: "flex",
                    //marginLeft: "10px",
                    marginBottom: "5%",
                    marginTop: "3%",
                    fontSize: "large",
                    fontWeight: "1000"
                  }}
                >
                  {" "}
                  With Flixbuy you can sync with your TV device and make purchase from TV!!!{" "}
                </h3>{" "}
                     <div
                  className="row"
                  style={{
                    // marginLeft: "50%",
                    marginBottom: "1%",
                  }}
                >
                  <Col xs={10}>
                  <Label>Login to my TV device(I have scanned QR code from my private TV)</Label>
          </Col>
          <Col xs={2}>
                    <input
                        type="checkbox"
                        style={{
                          width: "18px",
                          height: "40px",
                        }}
                        checked={values.login_to_device}
                        onChange={({ target }) => {
                          handleValues(target.checked, "login_to_device");
                        }}
                      />
          </Col>
                </div>{" "}
                 <div
                  style={{
                    marginBottom: "5%",
                    marginTop: "3%",
                    fontSize: "large",
                    fontWeight: "1000"
                  }}
                >
                  {" "}
For using this service you need to agree our <a href="https://watch.boondocknation.com/termsandconditions">terms and conditions</a></div>{"  "}

                <div
                  className="row"
                  style={{
                    // marginLeft: "50%",
                    marginBottom: "1%",
                  }}
                >
                  <button
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "fit-content",
                      height: "25px",
                      fontSize: "small",
                      backgroundColor: "#316F9C",
                      borderRadius: "5px",
                      border: "none",
                      color: "#FFFFFF",
                    }}
                    onClick={() => {
                      closePopup(true);
                    }}
                  >
                    {" "}
Agree & Proceed     </button>{" "}
               
                </div>{" "}
                 </CardBody>
                    </Card>
              </div>{" "}
            </div>
          ) : null}{" "}
      <ToastContainer
        style={{ fontSize: '2rem' }}
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
};

export default Register;
