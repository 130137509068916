import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useShopify } from "../hooks";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import Cart from './Cart'
import { services } from '../Service/service'
import Parser from 'html-react-parser';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Menudetails from "./menu.js";
import {
  Col,
  Row,
  Button,
  CustomInput,
  FormGroup,
  Input,
  Card,
  CardBody,
  Label
} from "reactstrap";
import {
  useHistory
} from "react-router-dom";
export default (props) => {
  const {
    // product,
    fetchProduct,
    openCart,
    checkoutState,
    addVariant,
    addToCart,
    fetchCollection,
    createCheckout,
    fetchCart
  } = useShopify();
  const [loading, setLoading] = useState(true);
  var url_string = window.location.href;
  var url = new URL(url_string);
  var id = url.searchParams.get("pid");
  var tid = url.searchParams.get("tid");
  if (tid != localStorage.getItem("trans_id")) {
    localStorage.removeItem("CID");
  }
  localStorage.setItem("trans_id", tid)
  var event = url.searchParams.get("e");
  //console.log("event", event, "tid", tid)
  let history = useHistory();

  const [product, setProduct] = useState();
  const defaultSize = ''
  const description = ''
  // const defaultSize = product.variants && product.variants[0].id.toString();
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [imageCollection, setImageCollection] = useState([]);
  const [imageIndex, setImageIndex] = useState();
  const [cartId, setCartId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [productAvaiable, setProductAvaiable] = useState(false);
  const [quantityAvailable, setQuantityAvailable] = useState()
  const [loginPopup, setLoginPopup] = useState(false);
  const [values, setValues] = useState({ ['login_to_device']:true});

  // const description = product.description && product.description.split(".");

  function changeSize(sizeId, quantity, product_id) {
    localStorage.setItem("add_to_cart", true)
   // console.log("product_id",product_id);
//console.log("quantityAvailable",quantityAvailable,quantity);
    // if (sizeId === "") {
    // sizeId = defaultSize;
    // const lineItemsToAdd = [
    //   { variantId: sizeId, quantity: parseInt(quantity, 10) },
    // ];
    // const checkoutId = checkoutState.id;
    // if(quantityAvailable>=quantity){
      services.addProduct(localStorage.getItem('trans_id'), product.id, quantity, String(sizeId), true).then((res) => {
       // console.log("YYYYYYYYYYYy",res)
        //  openCart();
        if (res?.data?.cart_id) {
  
          localStorage.setItem("CID", res.data.cart_id);
          setCartId(res.data.cart_id);
  
          //   fetchCollection();
  
          toast.success("Added to cart");
        }
        if(res?.status==209){
          toast.error("Some of the products are out of stock")
        }
        if(res?.status==210){
          toast.error("Inventory for some of the items in your cart has changed.")
        }
  
      },
        (error) => {
          toast.error(error.message)
        })
    // }
    // else{
    //   toast.error("only "+quantityAvailable +" available , Select lesser quantity")
    // }

    // addVariant(checkoutId, lineItemsToAdd, product_id);
    //  } else {
    // const lineItemsToAdd = [
    //   { variantId: sizeId, quantity: parseInt(quantity, 10) },
    // ];
    // const checkoutId = checkoutState.id;

    // addVariant(checkoutId, lineItemsToAdd,product_id)



    //}
  }

  function buyNow(sizeId, quantity, product_id) {

    if(quantityAvailable>=quantity){
      services.addProduct(null, product.id, quantity, String(sizeId), false).then((res) => {
        //console.log("RESPONSE",res)
        //  openCart();
       

        if (res?.data?.cart_id) {
          var transaction_id = res?.data?.transaction_id ; 
          var cart_id = res?.data?.cart_id;
          //localStorage.setItem("trans_id",transaction_id.toString)
          localStorage.setItem("CID",cart_id)
                setCartId(res?.data?.cart_id);
          services.fetchCart(res?.data?.cart_id,res?.data?.transaction_id).then(res => {

            if (res?.data) {
              if(values.is_guest && localStorage.getItem("userId")){
               setLoginPopup(true);
              }else{

              // if (localStorage.getItem("cart_user_id") == localStorage.getItem("userId") && !localStorage.getItem("guest_user_id")) {
              //   console.log("already login")
                services.checkoutCheck().then((response) => {
                 // console.log("response already", response)
  
                  if (response?.status == 202) {
                    localStorage.setItem("checkout_status", 202)
                    history.push({ pathname: '/login' })
                  } else if (response?.status == 203) {

                    localStorage.setItem("cart_user_id", localStorage.getItem("userId"));
                    history.push({ pathname: '/shippinginfo' })
                  } else if (response?.status == 200) {

                    localStorage.setItem("cart_user_id", localStorage.getItem("userId"));
                    history.push({ pathname: '/payment' })
                  }
                })
                  .catch((error) => {
                   // console.log("response", error)
  
                  })
                }
              // } else if ((localStorage.getItem("cart_user_id") != localStorage.getItem("userId")) && !localStorage.getItem("guest_user_id")) {
              //   console.log("cart_user_id")
  
  
              //   history.push({ pathname: '/login' })
              // } else if (localStorage.getItem("guest_user_id")) {
              //   console.log("guest_user_id")
  
              //   history.push({ pathname: '/login' })
              // }
              //                            //   fetchCollection();
            }
          }).catch(e => {
            setLoading(false)
          //  console.log(e);
          })
          
        }
        if(res?.status==209){
          toast.error('Some of the products are out of stock')
        }
        if(res?.status==210){
          toast.error('Some of the products are out of stock')
        }
        
      },
        (error) => {
          toast.error(error.message)
        })
    }
    else{
      toast.error("only "+quantityAvailable +" available , Select lesser quantity")
    }
   // console.log("buy now")
  }
  const closePopup = (value) => {
  //console.log("values.login_to_device",values.login_to_device)
    setLoginPopup(false);
     services.loginToDevice(values.login_to_device).then((response) => {
        if (response.success) {
           // services.checkoutCheck().then((res) => {
           //        console.log("res 45", res);

           //        if (res?.status == 202) {
           //          history.push({ pathname: '/login' })
           //        } else if (res?.status == 203) {
           //          history.push(
           //            {
           //              pathname: '/shippinginfo',

           //            })
           //        } else if (res?.status == 200) {
           //          history.push({
           //            pathname: "/payment",

           //          });
           //        } else if (res?.status == 206) {
           //          toast.error("No items in your cart ");
           //        }
           //        console.log("res", res);
           //      }, (error) => {

           //      })
          services.checkoutCheck().then((response) => {
                 // console.log("response already", response)
  
                  if (response?.status == 202) {
                    localStorage.setItem("checkout_status", 202)
                    history.push({ pathname: '/login' })
                  } else if (response?.status == 203) {

                    localStorage.setItem("cart_user_id", localStorage.getItem("userId"));
                    history.push({ pathname: '/shippinginfo' })
                  } else if (response?.status == 200) {

                    localStorage.setItem("cart_user_id", localStorage.getItem("userId"));
                    history.push({ pathname: '/payment' })
                  }
                })
                  .catch((error) => {
                   // console.log("response", error)
  
                  })
        } else {
          toast.error("Something went wrong");
        }
      });
    //setBat(false);

    // history.push({
    //   pathname: "/shippinginfo",
    // });
  };
  const handleValues = (value, name, country) => {
  
      setValues({
        ...values,
        [name]: value,
      });
    
  };
  useEffect(() => {
    if (
      localStorage.getItem("CID") != undefined ||
      localStorage.getItem("CID")
    ) {

      fetchCollection(localStorage.getItem("CID"))
        .then((res) => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((e) => {
          setLoading(false);
         // console.log(e);
        });
    } else {
      //   createCheckout();
    }
    services.getProducts(id, event).then((response) => {
      if (response?.data) {
       // console.log("res kii", response.data);
        setValues({
          ...values,
          ['is_guest']:response.data.is_guest
        })
        setProduct(response.data)
        var j = 0;
        var array = [];
        var json;
        response.data.variants.map((image, i) => {
          if (i == 0) {
            if(image.available){
              setProductAvaiable(true);
              setQuantityAvailable(image.quantityAvailable)
            }
            else{
               setProductAvaiable(false);
              // setProductAvaiable(true);
              setQuantityAvailable(image.quantityAvailable)
            }
            setSize(image.id)
           // console.log("image", image.images)
            setImageCollection(image.images)
            
          }
          // if(image.available){
          //   setProductAvaiable(true);
          // }

        })
        setIsLoading(false)
      }
    },
      (error) => {
        return false;
      }
    )

  }, []);
  const updateProductView = () => {
    services.updateProductView(tid, id);
  };
  const carousalChange = (id) => {

    // var images = imageCollection.filter(function (item) {
    //   return item.id == id
    // })
   // console.log("images product", product.variants)
//console.log("id",id);
    var images = product.variants.filter(function (item) {
      return item.id == id
    })
    //console.log("images", images[0].images)
    setImageCollection(images[0].images)
    setQuantityAvailable(images[0].quantityAvailable)
    if(images[0].available){
      setProductAvaiable(true);}
      else{
         setProductAvaiable(false);
        // setProductAvaiable(true);
      }

    // console.log("images",images[0].index)

  }
  useEffect(() => {
    // updateProductView();
    // fetchProduct(id);

  }, [id]);

  const CheckQuantity=(e) => {
    if(e.target.value<=7){
    setQuantity(e.target.value);
    }
  }
  return (
    <div id="individualProduct">
      <div className="logo_style_thumb">
        <Menudetails
          logo={true}

        />
      </div>
      {cartId && localStorage.getItem("trans_id") ? <Link className="homeButton button" style={{ marginLeft: '30%', marginTop: '40px' }} to={"/cart/" + cartId + "?e=qr&tid=" + localStorage.getItem("trans_id")}>
        Cart
      </Link> : null}

      {/* <Cart /> */}
      {product ? <div className="Product-wrapper2">
        <div className="slider_items">
          {product.variants && (
            <Carousel
              axis="horizontal"
              className="slider_classs"
              style={{
                paddingLeft: " 4rem",
                paddingRight: "4rem",
              }}
            >
              {imageCollection && imageCollection.map((image, i) => {


                return (
                  <div key={image}>
                    <img src={image} />
                  </div>
                );

              })}
            </Carousel>
          )}
        </div>

        <div className="Product__info">
          <h2 className="Product__title2 product_details_title">{product.title}</h2>
          <div className="Product__description product_details_title">{Parser(product.description)}</div>

          <div style={{ marginTop: '2%' }}>
            <label className="label_size">Sold by</label>
            <label className="label_size" style={{ marginLeft: "10%" }}>{product.merchant.merchant_name}</label>
            <div >
              <img className="merchant_logo" src={product.merchant.logo} />
            </div>

          </div>
          <div style={{ marginTop: '2%', marginLeft: 'auto', marginRight: 'auto' }}>
            <label className="label_size" >Price&nbsp;&nbsp;&nbsp;</label>
            <label className="label_size" >{product.variants && product.variants[0].price}</label>

          </div>
          <div className="quantity_style" >
            <label className="label_size quantity_label_style">Quantity</label>
            <input
              className="quantity"
              type="number"
              min={1}
              style={{ width: '30%' }}
              value={quantity}
              onChange={CheckQuantity}
            ></input>
          </div>
         
          {product.options.length > 0 ? <div className="size_style" >
            <label htmlFor={"prodOptions"} className="label_size size_label_style" >Size</label>
            <select
              id="prodOptions"
              name={size}
              onChange={(e) => {
                setSize(e.target.value); carousalChange(e.target.value);
              }}
            >
              {product.variants &&
                product.variants.map((item, i) => {
                  return (
                    <option
                      value={item.id.toString()}
                      key={item.title + i}
                    >{`${item.title}`}</option>
                  );
                })}
            </select>
          </div> : null}
          {productAvaiable ? 
          ( <Fragment>
            {quantityAvailable<=10 && ( <p style={{color:"blue",fontSize:"1.6rem",marginTop:".5rem"}}>Only {quantityAvailable} available!</p>)}
            <button
            className="prodBuy button"
            style={{ marginTop: '2%', width: '80%' }}
            onClick={(e) => {
              changeSize(size, quantity, id);
            }}
          >
            Add to Cart
          </button>
          <button
            className="prodBuy button"
            style={{ marginTop: '2%', width: '80%' }}
            onClick={(e) => {
              buyNow(size, quantity, id);
            }}>
            Buy Now
          </button>
         
          </Fragment>)
          :
          (
            <p style={{color:"red",fontSize:"1.8rem",marginTop:"1rem"}}>OUT OF STOCK </p>
                      
           
          )
          
        }
          {/*<h3 className="Product__price">
            ${product.variants && product.variants[0].price}
          </h3>*/}
          
           {/* {product.variants &&
                product.variants.map((item, i) => {
                  if(item.available){
                   return ( <div><button
              className="prodBuy button"
              style={{ marginTop: '2%', width: '80%' }}
              onClick={(e) => {
                changeSize(size, quantity, id);
              }}
            >
              Add to Cart
            </button>
            <button
              className="prodBuy button"
              style={{ marginTop: '2%', width: '80%' }}
              onClick={(e) => {
                buyNow(size, quantity, id);
              }}>
              Buy Now
            </button>
            </div>)
                  }

                
                })}

               {product.variants &&
                product.variants.map((item, i) => {
                  if(!item.available){
                   return ( <button
                    className="prodBuy button"
                    style={{ marginTop: '2%', width: '80%' }}
                    onClick={(e) => {
                      buyNow(size, quantity, id);
                    }}>
                    OUT OF STOCK           </button>)
                  }

                
                })} */}


          {/* {
            product.variants && product.variants[0].available ? ( <div><button
              className="prodBuy button"
              style={{ marginTop: '2%', width: '80%' }}
              onClick={(e) => {
                changeSize(size, quantity, id);
              }}
            >
              Add to Cart
            </button>
            <button
              className="prodBuy button"
              style={{ marginTop: '2%', width: '80%' }}
              onClick={(e) => {
                buyNow(size, quantity, id);
              }}>
              Buy Now
            </button>
            </div>) 
            :
            <button
              className="prodBuy button"
              style={{ marginTop: '2%', width: '80%' }}
              onClick={(e) => {
                buyNow(size, quantity, id);
              }}>
              OUT OF STOCK           </button>
          } */}
         
        </div>
      </div> : null}
       {loginPopup ? (
            <div className="popup">
              <div className="popup_inner">
              <Card style={{border: '6px solid #e5e5e5'}}>
                <CardBody>
                <h3
                  style={{
                    display: "flex",
                    //marginLeft: "10px",
                    marginBottom: "5%",
                    marginTop: "3%",
                    fontSize: "large",
                    fontWeight: "1000"
                  }}
                >
                  {" "}
                  With Flixbuy you can sync with your TV device and make purchase from TV!!!{" "}
                </h3>{" "}
                     <div
                  className="row"
                  style={{
                    // marginLeft: "50%",
                    marginBottom: "1%",
                  }}
                >
                  <Col xs={10}>
                  <Label>Login to my TV device(I have scanned QR code from my private TV)</Label>
          </Col>
          <Col xs={2}>
                    <input
                        type="checkbox"
                        style={{
                          width: "18px",
                          height: "40px",
                        }}
                        checked={values.login_to_device}
                        onChange={({ target }) => {
                          handleValues(target.checked, "login_to_device");
                        }}
                      />
          </Col>
                </div>{" "}
                 <div
                  style={{
                    marginBottom: "5%",
                    marginTop: "3%",
                    fontSize: "large",
                    fontWeight: "1000"
                  }}
                >
                  {" "}
For using this service you need to agree our <a href="https://watch.boondocknation.com/termsandconditions">terms and conditions</a></div>{"  "}

                <div
                  className="row"
                  style={{
                    // marginLeft: "50%",
                    marginBottom: "1%",
                  }}
                >
                  <button
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "fit-content",
                      height: "25px",
                      fontSize: "small",
                      backgroundColor: "#316F9C",
                      borderRadius: "5px",
                      border: "none",
                      color: "#FFFFFF",
                    }}
                    onClick={() => {
                      closePopup(true);
                    }}
                  >
                    {" "}
Agree & Proceed     </button>{" "}
               
                </div>{" "}
                 </CardBody>
                    </Card>
              </div>{" "}
            </div>
          ) : null}{" "}
      <ToastContainer
        style={{
          fontSize: "2rem",
        }}
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isLoading ? (
          <div id="app" class="loader" style={{ position: 'fixed',top: '50%',left: '50%',marginTop: '-50px',marginLeft: '-50px'}}></div>
      ) : null}
    </div>
  );
};
